/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface ExternalEventEmbedded
 */
export interface ExternalEventEmbedded {
    /**
     * 
     * @type {Location}
     * @memberof ExternalEventEmbedded
     */
    location?: Location;
}

/**
 * Check if a given object implements the ExternalEventEmbedded interface.
 */
export function instanceOfExternalEventEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExternalEventEmbeddedFromJSON(json: any): ExternalEventEmbedded {
    return ExternalEventEmbeddedFromJSONTyped(json, false);
}

export function ExternalEventEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEventEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
    };
}

export function ExternalEventEmbeddedToJSON(value?: ExternalEventEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'location': LocationToJSON(value.location),
    };
}

