/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeElectiveOptionsStatementAllOfEmbedded } from './ChangeElectiveOptionsStatementAllOfEmbedded';
import {
    ChangeElectiveOptionsStatementAllOfEmbeddedFromJSON,
    ChangeElectiveOptionsStatementAllOfEmbeddedFromJSONTyped,
    ChangeElectiveOptionsStatementAllOfEmbeddedToJSON,
} from './ChangeElectiveOptionsStatementAllOfEmbedded';
import type { ChooseElectiveOptionsStatementAllOfChoices } from './ChooseElectiveOptionsStatementAllOfChoices';
import {
    ChooseElectiveOptionsStatementAllOfChoicesFromJSON,
    ChooseElectiveOptionsStatementAllOfChoicesFromJSONTyped,
    ChooseElectiveOptionsStatementAllOfChoicesToJSON,
} from './ChooseElectiveOptionsStatementAllOfChoices';

/**
 * 
 * @export
 * @interface ChooseElectiveOptionsStatement
 */
export interface ChooseElectiveOptionsStatement {
    /**
     * 
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly userId?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly printFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    type: ChooseElectiveOptionsStatementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly status?: ChooseElectiveOptionsStatementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly response?: string | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof ChooseElectiveOptionsStatement
     */
    readonly updatedAt?: Date;
    /**
     * The academic year of the elective.
     * @type {string}
     * @memberof ChooseElectiveOptionsStatement
     */
    academicYear: string;
    /**
     * The number of academic term within the academic year, e.g. 1 for first semester on the year of study.
     * @type {number}
     * @memberof ChooseElectiveOptionsStatement
     */
    academicTerm: ChooseElectiveOptionsStatementAcademicTermEnum;
    /**
     * 
     * @type {Array<ChooseElectiveOptionsStatementAllOfChoices>}
     * @memberof ChooseElectiveOptionsStatement
     */
    choices: Array<ChooseElectiveOptionsStatementAllOfChoices>;
    /**
     * 
     * @type {ChangeElectiveOptionsStatementAllOfEmbedded}
     * @memberof ChooseElectiveOptionsStatement
     */
    embedded?: ChangeElectiveOptionsStatementAllOfEmbedded;
}


/**
 * @export
 */
export const ChooseElectiveOptionsStatementTypeEnum = {
    ChangeElectiveOptions: 'change-elective-options',
    ChooseElectiveOptions: 'choose-elective-options',
    VerifyElectiveChoice: 'verify-elective-choice'
} as const;
export type ChooseElectiveOptionsStatementTypeEnum = typeof ChooseElectiveOptionsStatementTypeEnum[keyof typeof ChooseElectiveOptionsStatementTypeEnum];

/**
 * @export
 */
export const ChooseElectiveOptionsStatementStatusEnum = {
    Filed: 'filed',
    Withdrawn: 'withdrawn',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Fulfilled: 'fulfilled',
    Formed: 'formed'
} as const;
export type ChooseElectiveOptionsStatementStatusEnum = typeof ChooseElectiveOptionsStatementStatusEnum[keyof typeof ChooseElectiveOptionsStatementStatusEnum];

/**
 * @export
 */
export const ChooseElectiveOptionsStatementAcademicTermEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type ChooseElectiveOptionsStatementAcademicTermEnum = typeof ChooseElectiveOptionsStatementAcademicTermEnum[keyof typeof ChooseElectiveOptionsStatementAcademicTermEnum];


/**
 * Check if a given object implements the ChooseElectiveOptionsStatement interface.
 */
export function instanceOfChooseElectiveOptionsStatement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "academicYear" in value;
    isInstance = isInstance && "academicTerm" in value;
    isInstance = isInstance && "choices" in value;

    return isInstance;
}

export function ChooseElectiveOptionsStatementFromJSON(json: any): ChooseElectiveOptionsStatement {
    return ChooseElectiveOptionsStatementFromJSONTyped(json, false);
}

export function ChooseElectiveOptionsStatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChooseElectiveOptionsStatement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'printFileId': !exists(json, 'printFileId') ? undefined : json['printFileId'],
        'type': json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'academicYear': json['academicYear'],
        'academicTerm': json['academicTerm'],
        'choices': ((json['choices'] as Array<any>).map(ChooseElectiveOptionsStatementAllOfChoicesFromJSON)),
        'embedded': !exists(json, '_embedded') ? undefined : ChangeElectiveOptionsStatementAllOfEmbeddedFromJSON(json['_embedded']),
    };
}

export function ChooseElectiveOptionsStatementToJSON(value?: ChooseElectiveOptionsStatement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'academicYear': value.academicYear,
        'academicTerm': value.academicTerm,
        'choices': ((value.choices as Array<any>).map(ChooseElectiveOptionsStatementAllOfChoicesToJSON)),
        '_embedded': ChangeElectiveOptionsStatementAllOfEmbeddedToJSON(value.embedded),
    };
}

