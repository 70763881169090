/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalEventEmbedded } from './ExternalEventEmbedded';
import {
    ExternalEventEmbeddedFromJSON,
    ExternalEventEmbeddedFromJSONTyped,
    ExternalEventEmbeddedToJSON,
} from './ExternalEventEmbedded';

/**
 * 
 * @export
 * @interface ExternalEvent
 */
export interface ExternalEvent {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ExternalEvent
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvent
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalEvent
     */
    description: string;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvent
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ExternalEvent
     */
    endAt: Date;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ExternalEvent
     */
    locationId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExternalEvent
     */
    organizers?: Array<string>;
    /**
     * 
     * @type {ExternalEventEmbedded}
     * @memberof ExternalEvent
     */
    embedded?: ExternalEventEmbedded;
}

/**
 * Check if a given object implements the ExternalEvent interface.
 */
export function instanceOfExternalEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "endAt" in value;

    return isInstance;
}

export function ExternalEventFromJSON(json: any): ExternalEvent {
    return ExternalEventFromJSONTyped(json, false);
}

export function ExternalEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'description': json['description'],
        'startAt': (new Date(json['startAt'])),
        'endAt': (new Date(json['endAt'])),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'organizers': !exists(json, 'organizers') ? undefined : json['organizers'],
        'embedded': !exists(json, '_embedded') ? undefined : ExternalEventEmbeddedFromJSON(json['_embedded']),
    };
}

export function ExternalEventToJSON(value?: ExternalEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'startAt': (value.startAt.toISOString()),
        'endAt': (value.endAt.toISOString()),
        'locationId': value.locationId,
        'organizers': value.organizers,
        '_embedded': ExternalEventEmbeddedToJSON(value.embedded),
    };
}

