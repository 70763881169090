/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ElectiveType } from './ElectiveType';
import {
    ElectiveTypeFromJSON,
    ElectiveTypeFromJSONTyped,
    ElectiveTypeToJSON,
} from './ElectiveType';
import type { OptionCohortSettings } from './OptionCohortSettings';
import {
    OptionCohortSettingsFromJSON,
    OptionCohortSettingsFromJSONTyped,
    OptionCohortSettingsToJSON,
} from './OptionCohortSettings';

/**
 * 
 * @export
 * @interface BuildElectiveJobStep
 */
export interface BuildElectiveJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof BuildElectiveJobStep
     */
    id?: string;
    /**
     * 
     * @type {ElectiveType}
     * @memberof BuildElectiveJobStep
     */
    type?: ElectiveType;
    /**
     * 
     * @type {string}
     * @memberof BuildElectiveJobStep
     */
    status?: BuildElectiveJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof BuildElectiveJobStep
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof BuildElectiveJobStep
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof BuildElectiveJobStep
     */
    section?: string;
    /**
     * The plan period of study.
     * @type {string}
     * @memberof BuildElectiveJobStep
     */
    periodOfStudy?: string;
    /**
     * 
     * @type {OptionCohortSettings}
     * @memberof BuildElectiveJobStep
     */
    cohort?: OptionCohortSettings;
}


/**
 * @export
 */
export const BuildElectiveJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type BuildElectiveJobStepStatusEnum = typeof BuildElectiveJobStepStatusEnum[keyof typeof BuildElectiveJobStepStatusEnum];


/**
 * Check if a given object implements the BuildElectiveJobStep interface.
 */
export function instanceOfBuildElectiveJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuildElectiveJobStepFromJSON(json: any): BuildElectiveJobStep {
    return BuildElectiveJobStepFromJSONTyped(json, false);
}

export function BuildElectiveJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildElectiveJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : ElectiveTypeFromJSON(json['type']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'periodOfStudy': !exists(json, 'periodOfStudy') ? undefined : json['periodOfStudy'],
        'cohort': !exists(json, 'cohort') ? undefined : OptionCohortSettingsFromJSON(json['cohort']),
    };
}

export function BuildElectiveJobStepToJSON(value?: BuildElectiveJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': ElectiveTypeToJSON(value.type),
        'status': value.status,
        'progress': value.progress,
        'name': value.name,
        'section': value.section,
        'periodOfStudy': value.periodOfStudy,
        'cohort': OptionCohortSettingsToJSON(value.cohort),
    };
}

