/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsolidationEmbedded } from './ConsolidationEmbedded';
import {
    ConsolidationEmbeddedFromJSON,
    ConsolidationEmbeddedFromJSONTyped,
    ConsolidationEmbeddedToJSON,
} from './ConsolidationEmbedded';

/**
 * 
 * @export
 * @interface PostConsolidationRequest
 */
export interface PostConsolidationRequest {
    /**
     * 
     * @type {string}
     * @memberof PostConsolidationRequest
     */
    readonly id?: string;
    /**
     * The elective resource ID.
     * @type {string}
     * @memberof PostConsolidationRequest
     */
    electiveId: string;
    /**
     * The author user ID.
     * @type {string}
     * @memberof PostConsolidationRequest
     */
    readonly authorId?: string;
    /**
     * 
     * @type {string}
     * @memberof PostConsolidationRequest
     */
    readonly parentId?: string | null;
    /**
     * State controll mechanism. Pass the value of the last consolidation. 
     * 
     * If first consolidation for an elective is created - use the value of 1.
     * If overriding an existing finalized consolidation use its revision.
     * @type {number}
     * @memberof PostConsolidationRequest
     */
    revision: number;
    /**
     * 
     * @type {any}
     * @memberof PostConsolidationRequest
     */
    readonly status?: any | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof PostConsolidationRequest
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof PostConsolidationRequest
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ConsolidationEmbedded}
     * @memberof PostConsolidationRequest
     */
    embedded?: ConsolidationEmbedded;
    /**
     * 
     * @type {any}
     * @memberof PostConsolidationRequest
     */
    readonly users?: any | null;
}

/**
 * Check if a given object implements the PostConsolidationRequest interface.
 */
export function instanceOfPostConsolidationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "revision" in value;

    return isInstance;
}

export function PostConsolidationRequestFromJSON(json: any): PostConsolidationRequest {
    return PostConsolidationRequestFromJSONTyped(json, false);
}

export function PostConsolidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostConsolidationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'electiveId': json['electiveId'],
        'authorId': !exists(json, 'authorId') ? undefined : json['authorId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'revision': json['revision'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : ConsolidationEmbeddedFromJSON(json['_embedded']),
        'users': !exists(json, 'users') ? undefined : json['users'],
    };
}

export function PostConsolidationRequestToJSON(value?: PostConsolidationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'revision': value.revision,
        '_embedded': ConsolidationEmbeddedToJSON(value.embedded),
    };
}

