/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Option,
} from '../models';
import {
    OptionFromJSON,
    OptionToJSON,
} from '../models';

export interface DeleteOptionRequest {
    id: string;
    slug?: boolean;
}

export interface GetOptionRequest {
    id: string;
    slug?: boolean;
    expand?: string;
}

export interface GetOptionCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface PatchOptionRequest {
    id: string;
    slug?: boolean;
    expand?: string;
    option?: Option;
}

export interface PostOptionRequest {
    option?: Option;
}

/**
 * 
 */
export class OptionApi extends runtime.BaseAPI {

    /**
     * Delete option
     */
    async deleteOptionRaw(requestParameters: DeleteOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/options/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete option
     */
    async deleteOption(requestParameters: DeleteOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOptionRaw(requestParameters, initOverrides);
    }

    /**
     * Get option
     */
    async getOptionRaw(requestParameters: GetOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Option>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/options/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionFromJSON(jsonValue));
    }

    /**
     * Get option
     */
    async getOption(requestParameters: GetOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Option> {
        const response = await this.getOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse option
     */
    async getOptionCollectionRaw(requestParameters: GetOptionCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Option>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(OptionFromJSON));
    }

    /**
     * Browse option
     */
    async getOptionCollection(requestParameters: GetOptionCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Option>> {
        const response = await this.getOptionCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update option
     */
    async patchOptionRaw(requestParameters: PatchOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Option>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchOption.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/options/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: OptionToJSON(requestParameters.option),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionFromJSON(jsonValue));
    }

    /**
     * Update option
     */
    async patchOption(requestParameters: PatchOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Option> {
        const response = await this.patchOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create option
     */
    async postOptionRaw(requestParameters: PostOptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Option>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/options`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OptionToJSON(requestParameters.option),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OptionFromJSON(jsonValue));
    }

    /**
     * Create option
     */
    async postOption(requestParameters: PostOptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Option> {
        const response = await this.postOptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
