/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface CalendarElectiveEmbedded
 */
export interface CalendarElectiveEmbedded {
    /**
     * 
     * @type {Array<Option>}
     * @memberof CalendarElectiveEmbedded
     */
    options?: Array<Option>;
    /**
     * Departments of all member users of a given elective.
     * @type {Array<string>}
     * @memberof CalendarElectiveEmbedded
     */
    departments?: Array<string>;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof CalendarElectiveEmbedded
     */
    tags?: Array<Tag>;
}

/**
 * Check if a given object implements the CalendarElectiveEmbedded interface.
 */
export function instanceOfCalendarElectiveEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarElectiveEmbeddedFromJSON(json: any): CalendarElectiveEmbedded {
    return CalendarElectiveEmbeddedFromJSONTyped(json, false);
}

export function CalendarElectiveEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarElectiveEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(OptionFromJSON)),
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
    };
}

export function CalendarElectiveEmbeddedToJSON(value?: CalendarElectiveEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(OptionToJSON)),
        'departments': value.departments,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
    };
}

