<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.1889 25.468C13.5023 23.3709 21.949 18.3548 5.28882 5.77219C19.092 9.78394 25.5676 12.812 26.3933 14.864C27.503 17.6175 14.6082 23.9125 11.1889 25.468ZM32 16C32 7.16398 24.836 0 16 0C7.16398 0 0 7.16398 0 16C0 24.836 7.16398 32 16 32C24.836 32 32 24.8379 32 16Z"
    />
  </svg>
</template>

<style scoped>
svg {
  fill: var(--c-white);
}
</style>
