/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarEventRegistrationEmbeddedRegisteredByEmbedded } from './CalendarEventRegistrationEmbeddedRegisteredByEmbedded';
import {
    CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSON,
    CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSONTyped,
    CalendarEventRegistrationEmbeddedRegisteredByEmbeddedToJSON,
} from './CalendarEventRegistrationEmbeddedRegisteredByEmbedded';

/**
 * 
 * @export
 * @interface Profile
 */
export interface Profile {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof Profile
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly username?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly code?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly middleName?: string;
    /**
     * 
     * @type {string}
     * @memberof Profile
     */
    readonly email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    roles?: Array<string>;
    /**
     * The external option ID.
     * @type {string}
     * @memberof Profile
     */
    readonly externalId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    readonly departments?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof Profile
     */
    readonly specialties?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof Profile
     */
    readonly rating?: number;
    /**
     * 
     * @type {CalendarEventRegistrationEmbeddedRegisteredByEmbedded}
     * @memberof Profile
     */
    embedded?: CalendarEventRegistrationEmbeddedRegisteredByEmbedded;
}

/**
 * Check if a given object implements the Profile interface.
 */
export function instanceOfProfile(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ProfileFromJSON(json: any): Profile {
    return ProfileFromJSONTyped(json, false);
}

export function ProfileFromJSONTyped(json: any, ignoreDiscriminator: boolean): Profile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'code': !exists(json, 'code') ? undefined : json['code'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
        'externalId': !exists(json, 'externalId') ? undefined : json['externalId'],
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
        'specialties': !exists(json, 'specialties') ? undefined : json['specialties'],
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'embedded': !exists(json, '_embedded') ? undefined : CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSON(json['_embedded']),
    };
}

export function ProfileToJSON(value?: Profile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'roles': value.roles,
        '_embedded': CalendarEventRegistrationEmbeddedRegisteredByEmbeddedToJSON(value.embedded),
    };
}

