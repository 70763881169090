<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.2019 5.03525C7.8114 4.42575 8.63805 4.08334 9.5 4.08334H18.1667C18.454 4.08334 18.7295 4.19748 18.9327 4.40064L25.4327 10.9006C25.6359 11.1038 25.75 11.3794 25.75 11.6667V24.6667C25.75 25.5286 25.4076 26.3553 24.7981 26.9648C24.1886 27.5743 23.362 27.9167 22.5 27.9167H9.5C8.63805 27.9167 7.8114 27.5743 7.2019 26.9648C6.59241 26.3553 6.25 25.5286 6.25 24.6667V7.33334C6.25 6.47139 6.59241 5.64474 7.2019 5.03525ZM9.5 6.25001C9.21268 6.25001 8.93713 6.36415 8.73397 6.56731C8.5308 6.77048 8.41667 7.04603 8.41667 7.33334V24.6667C8.41667 24.954 8.5308 25.2295 8.73397 25.4327C8.93713 25.6359 9.21268 25.75 9.5 25.75H22.5C22.7873 25.75 23.0629 25.6359 23.266 25.4327C23.4692 25.2295 23.5833 24.954 23.5833 24.6667V12.1154L17.7179 6.25001H9.5Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.1666 4.08334C18.765 4.08334 19.25 4.56837 19.25 5.16668V10.5833H24.6666C25.265 10.5833 25.75 11.0684 25.75 11.6667C25.75 12.265 25.265 12.75 24.6666 12.75H18.1666C17.5683 12.75 17.0833 12.265 17.0833 11.6667V5.16668C17.0833 4.56837 17.5683 4.08334 18.1666 4.08334Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5833 17.0833C10.5833 16.485 11.0683 16 11.6666 16H20.3333C20.9316 16 21.4166 16.485 21.4166 17.0833C21.4166 17.6816 20.9316 18.1667 20.3333 18.1667H11.6666C11.0683 18.1667 10.5833 17.6816 10.5833 17.0833Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5833 21.4167C10.5833 20.8184 11.0683 20.3333 11.6666 20.3333H20.3333C20.9316 20.3333 21.4166 20.8184 21.4166 21.4167C21.4166 22.015 20.9316 22.5 20.3333 22.5H11.6666C11.0683 22.5 10.5833 22.015 10.5833 21.4167Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5833 12.75C10.5833 12.1517 11.0683 11.6667 11.6666 11.6667H13.8333C14.4316 11.6667 14.9166 12.1517 14.9166 12.75C14.9166 13.3483 14.4316 13.8333 13.8333 13.8333H11.6666C11.0683 13.8333 10.5833 13.3483 10.5833 12.75Z"
    />
  </svg>
</template>

<style scoped>
svg {
  border-radius: 8px;
}

path {
  fill: var(--c-white);
}

.active > svg,
:hover > svg {
  background-color: var(--c-white);
}

.active > svg > path,
:hover > svg > path {
  fill: var(--c-blue);
}

.btn__inline svg {
  background-color: transparent;
}

.btn__inline path {
  fill: var(--c-gray-4);
}

@media only screen and (max-width: 991.98px) {
  path {
    fill: var(--c-gray-4);
  }

  .active > svg,
  :hover > svg {
    background-color: transparent;
  }

  svg > path,
  svg > path {
    fill: var(--c-gray-4);
  }

  .active > svg > path,
  :hover > svg > path {
    fill: var(--c-blue);
  }
}
</style>
