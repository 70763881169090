/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StatementTemplate = {
    TrackResearch: 'track-research',
    TrackGeneric: 'track-generic',
    Specialty: 'specialty'
} as const;
export type StatementTemplate = typeof StatementTemplate[keyof typeof StatementTemplate];


export function StatementTemplateFromJSON(json: any): StatementTemplate {
    return StatementTemplateFromJSONTyped(json, false);
}

export function StatementTemplateFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatementTemplate {
    return json as StatementTemplate;
}

export function StatementTemplateToJSON(value?: StatementTemplate | null): any {
    return value as any;
}

