/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserCohort
 */
export interface UserCohort {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof UserCohort
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserCohort
     */
    type?: UserCohortTypeEnum;
    /**
     * Cohort name.
     * @type {string}
     * @memberof UserCohort
     */
    name?: string;
}


/**
 * @export
 */
export const UserCohortTypeEnum = {
    Academic: 'academic',
    Educational: 'educational',
    Unknown: 'unknown'
} as const;
export type UserCohortTypeEnum = typeof UserCohortTypeEnum[keyof typeof UserCohortTypeEnum];


/**
 * Check if a given object implements the UserCohort interface.
 */
export function instanceOfUserCohort(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserCohortFromJSON(json: any): UserCohort {
    return UserCohortFromJSONTyped(json, false);
}

export function UserCohortFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCohort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function UserCohortToJSON(value?: UserCohort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'name': value.name,
    };
}

