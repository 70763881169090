/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ElectiveChoiceDistributionReportInnerBucketsInner
 */
export interface ElectiveChoiceDistributionReportInnerBucketsInner {
    /**
     * The type of the bucket.
     * @type {string}
     * @memberof ElectiveChoiceDistributionReportInnerBucketsInner
     */
    type: ElectiveChoiceDistributionReportInnerBucketsInnerTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ElectiveChoiceDistributionReportInnerBucketsInner
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ElectiveChoiceDistributionReportInnerBucketsInner
     */
    endAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ElectiveChoiceDistributionReportInnerBucketsInner
     */
    choices: number;
}


/**
 * @export
 */
export const ElectiveChoiceDistributionReportInnerBucketsInnerTypeEnum = {
    Wave: 'wave',
    Main: 'main',
    Fixed: 'fixed'
} as const;
export type ElectiveChoiceDistributionReportInnerBucketsInnerTypeEnum = typeof ElectiveChoiceDistributionReportInnerBucketsInnerTypeEnum[keyof typeof ElectiveChoiceDistributionReportInnerBucketsInnerTypeEnum];


/**
 * Check if a given object implements the ElectiveChoiceDistributionReportInnerBucketsInner interface.
 */
export function instanceOfElectiveChoiceDistributionReportInnerBucketsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "endAt" in value;
    isInstance = isInstance && "choices" in value;

    return isInstance;
}

export function ElectiveChoiceDistributionReportInnerBucketsInnerFromJSON(json: any): ElectiveChoiceDistributionReportInnerBucketsInner {
    return ElectiveChoiceDistributionReportInnerBucketsInnerFromJSONTyped(json, false);
}

export function ElectiveChoiceDistributionReportInnerBucketsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveChoiceDistributionReportInnerBucketsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'startAt': (new Date(json['startAt'])),
        'endAt': (new Date(json['endAt'])),
        'choices': json['choices'],
    };
}

export function ElectiveChoiceDistributionReportInnerBucketsInnerToJSON(value?: ElectiveChoiceDistributionReportInnerBucketsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'startAt': (value.startAt.toISOString()),
        'endAt': (value.endAt.toISOString()),
        'choices': value.choices,
    };
}

