/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ChangeElectiveOptionsStatementReportInner,
  ElectiveChoiceChangesReportInner,
  ElectiveChoiceDistributionReportInner,
  ElectiveChoiceStatsReportInner,
  ElectiveChoicesReportInner,
  UserCohortReportInner,
} from '../models';
import {
    ChangeElectiveOptionsStatementReportInnerFromJSON,
    ChangeElectiveOptionsStatementReportInnerToJSON,
    ElectiveChoiceChangesReportInnerFromJSON,
    ElectiveChoiceChangesReportInnerToJSON,
    ElectiveChoiceDistributionReportInnerFromJSON,
    ElectiveChoiceDistributionReportInnerToJSON,
    ElectiveChoiceStatsReportInnerFromJSON,
    ElectiveChoiceStatsReportInnerToJSON,
    ElectiveChoicesReportInnerFromJSON,
    ElectiveChoicesReportInnerToJSON,
    UserCohortReportInnerFromJSON,
    UserCohortReportInnerToJSON,
} from '../models';

export interface GetChangeElectiveOptionsStatementReportRequest {
    academicYear: string;
    academicTerm: number | null;
}

export interface GetElectiveChoiceChangesReportRequest {
    electiveId: string;
}

export interface GetElectiveChoiceDistributionReportRequest {
    electiveId: string;
}

export interface GetElectiveChoiceStatsReportRequest {
    electiveId: string;
}

export interface GetElectiveChoicesReportRequest {
    electiveId: string;
    limit?: number;
    offset?: number;
    filter?: string;
    expand?: string;
}

export interface GetUserCohortReportRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    expand?: string;
}

/**
 * 
 */
export class ReportApi extends runtime.BaseAPI {

    /**
     * Browse change elective options statement report
     */
    async getChangeElectiveOptionsStatementReportRaw(requestParameters: GetChangeElectiveOptionsStatementReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ChangeElectiveOptionsStatementReportInner>>> {
        if (requestParameters.academicYear === null || requestParameters.academicYear === undefined) {
            throw new runtime.RequiredError('academicYear','Required parameter requestParameters.academicYear was null or undefined when calling getChangeElectiveOptionsStatementReport.');
        }

        if (requestParameters.academicTerm === null || requestParameters.academicTerm === undefined) {
            throw new runtime.RequiredError('academicTerm','Required parameter requestParameters.academicTerm was null or undefined when calling getChangeElectiveOptionsStatementReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.academicYear !== undefined) {
            queryParameters['academicYear'] = requestParameters.academicYear;
        }

        if (requestParameters.academicTerm !== undefined) {
            queryParameters['academicTerm'] = requestParameters.academicTerm;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/change-elective-options-statement`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChangeElectiveOptionsStatementReportInnerFromJSON));
    }

    /**
     * Browse change elective options statement report
     */
    async getChangeElectiveOptionsStatementReport(requestParameters: GetChangeElectiveOptionsStatementReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ChangeElectiveOptionsStatementReportInner>> {
        const response = await this.getChangeElectiveOptionsStatementReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse elective choice changes report
     */
    async getElectiveChoiceChangesReportRaw(requestParameters: GetElectiveChoiceChangesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectiveChoiceChangesReportInner>>> {
        if (requestParameters.electiveId === null || requestParameters.electiveId === undefined) {
            throw new runtime.RequiredError('electiveId','Required parameter requestParameters.electiveId was null or undefined when calling getElectiveChoiceChangesReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.electiveId !== undefined) {
            queryParameters['electiveId'] = requestParameters.electiveId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/elective-choice-changes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElectiveChoiceChangesReportInnerFromJSON));
    }

    /**
     * Browse elective choice changes report
     */
    async getElectiveChoiceChangesReport(requestParameters: GetElectiveChoiceChangesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectiveChoiceChangesReportInner>> {
        const response = await this.getElectiveChoiceChangesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse elective choice distribution report
     */
    async getElectiveChoiceDistributionReportRaw(requestParameters: GetElectiveChoiceDistributionReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectiveChoiceDistributionReportInner>>> {
        if (requestParameters.electiveId === null || requestParameters.electiveId === undefined) {
            throw new runtime.RequiredError('electiveId','Required parameter requestParameters.electiveId was null or undefined when calling getElectiveChoiceDistributionReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.electiveId !== undefined) {
            queryParameters['electiveId'] = requestParameters.electiveId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/elective-choice-distribution`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElectiveChoiceDistributionReportInnerFromJSON));
    }

    /**
     * Browse elective choice distribution report
     */
    async getElectiveChoiceDistributionReport(requestParameters: GetElectiveChoiceDistributionReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectiveChoiceDistributionReportInner>> {
        const response = await this.getElectiveChoiceDistributionReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse elective choice stats report
     */
    async getElectiveChoiceStatsReportRaw(requestParameters: GetElectiveChoiceStatsReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectiveChoiceStatsReportInner>>> {
        if (requestParameters.electiveId === null || requestParameters.electiveId === undefined) {
            throw new runtime.RequiredError('electiveId','Required parameter requestParameters.electiveId was null or undefined when calling getElectiveChoiceStatsReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.electiveId !== undefined) {
            queryParameters['electiveId'] = requestParameters.electiveId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/elective-choice-stats`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElectiveChoiceStatsReportInnerFromJSON));
    }

    /**
     * Browse elective choice stats report
     */
    async getElectiveChoiceStatsReport(requestParameters: GetElectiveChoiceStatsReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectiveChoiceStatsReportInner>> {
        const response = await this.getElectiveChoiceStatsReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use filters to get desired cohorts of user or users:  - `name:SuperPool-22-1` to filter by cohort name. - `userId:some-user-id` to filter by user ID. - `electiveId:some-elective-id` to filter by elective ID. - `academicYear:2022/2023` to filter by elective academic year. - `academicTerm:3` to filter by elective academic term.  Combine any of these filters to get the desired response. 
     * Browse elective choices report
     */
    async getElectiveChoicesReportRaw(requestParameters: GetElectiveChoicesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ElectiveChoicesReportInner>>> {
        if (requestParameters.electiveId === null || requestParameters.electiveId === undefined) {
            throw new runtime.RequiredError('electiveId','Required parameter requestParameters.electiveId was null or undefined when calling getElectiveChoicesReport.');
        }

        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.electiveId !== undefined) {
            queryParameters['electiveId'] = requestParameters.electiveId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/elective-choices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElectiveChoicesReportInnerFromJSON));
    }

    /**
     * Use filters to get desired cohorts of user or users:  - `name:SuperPool-22-1` to filter by cohort name. - `userId:some-user-id` to filter by user ID. - `electiveId:some-elective-id` to filter by elective ID. - `academicYear:2022/2023` to filter by elective academic year. - `academicTerm:3` to filter by elective academic term.  Combine any of these filters to get the desired response. 
     * Browse elective choices report
     */
    async getElectiveChoicesReport(requestParameters: GetElectiveChoicesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ElectiveChoicesReportInner>> {
        const response = await this.getElectiveChoicesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Use filters to get desired cohorts of user or users:  - `name:SuperPool-22-1` to filter by cohort name. - `userId:some-user-id` to filter by user ID. - `electiveId:some-elective-id` to filter by elective ID. - `academicYear:2022/2023` to filter by elective academic year. - `academicTerm:3` to filter by elective academic term.  Combine any of these filters to get the desired response. 
     * Browse user cohorts report
     */
    async getUserCohortReportRaw(requestParameters: GetUserCohortReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<UserCohortReportInner>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/reports/user-cohorts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserCohortReportInnerFromJSON));
    }

    /**
     * Use filters to get desired cohorts of user or users:  - `name:SuperPool-22-1` to filter by cohort name. - `userId:some-user-id` to filter by user ID. - `electiveId:some-elective-id` to filter by elective ID. - `academicYear:2022/2023` to filter by elective academic year. - `academicTerm:3` to filter by elective academic term.  Combine any of these filters to get the desired response. 
     * Browse user cohorts report
     */
    async getUserCohortReport(requestParameters: GetUserCohortReportRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<UserCohortReportInner>> {
        const response = await this.getUserCohortReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
