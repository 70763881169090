/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsolidationRowEmbedded } from './ConsolidationRowEmbedded';
import {
    ConsolidationRowEmbeddedFromJSON,
    ConsolidationRowEmbeddedFromJSONTyped,
    ConsolidationRowEmbeddedToJSON,
} from './ConsolidationRowEmbedded';
import type { UserCohort } from './UserCohort';
import {
    UserCohortFromJSON,
    UserCohortFromJSONTyped,
    UserCohortToJSON,
} from './UserCohort';

/**
 * 
 * @export
 * @interface UserCohortReportInner
 */
export interface UserCohortReportInner {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof UserCohortReportInner
     */
    userId?: string;
    /**
     * 
     * @type {Array<UserCohort>}
     * @memberof UserCohortReportInner
     */
    cohorts?: Array<UserCohort>;
    /**
     * 
     * @type {ConsolidationRowEmbedded}
     * @memberof UserCohortReportInner
     */
    embedded?: ConsolidationRowEmbedded;
}

/**
 * Check if a given object implements the UserCohortReportInner interface.
 */
export function instanceOfUserCohortReportInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function UserCohortReportInnerFromJSON(json: any): UserCohortReportInner {
    return UserCohortReportInnerFromJSONTyped(json, false);
}

export function UserCohortReportInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserCohortReportInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'cohorts': !exists(json, 'cohorts') ? undefined : ((json['cohorts'] as Array<any>).map(UserCohortFromJSON)),
        'embedded': !exists(json, '_embedded') ? undefined : ConsolidationRowEmbeddedFromJSON(json['_embedded']),
    };
}

export function UserCohortReportInnerToJSON(value?: UserCohortReportInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'cohorts': value.cohorts === undefined ? undefined : ((value.cohorts as Array<any>).map(UserCohortToJSON)),
        '_embedded': ConsolidationRowEmbeddedToJSON(value.embedded),
    };
}

