/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarElectiveEmbedded } from './CalendarElectiveEmbedded';
import {
    CalendarElectiveEmbeddedFromJSON,
    CalendarElectiveEmbeddedFromJSONTyped,
    CalendarElectiveEmbeddedToJSON,
} from './CalendarElectiveEmbedded';
import type { CalendarElectiveFlow } from './CalendarElectiveFlow';
import {
    CalendarElectiveFlowFromJSON,
    CalendarElectiveFlowFromJSONTyped,
    CalendarElectiveFlowToJSON,
} from './CalendarElectiveFlow';
import type { CalendarElectiveOutcomeInner } from './CalendarElectiveOutcomeInner';
import {
    CalendarElectiveOutcomeInnerFromJSON,
    CalendarElectiveOutcomeInnerFromJSONTyped,
    CalendarElectiveOutcomeInnerToJSON,
} from './CalendarElectiveOutcomeInner';

/**
 * 
 * @export
 * @interface CalendarElective
 */
export interface CalendarElective {
    /**
     * 
     * @type {string}
     * @memberof CalendarElective
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarElective
     */
    type: CalendarElectiveTypeEnum;
    /**
     * The resource slug. Defaults to resource ID. Must be unique across resources of the same type.
     * @type {string}
     * @memberof CalendarElective
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarElective
     */
    name: string;
    /**
     * 
     * @type {CalendarElectiveFlow}
     * @memberof CalendarElective
     */
    flow: CalendarElectiveFlow;
    /**
     * Indicates whether current user is an elective member or not.
     * @type {boolean}
     * @memberof CalendarElective
     */
    readonly isMember?: boolean;
    /**
     * Indicates whether current user can post a choice or not. This takes timeframe and rating into an account as well as existing choice existance.
     * @type {boolean}
     * @memberof CalendarElective
     */
    readonly canChoose?: boolean;
    /**
     * 
     * @type {Array<CalendarElectiveOutcomeInner>}
     * @memberof CalendarElective
     */
    readonly outcome?: Array<CalendarElectiveOutcomeInner> | null;
    /**
     * A list of filters against User resource.
     * Elective is availabe to the user if any filter matches.
     * @type {Array<string>}
     * @memberof CalendarElective
     */
    userFilters?: Array<string>;
    /**
     * The academic year of the elective.
     * @type {string}
     * @memberof CalendarElective
     */
    academicYear: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof CalendarElective
     */
    academicTerm?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarElective
     */
    description?: string;
    /**
     * Poster file ID.
     * @type {string}
     * @memberof CalendarElective
     */
    posterId?: string;
    /**
     * Supported services (**https only**):
     * - disk.sevsu.ru
     * - Youtube
     * @type {string}
     * @memberof CalendarElective
     */
    videoUrl?: string;
    /**
     * Only *.sevsu.ru domains are supported (**https only**).
     * @type {string}
     * @memberof CalendarElective
     */
    detailsUrl?: string;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof CalendarElective
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof CalendarElective
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {CalendarElectiveEmbedded}
     * @memberof CalendarElective
     */
    embedded?: CalendarElectiveEmbedded;
}


/**
 * @export
 */
export const CalendarElectiveTypeEnum = {
    Calendar: 'calendar'
} as const;
export type CalendarElectiveTypeEnum = typeof CalendarElectiveTypeEnum[keyof typeof CalendarElectiveTypeEnum];


/**
 * Check if a given object implements the CalendarElective interface.
 */
export function instanceOfCalendarElective(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "flow" in value;
    isInstance = isInstance && "academicYear" in value;

    return isInstance;
}

export function CalendarElectiveFromJSON(json: any): CalendarElective {
    return CalendarElectiveFromJSONTyped(json, false);
}

export function CalendarElectiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarElective {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': json['type'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'name': json['name'],
        'flow': CalendarElectiveFlowFromJSON(json['flow']),
        'isMember': !exists(json, 'isMember') ? undefined : json['isMember'],
        'canChoose': !exists(json, 'canChoose') ? undefined : json['canChoose'],
        'outcome': !exists(json, 'outcome') ? undefined : (json['outcome'] === null ? null : (json['outcome'] as Array<any>).map(CalendarElectiveOutcomeInnerFromJSON)),
        'userFilters': !exists(json, 'userFilters') ? undefined : json['userFilters'],
        'academicYear': json['academicYear'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'posterId': !exists(json, 'posterId') ? undefined : json['posterId'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'detailsUrl': !exists(json, 'detailsUrl') ? undefined : json['detailsUrl'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : CalendarElectiveEmbeddedFromJSON(json['_embedded']),
    };
}

export function CalendarElectiveToJSON(value?: CalendarElective | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'slug': value.slug,
        'name': value.name,
        'flow': CalendarElectiveFlowToJSON(value.flow),
        'userFilters': value.userFilters,
        'academicYear': value.academicYear,
        'academicTerm': value.academicTerm,
        'description': value.description,
        'posterId': value.posterId,
        'videoUrl': value.videoUrl,
        'detailsUrl': value.detailsUrl,
        '_embedded': CalendarElectiveEmbeddedToJSON(value.embedded),
    };
}

