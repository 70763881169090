/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Elective } from './Elective';
import {
    ElectiveFromJSON,
    ElectiveFromJSONTyped,
    ElectiveToJSON,
} from './Elective';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface ChangeElectiveOptionsStatementAllOfEmbedded
 */
export interface ChangeElectiveOptionsStatementAllOfEmbedded {
    /**
     * 
     * @type {User}
     * @memberof ChangeElectiveOptionsStatementAllOfEmbedded
     */
    user?: User;
    /**
     * 
     * @type {Array<Elective>}
     * @memberof ChangeElectiveOptionsStatementAllOfEmbedded
     */
    electives?: Array<Elective>;
    /**
     * 
     * @type {Array<Option>}
     * @memberof ChangeElectiveOptionsStatementAllOfEmbedded
     */
    options?: Array<Option>;
}

/**
 * Check if a given object implements the ChangeElectiveOptionsStatementAllOfEmbedded interface.
 */
export function instanceOfChangeElectiveOptionsStatementAllOfEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChangeElectiveOptionsStatementAllOfEmbeddedFromJSON(json: any): ChangeElectiveOptionsStatementAllOfEmbedded {
    return ChangeElectiveOptionsStatementAllOfEmbeddedFromJSONTyped(json, false);
}

export function ChangeElectiveOptionsStatementAllOfEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeElectiveOptionsStatementAllOfEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'electives': !exists(json, 'electives') ? undefined : ((json['electives'] as Array<any>).map(ElectiveFromJSON)),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(OptionFromJSON)),
    };
}

export function ChangeElectiveOptionsStatementAllOfEmbeddedToJSON(value?: ChangeElectiveOptionsStatementAllOfEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'electives': value.electives === undefined ? undefined : ((value.electives as Array<any>).map(ElectiveToJSON)),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(OptionToJSON)),
    };
}

