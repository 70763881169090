import { acceptHMRUpdate, defineStore } from "pinia";
import {
  JobResponseTypeEnum,
  ResponseError,
  OptionCohortSettings,
  CreateElectiveFromDisciplineJobAllOfOptions,
  ElectiveType,
} from "@/iot";
import router from "@/router";
import { Filter, useJob } from "@/stores";
import { debounce } from "lodash";
import { IntegrationApi } from "@/iot/apis";

type CreateElectiveFromDisciplineForm = {
  dryRun: boolean;
  electiveType: ElectiveType;
  periodOfStudy: string;
  academicTerm: number | null;
  planFilters: Array<Filter>;
  name: string;
  disciplineId: string;
  cohort: OptionCohortSettings;
  options: Array<CreateElectiveFromDisciplineJobAllOfOptions>;
};

const emptyForm = (): CreateElectiveFromDisciplineForm =>
  ({
    dryRun: true,
    electiveType: ElectiveType.Discipline,
    periodOfStudy: "2023/2024",
    academicTerm: 1,
    planFilters: [{}],
    name: "",
    disciplineId: "",
    cohort: {
      name: "Уч.гр. {{short_name}}/б-{{y_start}}-{{number}}-о",
      minSize: 15,
      maxSize: 30,
      maxCount: 2,
    },
    options: [{ shortName: "", fullName: "" }],
  }) as CreateElectiveFromDisciplineForm;

export type CreateElectiveFromDisciplineJobState = {
  isLoading: boolean;
  isSaving: boolean;
  isFormShown: boolean;
  form: CreateElectiveFromDisciplineForm | null;
  saveErrors: { message: string; errors: object } | null;
  disciplines: Array<{ k: string | number; v: string }>;
};

export const useCreateElectiveFromDisciplineJob = defineStore({
  id: "createElectiveFromDisciplineJob",
  state: () =>
    ({
      isLoading: false,
      isSaving: false,
      isFormShown: false,
      form: null,
      saveErrors: null,
      disciplines: [],
    }) as CreateElectiveFromDisciplineJobState,
  getters: {
    planFilterStrings({ form }): Array<string> {
      return (
        form?.planFilters?.map((filterObject) =>
          Object.keys(filterObject)
            .map((field) => `${field}:${filterObject[field].join(",")}`)
            .join(";"),
        ) ?? []
      );
    },
  },
  actions: {
    openForm() {
      this.isFormShown = true;
      this.form = emptyForm();
    },
    async saveForm() {
      if (!this.form) {
        this.closeForm();
        return;
      }
      this.isSaving = true;
      try {
        const job = await this.$api.job.postJob({
          jobRequest: {
            ...this.form,
            planFilters: this.planFilterStrings,
            type: JobResponseTypeEnum.CreateElectiveFromDiscipline,
          },
          expand: "artifacts",
        });
        this.closeForm();
        if (job.id) {
          const jobStore = useJob();
          jobStore.id = job.id;
          jobStore.job = job;
          this.closeForm();
          await router.replace({
            name: "job:read",
            params: { id: job.id },
          });
        }
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
        }
      } finally {
        this.isSaving = false;
      }
    },
    closeForm() {
      this.isFormShown = false;
      this.form = null;
    },
    searchDisciplines({
      query,
      loading,
    }: {
      query: string;
      loading: (isLoading: boolean) => void;
    }) {
      if (query.trim().length) {
        loading(true);
        this.internalSearchDisciplines(
          this.$api.integration,
          query,
          loading,
          (disciplines: Array<{ k: string | number; v: string }>) => {
            const currentDiscipline = this.form?.disciplineId
              ? this.disciplines.find(({ k }) => k === this.form?.disciplineId)
              : undefined;
            if (
              currentDiscipline &&
              !disciplines.find(({ k }) => k === this.form?.disciplineId)
            ) {
              disciplines.unshift(currentDiscipline);
            }
            this.disciplines = disciplines;
          },
        );
      }
    },
    internalSearchDisciplines: debounce(
      async (
        integration: IntegrationApi,
        q: string,
        loading: (isLoading: boolean) => void,
        setter: (disciplines: Array<{ k: string | number; v: string }>) => void,
      ) => {
        try {
          const disciplines =
            await integration.getIntegrationDisciplineCollection({
              q,
            });
          setter(
            disciplines.map(({ id, fullName }) => ({
              k: id as string,
              v: fullName as string,
            })),
          );
        } finally {
          loading(false);
        }
      },
      350,
    ),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCreateElectiveFromDisciplineJob, import.meta.hot),
  );
}
