/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Elective,
  PatchElectiveRequest,
} from '../models';
import {
    ElectiveFromJSON,
    ElectiveToJSON,
    PatchElectiveRequestFromJSON,
    PatchElectiveRequestToJSON,
} from '../models';

export interface DeleteElectiveRequest {
    id: string;
    slug?: boolean;
}

export interface GetElectiveRequest {
    id: string;
    slug?: boolean;
    expand?: string;
}

export interface GetElectiveCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface PatchElectiveOperationRequest {
    id: string;
    slug?: boolean;
    expand?: string;
    patchElectiveRequest?: PatchElectiveRequest;
}

export interface PostElectiveRequest {
    elective?: Elective;
}

/**
 * 
 */
export class ElectiveApi extends runtime.BaseAPI {

    /**
     * Delete elective
     */
    async deleteElectiveRaw(requestParameters: DeleteElectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteElective.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/electives/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete elective
     */
    async deleteElective(requestParameters: DeleteElectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteElectiveRaw(requestParameters, initOverrides);
    }

    /**
     * Get elective
     */
    async getElectiveRaw(requestParameters: GetElectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Elective>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getElective.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/electives/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ElectiveFromJSON(jsonValue));
    }

    /**
     * Get elective
     */
    async getElective(requestParameters: GetElectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Elective> {
        const response = await this.getElectiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve electives collection. If scope `elective:admin` not present - only resources availabe to user are sent. 
     * Browse elective
     */
    async getElectiveCollectionRaw(requestParameters: GetElectiveCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Elective>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/electives`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ElectiveFromJSON));
    }

    /**
     * Retrieve electives collection. If scope `elective:admin` not present - only resources availabe to user are sent. 
     * Browse elective
     */
    async getElectiveCollection(requestParameters: GetElectiveCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Elective>> {
        const response = await this.getElectiveCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update elective
     */
    async patchElectiveRaw(requestParameters: PatchElectiveOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Elective>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchElective.');
        }

        const queryParameters: any = {};

        if (requestParameters.slug !== undefined) {
            queryParameters['slug'] = requestParameters.slug;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/electives/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchElectiveRequestToJSON(requestParameters.patchElectiveRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ElectiveFromJSON(jsonValue));
    }

    /**
     * Update elective
     */
    async patchElective(requestParameters: PatchElectiveOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Elective> {
        const response = await this.patchElectiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create elective
     */
    async postElectiveRaw(requestParameters: PostElectiveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Elective>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/electives`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ElectiveToJSON(requestParameters.elective),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ElectiveFromJSON(jsonValue));
    }

    /**
     * Create elective
     */
    async postElective(requestParameters: PostElectiveRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Elective> {
        const response = await this.postElectiveRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
