/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsolidationRow } from './ConsolidationRow';
import {
    ConsolidationRowFromJSON,
    ConsolidationRowFromJSONTyped,
    ConsolidationRowToJSON,
} from './ConsolidationRow';

/**
 * 
 * @export
 * @interface PatchConsolidationRequest
 */
export interface PatchConsolidationRequest {
    /**
     * State controll mechanism. Pass the value of the last consolidation. 
     * 
     * If first consolidation for an elective is created - use the value of 1.
     * If overriding an existing finalized consolidation use its revision.
     * @type {number}
     * @memberof PatchConsolidationRequest
     */
    revision: number;
    /**
     * Updates are applied for all users specified in the request body.
     * @type {Array<ConsolidationRow>}
     * @memberof PatchConsolidationRequest
     */
    users: Array<ConsolidationRow>;
}

/**
 * Check if a given object implements the PatchConsolidationRequest interface.
 */
export function instanceOfPatchConsolidationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "revision" in value;
    isInstance = isInstance && "users" in value;

    return isInstance;
}

export function PatchConsolidationRequestFromJSON(json: any): PatchConsolidationRequest {
    return PatchConsolidationRequestFromJSONTyped(json, false);
}

export function PatchConsolidationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchConsolidationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'revision': json['revision'],
        'users': ((json['users'] as Array<any>).map(ConsolidationRowFromJSON)),
    };
}

export function PatchConsolidationRequestToJSON(value?: PatchConsolidationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'revision': value.revision,
        'users': ((value.users as Array<any>).map(ConsolidationRowToJSON)),
    };
}

