/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarElectiveFlowChoiceWavesInner } from './CalendarElectiveFlowChoiceWavesInner';
import {
    CalendarElectiveFlowChoiceWavesInnerFromJSON,
    CalendarElectiveFlowChoiceWavesInnerFromJSONTyped,
    CalendarElectiveFlowChoiceWavesInnerToJSON,
} from './CalendarElectiveFlowChoiceWavesInner';

/**
 * 
 * @export
 * @interface GenericElectiveFlow
 */
export interface GenericElectiveFlow {
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    shownAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    hiddenAt?: Date | null;
    /**
     * 
     * @type {Array<CalendarElectiveFlowChoiceWavesInner>}
     * @memberof GenericElectiveFlow
     */
    choiceWaves: Array<CalendarElectiveFlowChoiceWavesInner>;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    choiceFixedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    choiceClosedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    finalizedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    statementsOpenedAt: Date | null;
    /**
     * 
     * @type {Date}
     * @memberof GenericElectiveFlow
     */
    statementsClosedAt: Date | null;
    /**
     * Number of primary options to choose.
     * @type {number}
     * @memberof GenericElectiveFlow
     */
    primaryOptions: number;
    /**
     * Number of backup options to choose from if primary unavailable.
     * @type {number}
     * @memberof GenericElectiveFlow
     */
    backupOptions?: number;
}

/**
 * Check if a given object implements the GenericElectiveFlow interface.
 */
export function instanceOfGenericElectiveFlow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shownAt" in value;
    isInstance = isInstance && "choiceWaves" in value;
    isInstance = isInstance && "choiceFixedAt" in value;
    isInstance = isInstance && "choiceClosedAt" in value;
    isInstance = isInstance && "finalizedAt" in value;
    isInstance = isInstance && "statementsOpenedAt" in value;
    isInstance = isInstance && "statementsClosedAt" in value;
    isInstance = isInstance && "primaryOptions" in value;

    return isInstance;
}

export function GenericElectiveFlowFromJSON(json: any): GenericElectiveFlow {
    return GenericElectiveFlowFromJSONTyped(json, false);
}

export function GenericElectiveFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericElectiveFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shownAt': (new Date(json['shownAt'])),
        'hiddenAt': !exists(json, 'hiddenAt') ? undefined : (json['hiddenAt'] === null ? null : new Date(json['hiddenAt'])),
        'choiceWaves': ((json['choiceWaves'] as Array<any>).map(CalendarElectiveFlowChoiceWavesInnerFromJSON)),
        'choiceFixedAt': (new Date(json['choiceFixedAt'])),
        'choiceClosedAt': (new Date(json['choiceClosedAt'])),
        'finalizedAt': (new Date(json['finalizedAt'])),
        'statementsOpenedAt': (json['statementsOpenedAt'] === null ? null : new Date(json['statementsOpenedAt'])),
        'statementsClosedAt': (json['statementsClosedAt'] === null ? null : new Date(json['statementsClosedAt'])),
        'primaryOptions': json['primaryOptions'],
        'backupOptions': !exists(json, 'backupOptions') ? undefined : json['backupOptions'],
    };
}

export function GenericElectiveFlowToJSON(value?: GenericElectiveFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shownAt': (value.shownAt.toISOString()),
        'hiddenAt': value.hiddenAt === undefined ? undefined : (value.hiddenAt === null ? null : value.hiddenAt.toISOString()),
        'choiceWaves': ((value.choiceWaves as Array<any>).map(CalendarElectiveFlowChoiceWavesInnerToJSON)),
        'choiceFixedAt': (value.choiceFixedAt.toISOString()),
        'choiceClosedAt': (value.choiceClosedAt.toISOString()),
        'finalizedAt': (value.finalizedAt.toISOString()),
        'statementsOpenedAt': (value.statementsOpenedAt === null ? null : value.statementsOpenedAt.toISOString()),
        'statementsClosedAt': (value.statementsClosedAt === null ? null : value.statementsClosedAt.toISOString()),
        'primaryOptions': value.primaryOptions,
        'backupOptions': value.backupOptions,
    };
}

