/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CalendarElective,
    instanceOfCalendarElective,
    CalendarElectiveFromJSON,
    CalendarElectiveFromJSONTyped,
    CalendarElectiveToJSON,
} from './CalendarElective';
import {
    GenericElective,
    instanceOfGenericElective,
    GenericElectiveFromJSON,
    GenericElectiveFromJSONTyped,
    GenericElectiveToJSON,
} from './GenericElective';

/**
 * @type Elective
 * 
 * @export
 */
export type Elective = { type: 'calendar' } & CalendarElective | { type: 'discipline' } & GenericElective | { type: 'facultative' } & GenericElective | { type: 'leveled' } & GenericElective | { type: 'section' } & GenericElective | { type: 'unrestricted' } & GenericElective;

export function ElectiveFromJSON(json: any): Elective {
    return ElectiveFromJSONTyped(json, false);
}

export function ElectiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): Elective {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'calendar':
            return {...CalendarElectiveFromJSONTyped(json, true), type: 'calendar'};
        case 'discipline':
            return {...GenericElectiveFromJSONTyped(json, true), type: 'discipline'};
        case 'facultative':
            return {...GenericElectiveFromJSONTyped(json, true), type: 'facultative'};
        case 'leveled':
            return {...GenericElectiveFromJSONTyped(json, true), type: 'leveled'};
        case 'section':
            return {...GenericElectiveFromJSONTyped(json, true), type: 'section'};
        case 'unrestricted':
            return {...GenericElectiveFromJSONTyped(json, true), type: 'unrestricted'};
        default:
            throw new Error(`No variant of Elective exists with 'type=${json['type']}'`);
    }
}

export function ElectiveToJSON(value?: Elective | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'calendar':
            return CalendarElectiveToJSON(value);
        case 'discipline':
            return GenericElectiveToJSON(value);
        case 'facultative':
            return GenericElectiveToJSON(value);
        case 'leveled':
            return GenericElectiveToJSON(value);
        case 'section':
            return GenericElectiveToJSON(value);
        case 'unrestricted':
            return GenericElectiveToJSON(value);
        default:
            throw new Error(`No variant of Elective exists with 'type=${value['type']}'`);
    }

}

