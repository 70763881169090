/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarElectiveFlowChoiceWavesInner } from './CalendarElectiveFlowChoiceWavesInner';
import {
    CalendarElectiveFlowChoiceWavesInnerFromJSON,
    CalendarElectiveFlowChoiceWavesInnerFromJSONTyped,
    CalendarElectiveFlowChoiceWavesInnerToJSON,
} from './CalendarElectiveFlowChoiceWavesInner';
import type { CalendarElectiveFlowEvent } from './CalendarElectiveFlowEvent';
import {
    CalendarElectiveFlowEventFromJSON,
    CalendarElectiveFlowEventFromJSONTyped,
    CalendarElectiveFlowEventToJSON,
} from './CalendarElectiveFlowEvent';

/**
 * 
 * @export
 * @interface CalendarElectiveFlow
 */
export interface CalendarElectiveFlow {
    /**
     * 
     * @type {Date}
     * @memberof CalendarElectiveFlow
     */
    shownAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarElectiveFlow
     */
    hiddenAt?: Date | null;
    /**
     * 
     * @type {Array<CalendarElectiveFlowChoiceWavesInner>}
     * @memberof CalendarElectiveFlow
     */
    choiceWaves: Array<CalendarElectiveFlowChoiceWavesInner>;
    /**
     * 
     * @type {Date}
     * @memberof CalendarElectiveFlow
     */
    choiceFixedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarElectiveFlow
     */
    choiceClosedAt?: Date;
    /**
     * Number of options to choose.
     * @type {number}
     * @memberof CalendarElectiveFlow
     */
    primaryOptions?: number;
    /**
     * 
     * @type {CalendarElectiveFlowEvent}
     * @memberof CalendarElectiveFlow
     */
    event: CalendarElectiveFlowEvent;
}

/**
 * Check if a given object implements the CalendarElectiveFlow interface.
 */
export function instanceOfCalendarElectiveFlow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "shownAt" in value;
    isInstance = isInstance && "choiceWaves" in value;
    isInstance = isInstance && "event" in value;

    return isInstance;
}

export function CalendarElectiveFlowFromJSON(json: any): CalendarElectiveFlow {
    return CalendarElectiveFlowFromJSONTyped(json, false);
}

export function CalendarElectiveFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarElectiveFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'shownAt': (new Date(json['shownAt'])),
        'hiddenAt': !exists(json, 'hiddenAt') ? undefined : (json['hiddenAt'] === null ? null : new Date(json['hiddenAt'])),
        'choiceWaves': ((json['choiceWaves'] as Array<any>).map(CalendarElectiveFlowChoiceWavesInnerFromJSON)),
        'choiceFixedAt': !exists(json, 'choiceFixedAt') ? undefined : (new Date(json['choiceFixedAt'])),
        'choiceClosedAt': !exists(json, 'choiceClosedAt') ? undefined : (new Date(json['choiceClosedAt'])),
        'primaryOptions': !exists(json, 'primaryOptions') ? undefined : json['primaryOptions'],
        'event': CalendarElectiveFlowEventFromJSON(json['event']),
    };
}

export function CalendarElectiveFlowToJSON(value?: CalendarElectiveFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shownAt': (value.shownAt.toISOString()),
        'hiddenAt': value.hiddenAt === undefined ? undefined : (value.hiddenAt === null ? null : value.hiddenAt.toISOString()),
        'choiceWaves': ((value.choiceWaves as Array<any>).map(CalendarElectiveFlowChoiceWavesInnerToJSON)),
        'choiceFixedAt': value.choiceFixedAt === undefined ? undefined : (value.choiceFixedAt.toISOString()),
        'choiceClosedAt': value.choiceClosedAt === undefined ? undefined : (value.choiceClosedAt.toISOString()),
        'primaryOptions': value.primaryOptions,
        'event': CalendarElectiveFlowEventToJSON(value.event),
    };
}

