/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobArtifact } from './JobArtifact';
import {
    JobArtifactFromJSON,
    JobArtifactFromJSONTyped,
    JobArtifactToJSON,
} from './JobArtifact';
import type { JobMessage } from './JobMessage';
import {
    JobMessageFromJSON,
    JobMessageFromJSONTyped,
    JobMessageToJSON,
} from './JobMessage';

/**
 * 
 * @export
 * @interface JobResponseEmbedded
 */
export interface JobResponseEmbedded {
    /**
     * 
     * @type {Array<JobMessage>}
     * @memberof JobResponseEmbedded
     */
    messages?: Array<JobMessage>;
    /**
     * 
     * @type {Array<JobArtifact>}
     * @memberof JobResponseEmbedded
     */
    artifacts?: Array<JobArtifact>;
}

/**
 * Check if a given object implements the JobResponseEmbedded interface.
 */
export function instanceOfJobResponseEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobResponseEmbeddedFromJSON(json: any): JobResponseEmbedded {
    return JobResponseEmbeddedFromJSONTyped(json, false);
}

export function JobResponseEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResponseEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'messages': !exists(json, 'messages') ? undefined : ((json['messages'] as Array<any>).map(JobMessageFromJSON)),
        'artifacts': !exists(json, 'artifacts') ? undefined : ((json['artifacts'] as Array<any>).map(JobArtifactFromJSON)),
    };
}

export function JobResponseEmbeddedToJSON(value?: JobResponseEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'messages': value.messages === undefined ? undefined : ((value.messages as Array<any>).map(JobMessageToJSON)),
        'artifacts': value.artifacts === undefined ? undefined : ((value.artifacts as Array<any>).map(JobArtifactToJSON)),
    };
}

