import { Filter } from "@/stores";
import { parse } from "csv/browser/esm/sync";

export function guid(): string {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
  return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

export function parseFilters(filterList: Array<string>) {
  const parsed = filterList.map(
    (filterString): Filter =>
      filterString.split(";").reduce((map: Filter, filter) => {
        const [field, valuesRaw] = filter.split(":");
        map[field] = parse(valuesRaw)[0] ?? "";

        return map;
      }, {}),
  );

  if (parsed.length === 0) {
    parsed.push({});
  }

  return parsed;
}

export function generateEnumOptions(
  t: (key: string) => string,
  originalEnum: { [k: string]: string },
  prefix: string,
  excludeMissing = true,
): { k: string; v: string }[] {
  const options = [];
  for (const enumValue of Object.values(originalEnum)) {
    const key = `${prefix}.${enumValue}`;
    const translated = t(key);
    if (translated !== key || !excludeMissing) {
      options.push({ k: enumValue, v: translated || enumValue });
    }
  }

  return options;
}
