/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobArtifactEmbedded } from './JobArtifactEmbedded';
import {
    JobArtifactEmbeddedFromJSON,
    JobArtifactEmbeddedFromJSONTyped,
    JobArtifactEmbeddedToJSON,
} from './JobArtifactEmbedded';

/**
 * 
 * @export
 * @interface JobArtifact
 */
export interface JobArtifact {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof JobArtifact
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobArtifact
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof JobArtifact
     */
    role?: JobArtifactRoleEnum;
    /**
     * Artifact payload can be either an object or an array
     * @type {object}
     * @memberof JobArtifact
     */
    payload?: object | null;
    /**
     * 
     * @type {string}
     * @memberof JobArtifact
     */
    fileId?: string | null;
    /**
     * 
     * @type {JobArtifactEmbedded}
     * @memberof JobArtifact
     */
    embedded?: JobArtifactEmbedded;
}


/**
 * @export
 */
export const JobArtifactRoleEnum = {
    IntegrationRequest: 'integration-request',
    IntegrationResponse: 'integration-response',
    PlanIdList: 'plan-id-list',
    PlanList: 'plan-list',
    Plan: 'plan',
    PlanSection: 'plan-section',
    DisciplineList: 'discipline-list',
    Elective: 'elective',
    Option: 'option',
    CohortNameList: 'cohort-name-list',
    Cohort: 'cohort',
    UserIdList: 'user-id-list',
    ConsolidationUserOptionResult: 'consolidation-user-option-result',
    AcademicTerms: 'academic-terms',
    ConsolidationDistribution: 'consolidation-distribution',
    ConsolidationCohortDistribution: 'consolidation-cohort-distribution',
    ExportFile: 'export-file',
    Consolidation: 'consolidation'
} as const;
export type JobArtifactRoleEnum = typeof JobArtifactRoleEnum[keyof typeof JobArtifactRoleEnum];


/**
 * Check if a given object implements the JobArtifact interface.
 */
export function instanceOfJobArtifact(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobArtifactFromJSON(json: any): JobArtifact {
    return JobArtifactFromJSONTyped(json, false);
}

export function JobArtifactFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobArtifact {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'role': !exists(json, 'role') ? undefined : json['role'],
        'payload': !exists(json, 'payload') ? undefined : json['payload'],
        'fileId': !exists(json, 'fileId') ? undefined : json['fileId'],
        'embedded': !exists(json, '_embedded') ? undefined : JobArtifactEmbeddedFromJSON(json['_embedded']),
    };
}

export function JobArtifactToJSON(value?: JobArtifact | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'role': value.role,
        'payload': value.payload,
        'fileId': value.fileId,
        '_embedded': JobArtifactEmbeddedToJSON(value.embedded),
    };
}

