import { acceptHMRUpdate, defineStore } from "pinia";
import { Profile, ResponseError } from "@/iot";
import { useToast } from "vue-toastification";
import * as Sentry from "@sentry/browser";

export type ProfileState = {
  profile: Profile | null;
  isLoading: boolean;
};

export function userFullName(user: Profile | null | undefined) {
  if (!user) {
    return "";
  }

  return `${user?.lastName} ${user?.firstName} ${user?.middleName}`.trim();
}

export function userCohort(user: Profile | null | undefined) {
  let cohorts = user?.embedded?.cohorts ?? [];
  if (cohorts.length > 1) {
    const noZ = cohorts.filter(({ name }) => name && !name.endsWith("-з"));
    if (noZ.length > 0) {
      cohorts = noZ;
    }
  }

  return cohorts?.[0]?.name ?? null;
}

export function userCourse(user: Profile | null | undefined) {
  const cohort = userCohort(user);
  if (cohort === null) {
    return null;
  }
  const matches = cohort.match(/-(\d+)-/);
  if (matches === null) {
    return null;
  }
  const now = new Date();
  let course = now.getFullYear() - 2000 - parseInt(matches[1]);
  if (now > new Date(`${now.getFullYear()}-09-01T00:00:00.00+03:00`)) {
    course++;
  }

  return course;
}

export function userSpecialty(
  user: Profile | null | undefined,
  isPool: boolean,
) {
  const specialty = user?.specialties?.[0] ?? null;
  if (specialty === null) {
    return null;
  }

  return isPool ? specialty : specialty.substring(0, 6) + "00";
}

export const useUser = defineStore({
  id: "user",
  state: () =>
    ({
      profile: null,
      isLoading: false,
    }) as ProfileState,
  getters: {
    roles: ({ profile }): string[] => {
      return profile?.roles || [];
    },
    can() {
      return (...roles: string[]): boolean =>
        this.roles.includes("*") ||
        roles.some((role) => this.roles.includes(role));
    },
    // defaultPeriod({ profile }): ProfileElectiveStat | null {
    //   const unChosen = profile?.electives?.unChosen.reduce(
    //     (picked: ProfileElectiveStat | null, period) =>
    //       !picked ||
    //       period.academicYear < picked.academicYear ||
    //       (period.academicYear === picked.academicYear &&
    //         period.academicTerm < picked.academicTerm)
    //         ? period
    //         : picked,
    //     null
    //   );
    //   if (unChosen) {
    //     console.log(unChosen);
    //     return unChosen;
    //   }
    //   const open = profile?.electives?.open.reduce(
    //     (picked: ProfileElectiveStat | null, period) =>
    //       !picked ||
    //       period.academicYear < picked.academicYear ||
    //       (period.academicYear === picked.academicYear &&
    //         period.academicTerm < picked.academicTerm)
    //         ? period
    //         : picked,
    //     null
    //   );
    //   if (open) {
    //     console.log(open);
    //     return open;
    //   }
    //   const visible = profile?.electives?.visible.reduce(
    //     (picked: ProfileElectiveStat | null, period) =>
    //       !picked ||
    //       period.academicYear > picked.academicYear ||
    //       (period.academicYear === picked.academicYear &&
    //         period.academicTerm > picked.academicTerm)
    //         ? period
    //         : picked,
    //     null
    //   );
    //   if (visible) {
    //     console.log(visible);
    //     return visible;
    //   }
    //   console.log(null);
    //
    //   return null;
    // },
    defaultAcademicYear() {
      return "2024/2025";
    },
    defaultAcademicTerm() {
      return 1;
    },
  },
  actions: {
    async load(noLoading = false) {
      if (!noLoading) {
        this.isLoading = true;
      }
      try {
        this.profile = await this.$api.user.getProfile({
          expand: "cohorts.option",
        });
        try {
          Sentry.setUser({
            id: this.profile.id,
            email: this.profile.email,
            username: this.profile.username,
          });
        } catch (_) {
          // ignore
        }
      } catch (e) {
        let message = "Не удалось загрузить профиль";
        if (e instanceof ResponseError) {
          message = `${message}: ${(await e.response.json()).message}`;
        }
        useToast().error(message);
      } finally {
        if (!noLoading) {
          this.isLoading = false;
        }
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useUser, import.meta.hot));
}
