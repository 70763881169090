/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CalendarEvent,
  CalendarEventRegistration,
  DeleteCalendarEventRequest,
  ExternalEvent,
} from '../models';
import {
    CalendarEventFromJSON,
    CalendarEventToJSON,
    CalendarEventRegistrationFromJSON,
    CalendarEventRegistrationToJSON,
    DeleteCalendarEventRequestFromJSON,
    DeleteCalendarEventRequestToJSON,
    ExternalEventFromJSON,
    ExternalEventToJSON,
} from '../models';

export interface DeleteCalendarEventOperationRequest {
    id: string;
    deleteCalendarEventRequest?: DeleteCalendarEventRequest;
}

export interface DeleteCalendarEventRegistrationRequest {
    id: string;
}

export interface GetCalendarEventRequest {
    id: string;
    expand?: string;
}

export interface GetCalendarEventCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface GetCalendarEventRegistrationRequest {
    id: string;
    expand?: string;
}

export interface GetCalendarEventRegistrationCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface GetExternalEventCollectionRequest {
    from: Date;
    to: Date;
    expand?: string;
}

export interface PatchCalendarEventRequest {
    id: string;
    expand?: string;
    calendarEvent?: CalendarEvent;
}

export interface PatchCalendarEventRegistrationRequest {
    id: string;
    expand?: string;
    calendarEventRegistration?: CalendarEventRegistration;
}

export interface PostCalendarEventRequest {
    expand?: string;
    calendarEvent?: CalendarEvent;
}

export interface PostCalendarEventRegistrationRequest {
    expand?: string;
    calendarEventRegistration?: CalendarEventRegistration;
}

/**
 * 
 */
export class CalendarEventApi extends runtime.BaseAPI {

    /**
     * Delete calendar event
     */
    async deleteCalendarEventRaw(requestParameters: DeleteCalendarEventOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCalendarEvent.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeleteCalendarEventRequestToJSON(requestParameters.deleteCalendarEventRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete calendar event
     */
    async deleteCalendarEvent(requestParameters: DeleteCalendarEventOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCalendarEventRaw(requestParameters, initOverrides);
    }

    /**
     * Retract or cancel calendar event registration
     */
    async deleteCalendarEventRegistrationRaw(requestParameters: DeleteCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteCalendarEventRegistration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar-event-registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Retract or cancel calendar event registration
     */
    async deleteCalendarEventRegistration(requestParameters: DeleteCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCalendarEventRegistrationRaw(requestParameters, initOverrides);
    }

    /**
     * Get calendar event
     */
    async getCalendarEventRaw(requestParameters: GetCalendarEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEvent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCalendarEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventFromJSON(jsonValue));
    }

    /**
     * Get calendar event
     */
    async getCalendarEvent(requestParameters: GetCalendarEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEvent> {
        const response = await this.getCalendarEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get calendar events
     */
    async getCalendarEventCollectionRaw(requestParameters: GetCalendarEventCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEvent>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar-events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventFromJSON));
    }

    /**
     * Get calendar events
     */
    async getCalendarEventCollection(requestParameters: GetCalendarEventCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEvent>> {
        const response = await this.getCalendarEventCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get calendar event registration
     */
    async getCalendarEventRegistrationRaw(requestParameters: GetCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEventRegistration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getCalendarEventRegistration.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar-event-registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventRegistrationFromJSON(jsonValue));
    }

    /**
     * Get calendar event registration
     */
    async getCalendarEventRegistration(requestParameters: GetCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEventRegistration> {
        const response = await this.getCalendarEventRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get calendar event registrations
     */
    async getCalendarEventRegistrationCollectionRaw(requestParameters: GetCalendarEventRegistrationCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<CalendarEventRegistration>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/calendar-event-registrations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(CalendarEventRegistrationFromJSON));
    }

    /**
     * Get calendar event registrations
     */
    async getCalendarEventRegistrationCollection(requestParameters: GetCalendarEventRegistrationCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<CalendarEventRegistration>> {
        const response = await this.getCalendarEventRegistrationCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get external events
     */
    async getExternalEventCollectionRaw(requestParameters: GetExternalEventCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ExternalEvent>>> {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from','Required parameter requestParameters.from was null or undefined when calling getExternalEventCollection.');
        }

        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to','Required parameter requestParameters.to was null or undefined when calling getExternalEventCollection.');
        }

        const queryParameters: any = {};

        if (requestParameters.from !== undefined) {
            queryParameters['from'] = (requestParameters.from as any).toISOString().substr(0,10);
        }

        if (requestParameters.to !== undefined) {
            queryParameters['to'] = (requestParameters.to as any).toISOString().substr(0,10);
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/external-events`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalEventFromJSON));
    }

    /**
     * Get external events
     */
    async getExternalEventCollection(requestParameters: GetExternalEventCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ExternalEvent>> {
        const response = await this.getExternalEventCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update calendar event
     */
    async patchCalendarEventRaw(requestParameters: PatchCalendarEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEvent>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchCalendarEvent.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar-events/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarEventToJSON(requestParameters.calendarEvent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventFromJSON(jsonValue));
    }

    /**
     * Update calendar event
     */
    async patchCalendarEvent(requestParameters: PatchCalendarEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEvent> {
        const response = await this.patchCalendarEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update calendar event registration
     */
    async patchCalendarEventRegistrationRaw(requestParameters: PatchCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEventRegistration>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchCalendarEventRegistration.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar-event-registrations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarEventRegistrationToJSON(requestParameters.calendarEventRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventRegistrationFromJSON(jsonValue));
    }

    /**
     * Update calendar event registration
     */
    async patchCalendarEventRegistration(requestParameters: PatchCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEventRegistration> {
        const response = await this.patchCalendarEventRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create calendar event
     */
    async postCalendarEventRaw(requestParameters: PostCalendarEventRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEvent>> {
        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar-events`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarEventToJSON(requestParameters.calendarEvent),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventFromJSON(jsonValue));
    }

    /**
     * Create calendar event
     */
    async postCalendarEvent(requestParameters: PostCalendarEventRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEvent> {
        const response = await this.postCalendarEventRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register to calendar event
     */
    async postCalendarEventRegistrationRaw(requestParameters: PostCalendarEventRegistrationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CalendarEventRegistration>> {
        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/calendar-event-registrations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CalendarEventRegistrationToJSON(requestParameters.calendarEventRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CalendarEventRegistrationFromJSON(jsonValue));
    }

    /**
     * Register to calendar event
     */
    async postCalendarEventRegistration(requestParameters: PostCalendarEventRegistrationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CalendarEventRegistration> {
        const response = await this.postCalendarEventRegistrationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
