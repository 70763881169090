/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsolidationExport
 */
export interface ConsolidationExport {
    /**
     * Export spreadsheet title.
     * @type {string}
     * @memberof ConsolidationExport
     */
    title: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ConsolidationExport
     */
    templateFileId?: string;
}

/**
 * Check if a given object implements the ConsolidationExport interface.
 */
export function instanceOfConsolidationExport(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "title" in value;

    return isInstance;
}

export function ConsolidationExportFromJSON(json: any): ConsolidationExport {
    return ConsolidationExportFromJSONTyped(json, false);
}

export function ConsolidationExportFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsolidationExport {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'title': json['title'],
        'templateFileId': !exists(json, 'templateFileId') ? undefined : json['templateFileId'],
    };
}

export function ConsolidationExportToJSON(value?: ConsolidationExport | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'title': value.title,
        'templateFileId': value.templateFileId,
    };
}

