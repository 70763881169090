/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeElectiveOptionsStatementAllOfChanges
 */
export interface ChangeElectiveOptionsStatementAllOfChanges {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChangeElectiveOptionsStatementAllOfChanges
     */
    electiveId: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChangeElectiveOptionsStatementAllOfChanges
     */
    oldOptionId: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChangeElectiveOptionsStatementAllOfChanges
     */
    newOptionId: string;
}

/**
 * Check if a given object implements the ChangeElectiveOptionsStatementAllOfChanges interface.
 */
export function instanceOfChangeElectiveOptionsStatementAllOfChanges(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "oldOptionId" in value;
    isInstance = isInstance && "newOptionId" in value;

    return isInstance;
}

export function ChangeElectiveOptionsStatementAllOfChangesFromJSON(json: any): ChangeElectiveOptionsStatementAllOfChanges {
    return ChangeElectiveOptionsStatementAllOfChangesFromJSONTyped(json, false);
}

export function ChangeElectiveOptionsStatementAllOfChangesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeElectiveOptionsStatementAllOfChanges {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'electiveId': json['electiveId'],
        'oldOptionId': json['oldOptionId'],
        'newOptionId': json['newOptionId'],
    };
}

export function ChangeElectiveOptionsStatementAllOfChangesToJSON(value?: ChangeElectiveOptionsStatementAllOfChanges | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'oldOptionId': value.oldOptionId,
        'newOptionId': value.newOptionId,
    };
}

