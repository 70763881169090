import {
  createI18n as createI18nNative,
  useI18n as useI18nNative,
} from "vue-i18n";
import * as messages from "@/locales/messages";

const options = {
  locale: "ru",
  fallbackLocale: "ru",
  pluralRules: {
    ru: (choice, choicesLength) => {
      if (choice === 0) {
        return 0;
      }

      const teen = choice > 10 && choice < 20;
      const endsWithOne = choice % 10 === 1;
      if (!teen && endsWithOne) {
        return 1;
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2;
      }

      return choicesLength < 4 ? 2 : 3;
    },
  },
  messages,
};

export const i18n = createI18nNative(options);

export const useI18n = () => useI18nNative(options);
