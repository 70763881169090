<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.41669 16C7.81838 16 7.33335 16.485 7.33335 17.0833V24.6667C7.33335 25.265 7.81838 25.75 8.41669 25.75H23.5834C24.1817 25.75 24.6667 25.265 24.6667 24.6667V17.0833C24.6667 16.485 24.1817 16 23.5834 16H8.41669ZM5.16669 17.0833C5.16669 15.2884 6.62176 13.8333 8.41669 13.8333H23.5834C25.3783 13.8333 26.8334 15.2884 26.8334 17.0833V24.6667C26.8334 26.4616 25.3783 27.9167 23.5834 27.9167H8.41669C6.62176 27.9167 5.16669 26.4616 5.16669 24.6667V17.0833Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16 6.25001C14.8507 6.25001 13.7485 6.70656 12.9359 7.51921C12.1232 8.33187 11.6667 9.43407 11.6667 10.5833V14.9167C11.6667 15.515 11.1816 16 10.5833 16C9.98502 16 9.5 15.515 9.5 14.9167V10.5833C9.5 8.85944 10.1848 7.20614 11.4038 5.98715C12.6228 4.76816 14.2761 4.08334 16 4.08334C17.7239 4.08334 19.3772 4.76816 20.5962 5.98715C21.8152 7.20614 22.5 8.85944 22.5 10.5833V14.9167C22.5 15.515 22.015 16 21.4167 16C20.8184 16 20.3333 15.515 20.3333 14.9167V10.5833C20.3333 9.43407 19.8768 8.33187 19.0641 7.51921C18.2515 6.70656 17.1493 6.25001 16 6.25001Z"
    />
  </svg>
</template>

<style scoped>
svg {
  border-radius: 8px;
}

path {
  fill: var(--c-white);
}

.active > svg,
:hover > svg {
  background-color: var(--c-white);
}

.active > svg > path,
:hover > svg > path {
  fill: var(--c-blue);
}

@media only screen and (max-width: 991.98px) {
  svg > * {
    fill: var(--c-gray-4);
  }

  .active > svg,
  :hover > svg {
    background-color: transparent;
  }

  .active > svg > *,
  :hover > svg > * {
    fill: var(--c-blue);
  }
}
</style>
