import { acceptHMRUpdate, defineStore } from "pinia";
import {
  Choice,
  type ChoiceOption,
  Elective,
  Option,
  ResponseError,
} from "@/iot";
import { useToast } from "vue-toastification";
import router from "@/router";

export type ElectiveState = {
  slug: string | null;
  elective: Elective | null;
  choice: Choice | null;
  isLoading: boolean;
  isChoosing: boolean;
  isSaving: boolean;
  primaryChoices: ChoiceOption[];
  backupChoices: ChoiceOption[];
  deletingOption: Option | null;
  saveErrors: { message: string; errors: object } | null;
};

type RichChoiceOption = { option: Option; subOptions: Option[] };

function getOptions(
  elective: Elective | null,
  choiceOptions: ChoiceOption[],
): RichChoiceOption[] {
  if (!elective || !elective.embedded || !elective.embedded.options) {
    return [];
  }
  const result: RichChoiceOption[] = [];
  for (const choiceOption of choiceOptions) {
    const option = elective.embedded.options.find(
      ({ id }) => id === choiceOption.optionId,
    );
    if (option) {
      const optionResult: RichChoiceOption = { option, subOptions: [] };
      for (const subOptionId of choiceOption.subOptionIds ?? []) {
        const subOption = elective.embedded.options.find(
          ({ id }) => id === subOptionId,
        );
        if (subOption) {
          optionResult.subOptions.push(subOption);
        } else {
          console.log(`Can't find a suitable suboption ${subOptionId}`);
        }
      }
      result.push(optionResult);
    } else {
      console.log(`Can't find a suitable option ${choiceOption.optionId}`);
    }
  }

  return result;
}

export const useElective = defineStore({
  id: "elective",
  state: () =>
    ({
      slug: null,
      elective: null,
      isLoading: false,
      isChoosing: false,
      isSaving: false,
      primaryChoices: [] as ChoiceOption[],
      backupChoices: [] as ChoiceOption[],
      deletingOption: null,
      saveErrors: null,
      choice: null,
    }) as ElectiveState,
  getters: {
    canChoose: ({ elective }): boolean =>
      elective !== null && elective.canChoose === true,
    canBeSaved({ elective, primaryChoices, backupChoices }): boolean {
      return (
        elective !== null &&
        this.canChoose &&
        primaryChoices.length === elective.flow.primaryOptions &&
        (backupChoices.length === (elective.flow.backupOptions ?? 0) ||
          this.primaryChosenOptions.some(
            ({ option }) => !option.isBackupAllowed,
          ))
      );
    },
    options: ({ elective }): { [id: string]: Option } =>
      (elective?.embedded?.options || []).reduce(
        (map, option) => ({
          ...map,
          [option.id as string]: option,
        }),
        {},
      ),
    primaryChosenOptions: ({ elective, primaryChoices }) =>
      getOptions(elective, primaryChoices),
    backupChosenOptions: ({ elective, backupChoices }) =>
      getOptions(elective, backupChoices),
  },
  actions: {
    async setSlug(newSlug: string) {
      if (this.slug === newSlug) {
        return;
      }
      this.slug = newSlug;
      return await this.load();
    },
    async load(noLoading = false) {
      if (this.slug === null) {
        return;
      }
      if (!noLoading) {
        this.isLoading = true;
      }
      try {
        this.elective = await this.$api.elective.getElective({
          id: this.slug,
          slug: true,
          expand: "departments,options.tags,tags",
        });

        const choices = await this.$api.choice.getChoiceCollection({
          filter: `electiveId:${this.elective?.id};status:active`,
        });
        this.choice = choices[0] ?? null;

        if (this.choice === null) {
          this.isChoosing = true;
          this.primaryChoices = [];
          this.backupChoices = [];
        }
      } catch (e) {
        useToast().error("Не удалось загрузить блок");
        await router.replace({ name: "home" });
      } finally {
        if (!noLoading) {
          this.isLoading = false;
        }
      }
    },
    async saveChoice() {
      if (!this.elective) {
        return;
      }
      const toast = useToast();
      this.isSaving = true;
      try {
        this.choice = await this.$api.choice.postChoice({
          choice: {
            electiveId: this.elective.id as string,
            primaryOptions: this.primaryChoices,
            backupOptions: this.backupChoices,
          },
        });
        toast.success("Ваш выбор успешно сохранён");
        this.cancelChoosing();
        // noinspection ES6MissingAwait
        this.load(true);
        if (this.choice.statementIds?.length) {
          await router.push({
            name: "statement:read",
            params: { id: this.choice.statementIds[0] },
          });
        }
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
          toast.error(
            this.saveErrors?.message ||
              "Не удалось сохранить выбор. Обновите страницу и попробуйте ещё раз. Если ошибка повторяется обратитесь в техническую поддержку",
          );
        }
      } finally {
        this.isSaving = false;
      }
    },
    startChoosing() {
      if (!this.canChoose) {
        return;
      }
      this.isChoosing = true;
      this.primaryChoices = [...(this.choice?.primaryOptions || [])];
      this.backupChoices = [...(this.choice?.backupOptions || [])];
    },
    cancelChoosing() {
      this.isChoosing = false;
      this.saveErrors = null;
    },
    startDelete(option: Option) {
      this.deletingOption = option;
    },
    async confirmDelete() {
      if (!this.deletingOption || !this.deletingOption.id) {
        return;
      }
      const toast = useToast();
      try {
        await this.$api.option.deleteOption({ id: this.deletingOption.id });
        toast.success("Дисциплина успешно удалена");
      } catch (e) {
        if (e instanceof ResponseError) {
          const resp = await e.response.json();
          toast.error(`Не удалось удалить дисциплину: ${resp.message}`);
        }
      }
      this.deletingOption = null;
      await this.load();
    },
    cancelDelete() {
      this.deletingOption = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useElective, import.meta.hot));
}
