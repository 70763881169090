<template>
  <header class="header">
    <div class="header__logo">
      <IconLogo />
    </div>
    <nav class="header__nav">
      <RouterLink to="/" active-class="active" title="Главная">
        <IconHome />
      </RouterLink>
      <RouterLink to="/electives" active-class="active" title="Список блоков">
        <IconElective />
      </RouterLink>
      <RouterLink to="/statements" active-class="active" title="Заявления">
        <IconStatement />
      </RouterLink>
      <RouterLink
        to="/consolidations"
        active-class="active"
        title="Закрепления"
        v-if="user.can('consolidation:read')"
      >
        <IconConsolidationExists />
      </RouterLink>
    </nav>
    <div class="header__account">
      <button @click="$keycloak.logoutFn" title="Выход">
        <IconLogout />
      </button>
    </div>
  </header>
</template>

<script setup lang="ts">
import { RouterLink } from "vue-router";
import IconLogo from "@/components/icons/IconLogo.vue";
import IconHome from "@/components/icons/IconHome.vue";
import IconElective from "@/components/icons/IconElective.vue";
import IconLogout from "@/components/icons/IconLogout.vue";
import IconStatement from "@/components/icons/IconStatement.vue";
import IconConsolidationExists from "@/components/icons/IconConsolidationExists.vue";
import { useUser } from "@/stores";

const user = useUser();
</script>

<style scoped>
.header {
  display: flex;
  flex-flow: column;
  background-color: var(--c-blue);
  border-radius: 45px;
  color: var(--c-white);
  height: 550px;
  max-height: calc(100% - 110px);
  z-index: 1;
}

.header__logo {
  display: flex;
  margin-bottom: 88px;
  padding: 20px 14px;
}

.header__nav {
  display: flex;
  flex-flow: column;
  gap: 14px;
}

.header__nav > a {
  display: flex;
  justify-content: center;
  padding: 12px 0;
}

.header__account {
  display: flex;
  justify-content: center;
  padding: 20px 0;
  margin-top: auto;
}

.header__account > button {
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  border-radius: 8px;
}

.header__account > button:hover {
  background-color: var(--c-white);
}

@media only screen and (max-width: 991.98px) {
  .header {
    position: fixed;
    bottom: 0;
    z-index: 1000;
    height: auto;
    flex-flow: row;
    width: calc(100% - 36px);
    border-radius: var(--border-radius) var(--border-radius) 0 0;
    justify-content: space-between;
    padding: 7px 24px 8px;
    background-color: var(--c-gray-1);
    border-width: 1px 1px 0;
    border-style: solid;
    border-color: var(--c-gray-3);
    gap: 18px;
    min-width: 300px;
    overflow-x: scroll;
  }

  .header__account {
    padding: 0;
    margin: 0;
  }

  .header__logo,
  .header__account > button,
  .header__nav > a {
    margin: 0;
    padding: 8px;
    border-radius: 100%;
  }

  .header__account > button {
    width: 48px;
    height: 48px;
  }

  .header__logo > svg {
    fill: var(--c-blue);
  }

  .header__nav {
    flex-flow: row;
    gap: 18px;
  }

  .header__account > button:hover,
  .header__nav > a.active,
  .header__nav > a:hover {
    background-color: var(--c-white);
  }
}
</style>
