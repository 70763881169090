/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncConsolidationUserOptionsJobStep
 */
export interface SyncConsolidationUserOptionsJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof SyncConsolidationUserOptionsJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncConsolidationUserOptionsJobStep
     */
    type?: SyncConsolidationUserOptionsJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SyncConsolidationUserOptionsJobStep
     */
    status?: SyncConsolidationUserOptionsJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SyncConsolidationUserOptionsJobStep
     */
    progress?: number;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof SyncConsolidationUserOptionsJobStep
     */
    consolidationId?: string;
}


/**
 * @export
 */
export const SyncConsolidationUserOptionsJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type SyncConsolidationUserOptionsJobStepTypeEnum = typeof SyncConsolidationUserOptionsJobStepTypeEnum[keyof typeof SyncConsolidationUserOptionsJobStepTypeEnum];

/**
 * @export
 */
export const SyncConsolidationUserOptionsJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type SyncConsolidationUserOptionsJobStepStatusEnum = typeof SyncConsolidationUserOptionsJobStepStatusEnum[keyof typeof SyncConsolidationUserOptionsJobStepStatusEnum];


/**
 * Check if a given object implements the SyncConsolidationUserOptionsJobStep interface.
 */
export function instanceOfSyncConsolidationUserOptionsJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SyncConsolidationUserOptionsJobStepFromJSON(json: any): SyncConsolidationUserOptionsJobStep {
    return SyncConsolidationUserOptionsJobStepFromJSONTyped(json, false);
}

export function SyncConsolidationUserOptionsJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncConsolidationUserOptionsJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'consolidationId': !exists(json, 'consolidationId') ? undefined : json['consolidationId'],
    };
}

export function SyncConsolidationUserOptionsJobStepToJSON(value?: SyncConsolidationUserOptionsJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'consolidationId': value.consolidationId,
    };
}

