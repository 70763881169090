/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Discipline } from './Discipline';
import {
    DisciplineFromJSON,
    DisciplineFromJSONTyped,
    DisciplineToJSON,
} from './Discipline';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { Tag } from './Tag';
import {
    TagFromJSON,
    TagFromJSONTyped,
    TagToJSON,
} from './Tag';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface OptionEmbedded
 */
export interface OptionEmbedded {
    /**
     * 
     * @type {Array<Tag>}
     * @memberof OptionEmbedded
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {Discipline}
     * @memberof OptionEmbedded
     */
    discipline?: Discipline;
    /**
     * 
     * @type {Array<Option>}
     * @memberof OptionEmbedded
     */
    children?: Array<Option>;
}

/**
 * Check if a given object implements the OptionEmbedded interface.
 */
export function instanceOfOptionEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OptionEmbeddedFromJSON(json: any): OptionEmbedded {
    return OptionEmbeddedFromJSONTyped(json, false);
}

export function OptionEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagFromJSON)),
        'discipline': !exists(json, 'discipline') ? undefined : DisciplineFromJSON(json['discipline']),
        'children': !exists(json, 'children') ? undefined : ((json['children'] as Array<any>).map(OptionFromJSON)),
    };
}

export function OptionEmbeddedToJSON(value?: OptionEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagToJSON)),
        'discipline': DisciplineToJSON(value.discipline),
        'children': value.children === undefined ? undefined : ((value.children as Array<any>).map(OptionToJSON)),
    };
}

