/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PlanDiscipline } from './PlanDiscipline';
import {
    PlanDisciplineFromJSON,
    PlanDisciplineFromJSONTyped,
    PlanDisciplineToJSON,
} from './PlanDiscipline';

/**
 * 
 * @export
 * @interface EducationalPlan
 */
export interface EducationalPlan {
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    type?: EducationalPlanTypeEnum;
    /**
     * The plan period of study.
     * @type {string}
     * @memberof EducationalPlan
     */
    periodOfStudy?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    level?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    degree?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    specialtyCode?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    specialtyName?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    institution?: string;
    /**
     * 
     * @type {string}
     * @memberof EducationalPlan
     */
    form?: string;
    /**
     * 
     * @type {Array<PlanDiscipline>}
     * @memberof EducationalPlan
     */
    disciplines?: Array<PlanDiscipline>;
}


/**
 * @export
 */
export const EducationalPlanTypeEnum = {
    Working: 'working',
    Educational: 'educational'
} as const;
export type EducationalPlanTypeEnum = typeof EducationalPlanTypeEnum[keyof typeof EducationalPlanTypeEnum];


/**
 * Check if a given object implements the EducationalPlan interface.
 */
export function instanceOfEducationalPlan(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function EducationalPlanFromJSON(json: any): EducationalPlan {
    return EducationalPlanFromJSONTyped(json, false);
}

export function EducationalPlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): EducationalPlan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'periodOfStudy': !exists(json, 'periodOfStudy') ? undefined : json['periodOfStudy'],
        'level': !exists(json, 'level') ? undefined : json['level'],
        'degree': !exists(json, 'degree') ? undefined : json['degree'],
        'specialtyCode': !exists(json, 'specialtyCode') ? undefined : json['specialtyCode'],
        'specialtyName': !exists(json, 'specialtyName') ? undefined : json['specialtyName'],
        'institution': !exists(json, 'institution') ? undefined : json['institution'],
        'form': !exists(json, 'form') ? undefined : json['form'],
        'disciplines': !exists(json, 'disciplines') ? undefined : ((json['disciplines'] as Array<any>).map(PlanDisciplineFromJSON)),
    };
}

export function EducationalPlanToJSON(value?: EducationalPlan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'type': value.type,
        'periodOfStudy': value.periodOfStudy,
        'level': value.level,
        'degree': value.degree,
        'specialtyCode': value.specialtyCode,
        'specialtyName': value.specialtyName,
        'institution': value.institution,
        'form': value.form,
        'disciplines': value.disciplines === undefined ? undefined : ((value.disciplines as Array<any>).map(PlanDisciplineToJSON)),
    };
}

