/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SyncConsolidationBackJobStep
 */
export interface SyncConsolidationBackJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof SyncConsolidationBackJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SyncConsolidationBackJobStep
     */
    type?: SyncConsolidationBackJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SyncConsolidationBackJobStep
     */
    status?: SyncConsolidationBackJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof SyncConsolidationBackJobStep
     */
    progress?: number;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof SyncConsolidationBackJobStep
     */
    electiveId?: string;
}


/**
 * @export
 */
export const SyncConsolidationBackJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type SyncConsolidationBackJobStepTypeEnum = typeof SyncConsolidationBackJobStepTypeEnum[keyof typeof SyncConsolidationBackJobStepTypeEnum];

/**
 * @export
 */
export const SyncConsolidationBackJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type SyncConsolidationBackJobStepStatusEnum = typeof SyncConsolidationBackJobStepStatusEnum[keyof typeof SyncConsolidationBackJobStepStatusEnum];


/**
 * Check if a given object implements the SyncConsolidationBackJobStep interface.
 */
export function instanceOfSyncConsolidationBackJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SyncConsolidationBackJobStepFromJSON(json: any): SyncConsolidationBackJobStep {
    return SyncConsolidationBackJobStepFromJSONTyped(json, false);
}

export function SyncConsolidationBackJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): SyncConsolidationBackJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'electiveId': !exists(json, 'electiveId') ? undefined : json['electiveId'],
    };
}

export function SyncConsolidationBackJobStepToJSON(value?: SyncConsolidationBackJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'electiveId': value.electiveId,
    };
}

