import { acceptHMRUpdate, defineStore } from "pinia";
import { ResponseError, Statement } from "@/iot";
import router from "@/router";
import { useToast } from "vue-toastification";

type ResolutionType = "accept" | "reject";

export type StatementState = {
  id: string | null;
  statement: Statement | null;
  isLoading: boolean;
  showResolutionModal: boolean;
  isResolutionSaving: boolean;
  resolutionType: ResolutionType | null;
  resolutionResponse: string;
};

export const useStatement = defineStore({
  id: "statement",
  state: () =>
    ({
      id: null,
      statement: null,
      isLoading: false,
      showResolutionModal: false,
      isResolutionSaving: false,
      resolutionType: null,
      resolutionResponse: "",
    }) as StatementState,
  actions: {
    setId(id: string) {
      this.id = id;
      return this.load();
    },
    async load() {
      if (!this.id) {
        return;
      }
      this.isLoading = true;
      try {
        this.statement = await this.$api.statement.getStatement({
          id: this.id,
          expand: "electives,options,user.cohorts",
        });
      } catch (e) {
        useToast().error("Не удалось загрузить заявление");
        await router.replace({ name: "statement:browse" });
      } finally {
        this.isLoading = false;
      }
    },
    async withdraw() {
      if (!this.id) {
        return;
      }
      this.isLoading = true;
      try {
        await this.$api.statement.deleteStatement({
          id: this.id,
        });
        await this.load();
      } catch (e) {
        useToast().error("Не удалось отозвать заявление");
      } finally {
        this.isLoading = false;
      }
    },
    startResolution(resolutionType: ResolutionType) {
      this.showResolutionModal = true;
      this.resolutionType = resolutionType;
      this.resolutionResponse = "";
    },
    async saveResolution() {
      if (this.resolutionType === null || !this.statement?.id) {
        return;
      }
      this.isResolutionSaving = true;
      try {
        if (this.resolutionType === "accept") {
          await this.$api.statement.postStatementAccept({
            id: this.statement.id,
            postStatementAcceptRequest: {
              response:
                this.resolutionResponse.trim().length === 0
                  ? null
                  : this.resolutionResponse.trim(),
            },
          });
        } else {
          await this.$api.statement.postStatementReject({
            id: this.statement.id,
            postStatementRejectRequest: {
              response: this.resolutionResponse.trim(),
            },
          });
        }
        this.stopResolution();
      } catch (e) {
        let message = `Не удалось ${
          this.resolutionType === "accept" ? "принять" : "отклонить"
        } заявление`;
        if (e instanceof ResponseError) {
          message = `${message}: ${(await e.response.json()).message}`;
        }
        useToast().error(message);
      } finally {
        this.isResolutionSaving = false;
      }
      await this.load();
    },
    stopResolution() {
      this.showResolutionModal = false;
      this.resolutionType = null;
      this.resolutionResponse = "";
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatement, import.meta.hot));
}
