import {
  ChoiceApi as Choice,
  ConsolidationApi as Consolidation,
  ElectiveApi as Elective,
  FileApi as File,
  IntegrationApi as Integration,
  JobApi as Job,
  OptionApi as Option,
  ReportApi as Report,
  UserApi as User,
  TagApi as Tag,
  StatementApi as Statement,
  CalendarEventApi as CalendarEvent,
} from "@/iot/apis";
import {
  Configuration,
  FetchParams,
  Middleware,
  ResponseContext,
} from "@/iot/runtime";
import { VueKeycloak } from "@/env";

export class AuthMiddleware implements Middleware {
  constructor(
    private token: () => Promise<string | undefined> | string | undefined,
  ) {}

  public async pre(context: ResponseContext): Promise<FetchParams | void> {
    const accessToken = await this.token();
    return {
      url: context.url,
      init: {
        ...context.init,
        headers: new Headers({
          ...context.init.headers,
          Authorization: `Bearer ${accessToken}`,
        }),
      },
    };
  }

  public post(context: ResponseContext): Promise<Response | void> {
    return Promise.resolve(context.response);
  }
}

let api: Api | null = null;

export function useApi(keycloak?: VueKeycloak, basePath?: string): Api {
  if (api === null) {
    if (keycloak === undefined || basePath === undefined) {
      throw new Error("Keycloak and basePath must be defined on first use");
    }
    const config = new Configuration({
      basePath,
      middleware: [new AuthMiddleware(() => keycloak.token)],
    });
    api = createApi(config);
  }

  return api;
}

function createApi(config: Configuration): Api {
  return {
    user: new User(config),
    elective: new Elective(config),
    option: new Option(config),
    choice: new Choice(config),
    consolidation: new Consolidation(config),
    job: new Job(config),
    report: new Report(config),
    integration: new Integration(config),
    file: new File(config),
    tag: new Tag(config),
    statement: new Statement(config),
    calendarEvent: new CalendarEvent(config),
  };
}

export interface Api {
  user: User;
  elective: Elective;
  option: Option;
  choice: Choice;
  consolidation: Consolidation;
  job: Job;
  report: Report;
  integration: Integration;
  file: File;
  tag: Tag;
  statement: Statement;
  calendarEvent: CalendarEvent;
}
