/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { BuildingLinks } from './BuildingLinks';
import {
    BuildingLinksFromJSON,
    BuildingLinksFromJSONTyped,
    BuildingLinksToJSON,
} from './BuildingLinks';
import type { LocationEmbedded } from './LocationEmbedded';
import {
    LocationEmbeddedFromJSON,
    LocationEmbeddedFromJSONTyped,
    LocationEmbeddedToJSON,
} from './LocationEmbedded';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    fullName?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof Location
     */
    buildingId?: string;
    /**
     * 
     * @type {LocationEmbedded}
     * @memberof Location
     */
    embedded?: LocationEmbedded;
    /**
     * 
     * @type {BuildingLinks}
     * @memberof Location
     */
    links?: BuildingLinks;
}

/**
 * Check if a given object implements the Location interface.
 */
export function instanceOfLocation(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'fullName': !exists(json, 'fullName') ? undefined : json['fullName'],
        'buildingId': !exists(json, 'buildingId') ? undefined : json['buildingId'],
        'embedded': !exists(json, '_embedded') ? undefined : LocationEmbeddedFromJSON(json['_embedded']),
        'links': !exists(json, '_links') ? undefined : BuildingLinksFromJSON(json['_links']),
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'shortName': value.shortName,
        'fullName': value.fullName,
        'buildingId': value.buildingId,
        '_embedded': LocationEmbeddedToJSON(value.embedded),
        '_links': BuildingLinksToJSON(value.links),
    };
}

