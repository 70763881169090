/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DistributeCohortConsolidationJobStep
 */
export interface DistributeCohortConsolidationJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof DistributeCohortConsolidationJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DistributeCohortConsolidationJobStep
     */
    type?: DistributeCohortConsolidationJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof DistributeCohortConsolidationJobStep
     */
    status?: DistributeCohortConsolidationJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof DistributeCohortConsolidationJobStep
     */
    progress?: number;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof DistributeCohortConsolidationJobStep
     */
    consolidationId?: string;
}


/**
 * @export
 */
export const DistributeCohortConsolidationJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type DistributeCohortConsolidationJobStepTypeEnum = typeof DistributeCohortConsolidationJobStepTypeEnum[keyof typeof DistributeCohortConsolidationJobStepTypeEnum];

/**
 * @export
 */
export const DistributeCohortConsolidationJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type DistributeCohortConsolidationJobStepStatusEnum = typeof DistributeCohortConsolidationJobStepStatusEnum[keyof typeof DistributeCohortConsolidationJobStepStatusEnum];


/**
 * Check if a given object implements the DistributeCohortConsolidationJobStep interface.
 */
export function instanceOfDistributeCohortConsolidationJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function DistributeCohortConsolidationJobStepFromJSON(json: any): DistributeCohortConsolidationJobStep {
    return DistributeCohortConsolidationJobStepFromJSONTyped(json, false);
}

export function DistributeCohortConsolidationJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): DistributeCohortConsolidationJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'consolidationId': !exists(json, 'consolidationId') ? undefined : json['consolidationId'],
    };
}

export function DistributeCohortConsolidationJobStepToJSON(value?: DistributeCohortConsolidationJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'consolidationId': value.consolidationId,
    };
}

