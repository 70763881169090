import { createRouter, createWebHistory } from "vue-router";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
    },
    {
      path: "/electives",
      name: "elective:browse",
      props: (route) => ({
        academicYear: route.query.academicYear,
        academicTerm: route.query.academicTerm,
      }),
      component: () => import("@/views/BrowseElective.vue"),
    },
    {
      path: "/electives/:slug",
      name: "elective:read",
      props: true,
      component: () => import("@/views/ReadElective.vue"),
    },
    {
      path: "/electives/:slug/events",
      name: "elective-event:browse",
      props: true,
      component: () => import("@/views/ReadElectiveEventCollection.vue"),
    },
    {
      path: "/events/:id/attendance",
      name: "calendar-event:attendance",
      props: true,
      component: () => import("@/views/ReadCalendarEventAttendance.vue"),
    },
    {
      path: "/electives/:slug/reports/choices",
      name: "reports:elective-choices",
      props: true,
      component: () => import("@/views/ReadElectiveChoicesReport.vue"),
    },
    {
      path: "/electives/:slug/reports/choice-stats",
      name: "reports:elective-choice-stats",
      props: true,
      component: () => import("@/views/ReadElectiveChoiceStatsReport.vue"),
    },
    {
      path: "/electives/:slug/reports/choice-distribution",
      name: "reports:elective-choice-distribution",
      props: true,
      component: () =>
        import("@/views/ReadElectiveChoiceDistributionReport.vue"),
    },
    {
      path: "/electives/:slug/reports/choice-changes",
      name: "reports:elective-choice-changes",
      props: true,
      component: () => import("@/views/ReadElectiveChoiceChangesReport.vue"),
    },
    {
      path: "/electives/:slug/consolidations",
      name: "elective:consolidation:browse",
      props: true,
      component: () => import("@/views/BrowseConsolidation.vue"),
    },
    {
      path: "/electives/:slug/consolidations/:id",
      name: "consolidation:read",
      props: true,
      component: () => import("@/views/ReadConsolidation.vue"),
    },
    {
      path: "/consolidations",
      name: "consolidation:browse",
      props: true,
      component: () => import("@/views/BrowseConsolidation.vue"),
    },
    {
      path: "/jobs/:id",
      name: "job:read",
      props: true,
      component: () => import("@/views/ReadJob.vue"),
    },
    {
      path: "/statements",
      name: "statement:browse",
      component: () => import("@/views/BrowseStatement.vue"),
    },
    {
      path: "/statements/add",
      name: "statement:add",
      component: () => import("@/views/AddStatement.vue"),
    },
    {
      path: "/statements/:id",
      name: "statement:read",
      props: true,
      component: () => import("@/views/ReadStatement.vue"),
    },
    {
      path: "/reports/change-elective-options-statement",
      name: "reports:change-elective-options-statement",
      props: true,
      component: () =>
        import("@/views/ReadChangeElectiveOptionsStatementReport.vue"),
    },
  ],
});

export default router;
