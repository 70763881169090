/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ElectiveChoiceChangesReportInnerBucketsInner
 */
export interface ElectiveChoiceChangesReportInnerBucketsInner {
    /**
     * The type of the bucket.
     * @type {string}
     * @memberof ElectiveChoiceChangesReportInnerBucketsInner
     */
    type: ElectiveChoiceChangesReportInnerBucketsInnerTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof ElectiveChoiceChangesReportInnerBucketsInner
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof ElectiveChoiceChangesReportInnerBucketsInner
     */
    endAt: Date;
    /**
     * 
     * @type {number}
     * @memberof ElectiveChoiceChangesReportInnerBucketsInner
     */
    choices: number;
}


/**
 * @export
 */
export const ElectiveChoiceChangesReportInnerBucketsInnerTypeEnum = {
    Wave: 'wave',
    Main: 'main'
} as const;
export type ElectiveChoiceChangesReportInnerBucketsInnerTypeEnum = typeof ElectiveChoiceChangesReportInnerBucketsInnerTypeEnum[keyof typeof ElectiveChoiceChangesReportInnerBucketsInnerTypeEnum];


/**
 * Check if a given object implements the ElectiveChoiceChangesReportInnerBucketsInner interface.
 */
export function instanceOfElectiveChoiceChangesReportInnerBucketsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "endAt" in value;
    isInstance = isInstance && "choices" in value;

    return isInstance;
}

export function ElectiveChoiceChangesReportInnerBucketsInnerFromJSON(json: any): ElectiveChoiceChangesReportInnerBucketsInner {
    return ElectiveChoiceChangesReportInnerBucketsInnerFromJSONTyped(json, false);
}

export function ElectiveChoiceChangesReportInnerBucketsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveChoiceChangesReportInnerBucketsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'startAt': (new Date(json['startAt'])),
        'endAt': (new Date(json['endAt'])),
        'choices': json['choices'],
    };
}

export function ElectiveChoiceChangesReportInnerBucketsInnerToJSON(value?: ElectiveChoiceChangesReportInnerBucketsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'startAt': (value.startAt.toISOString()),
        'endAt': (value.endAt.toISOString()),
        'choices': value.choices,
    };
}

