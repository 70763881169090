/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarEventRegistrationEmbedded } from './CalendarEventRegistrationEmbedded';
import {
    CalendarEventRegistrationEmbeddedFromJSON,
    CalendarEventRegistrationEmbeddedFromJSONTyped,
    CalendarEventRegistrationEmbeddedToJSON,
} from './CalendarEventRegistrationEmbedded';

/**
 * 
 * @export
 * @interface CalendarEventRegistration
 */
export interface CalendarEventRegistration {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    readonly id?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    userId: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    calendarEventId: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    status?: CalendarEventRegistrationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    attendance?: CalendarEventRegistrationAttendanceEnum;
    /**
     * Reason for absence.
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    absentReason?: string | null;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    absentProofFileId?: string | null;
    /**
     * Propagate the status change to the user's other event registrations.
     * @type {boolean}
     * @memberof CalendarEventRegistration
     */
    autoRegister?: boolean;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEventRegistration
     */
    readonly registeredById?: string | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof CalendarEventRegistration
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof CalendarEventRegistration
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {CalendarEventRegistrationEmbedded}
     * @memberof CalendarEventRegistration
     */
    embedded?: CalendarEventRegistrationEmbedded;
}


/**
 * @export
 */
export const CalendarEventRegistrationStatusEnum = {
    Registered: 'registered',
    Retracted: 'retracted',
    Cancelled: 'cancelled'
} as const;
export type CalendarEventRegistrationStatusEnum = typeof CalendarEventRegistrationStatusEnum[keyof typeof CalendarEventRegistrationStatusEnum];

/**
 * @export
 */
export const CalendarEventRegistrationAttendanceEnum = {
    Attended: 'attended',
    Late: 'late',
    MadeUp: 'made-up',
    PendingJustification: 'pending-justification',
    Justified: 'justified',
    Absent: 'absent'
} as const;
export type CalendarEventRegistrationAttendanceEnum = typeof CalendarEventRegistrationAttendanceEnum[keyof typeof CalendarEventRegistrationAttendanceEnum];


/**
 * Check if a given object implements the CalendarEventRegistration interface.
 */
export function instanceOfCalendarEventRegistration(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "calendarEventId" in value;

    return isInstance;
}

export function CalendarEventRegistrationFromJSON(json: any): CalendarEventRegistration {
    return CalendarEventRegistrationFromJSONTyped(json, false);
}

export function CalendarEventRegistrationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventRegistration {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': json['userId'],
        'calendarEventId': json['calendarEventId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'attendance': !exists(json, 'attendance') ? undefined : json['attendance'],
        'absentReason': !exists(json, 'absentReason') ? undefined : json['absentReason'],
        'absentProofFileId': !exists(json, 'absentProofFileId') ? undefined : json['absentProofFileId'],
        'autoRegister': !exists(json, 'autoRegister') ? undefined : json['autoRegister'],
        'registeredById': !exists(json, 'registeredById') ? undefined : json['registeredById'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : CalendarEventRegistrationEmbeddedFromJSON(json['_embedded']),
    };
}

export function CalendarEventRegistrationToJSON(value?: CalendarEventRegistration | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'calendarEventId': value.calendarEventId,
        'status': value.status,
        'attendance': value.attendance,
        'absentReason': value.absentReason,
        'absentProofFileId': value.absentProofFileId,
        'autoRegister': value.autoRegister,
        '_embedded': CalendarEventRegistrationEmbeddedToJSON(value.embedded),
    };
}

