/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChoiceEmbedded } from './ChoiceEmbedded';
import {
    ChoiceEmbeddedFromJSON,
    ChoiceEmbeddedFromJSONTyped,
    ChoiceEmbeddedToJSON,
} from './ChoiceEmbedded';

/**
 * 
 * @export
 * @interface ElectiveChoicesReportInner
 */
export interface ElectiveChoicesReportInner {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ElectiveChoicesReportInner
     */
    userId?: string;
    /**
     * The list of primary options chosen by the user. `null` if no choice was made.
     * @type {Array<string>}
     * @memberof ElectiveChoicesReportInner
     */
    primaryOptions?: Array<string> | null;
    /**
     * The list of backup options chosen by the user. `null` if no choice was made.
     * @type {Array<string>}
     * @memberof ElectiveChoicesReportInner
     */
    backupOptions?: Array<string> | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof ElectiveChoicesReportInner
     */
    readonly createdAt?: Date | null;
    /**
     * 
     * @type {ChoiceEmbedded}
     * @memberof ElectiveChoicesReportInner
     */
    embedded?: ChoiceEmbedded;
}

/**
 * Check if a given object implements the ElectiveChoicesReportInner interface.
 */
export function instanceOfElectiveChoicesReportInner(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ElectiveChoicesReportInnerFromJSON(json: any): ElectiveChoicesReportInner {
    return ElectiveChoicesReportInnerFromJSONTyped(json, false);
}

export function ElectiveChoicesReportInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveChoicesReportInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'primaryOptions': !exists(json, 'primaryOptions') ? undefined : json['primaryOptions'],
        'backupOptions': !exists(json, 'backupOptions') ? undefined : json['backupOptions'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (json['createdAt'] === null ? null : new Date(json['createdAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : ChoiceEmbeddedFromJSON(json['_embedded']),
    };
}

export function ElectiveChoicesReportInnerToJSON(value?: ElectiveChoicesReportInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'primaryOptions': value.primaryOptions,
        'backupOptions': value.backupOptions,
        '_embedded': ChoiceEmbeddedToJSON(value.embedded),
    };
}

