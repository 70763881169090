/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  StoredFile,
} from '../models';
import {
    StoredFileFromJSON,
    StoredFileToJSON,
} from '../models';

export interface GetFileRequest {
    id: string;
}

export interface GetFileDownloadRequest {
    id: string;
    extension: string;
    size?: string;
}

export interface PostFileRequest {
    name: string;
    extension: string;
    file: Blob;
    id?: string;
    description?: string;
    isPublic?: boolean;
    mime?: PostFileMimeEnum;
    size?: number;
    width?: number;
    height?: number;
    sha1?: string;
    createdAt?: Date;
    updatedAt?: Date;
}

/**
 * 
 */
export class FileApi extends runtime.BaseAPI {

    /**
     * Get file
     */
    async getFileRaw(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/files/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFileFromJSON(jsonValue));
    }

    /**
     * Get file
     */
    async getFile(requestParameters: GetFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredFile> {
        const response = await this.getFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Download file
     */
    async getFileDownloadRaw(requestParameters: GetFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getFileDownload.');
        }

        if (requestParameters.extension === null || requestParameters.extension === undefined) {
            throw new runtime.RequiredError('extension','Required parameter requestParameters.extension was null or undefined when calling getFileDownload.');
        }

        const queryParameters: any = {};

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/files/{id}.{extension}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"extension"}}`, encodeURIComponent(String(requestParameters.extension))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Download file
     */
    async getFileDownload(requestParameters: GetFileDownloadRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getFileDownloadRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload file
     */
    async postFileRaw(requestParameters: PostFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredFile>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling postFile.');
        }

        if (requestParameters.extension === null || requestParameters.extension === undefined) {
            throw new runtime.RequiredError('extension','Required parameter requestParameters.extension was null or undefined when calling postFile.');
        }

        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling postFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.id !== undefined) {
            formParams.append('id', new Blob([JSON.stringify(stringToJSON(requestParameters.id))], { type: "application/json", }));
                    }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.extension !== undefined) {
            formParams.append('extension', requestParameters.extension as any);
        }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        if (requestParameters.description !== undefined) {
            formParams.append('description', requestParameters.description as any);
        }

        if (requestParameters.isPublic !== undefined) {
            formParams.append('isPublic', requestParameters.isPublic as any);
        }

        if (requestParameters.mime !== undefined) {
            formParams.append('mime', requestParameters.mime as any);
        }

        if (requestParameters.size !== undefined) {
            formParams.append('size', requestParameters.size as any);
        }

        if (requestParameters.width !== undefined) {
            formParams.append('width', requestParameters.width as any);
        }

        if (requestParameters.height !== undefined) {
            formParams.append('height', requestParameters.height as any);
        }

        if (requestParameters.sha1 !== undefined) {
            formParams.append('sha1', requestParameters.sha1 as any);
        }

        if (requestParameters.createdAt !== undefined) {
            formParams.append('createdAt', requestParameters.createdAt as any);
        }

        if (requestParameters.updatedAt !== undefined) {
            formParams.append('updatedAt', requestParameters.updatedAt as any);
        }

        const response = await this.request({
            path: `/files`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFileFromJSON(jsonValue));
    }

    /**
     * Upload file
     */
    async postFile(requestParameters: PostFileRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredFile> {
        const response = await this.postFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const PostFileMimeEnum = {
    ImagePng: 'image/png',
    ImageJpeg: 'image/jpeg',
    ImageGif: 'image/gif',
    ApplicationPdf: 'application/pdf',
    AudioMpeg: 'audio/mpeg'
} as const;
export type PostFileMimeEnum = typeof PostFileMimeEnum[keyof typeof PostFileMimeEnum];
