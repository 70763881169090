/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ConsolidationUserOption
 */
export interface ConsolidationUserOption {
    /**
     * The sequential number of an option for a given user. Must be in range of 1 to the `elective.flow.primaryOptions`.
     * @type {number}
     * @memberof ConsolidationUserOption
     */
    slot: number;
    /**
     * 
     * @type {string}
     * @memberof ConsolidationUserOption
     */
    optionId: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConsolidationUserOption
     */
    cohort?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConsolidationUserOption
     */
    readonly source?: ConsolidationUserOptionSourceEnum;
}


/**
 * @export
 */
export const ConsolidationUserOptionSourceEnum = {
    Primary: 'primary',
    Backup: 'backup',
    Automated: 'automated',
    Manual: 'manual',
    Synced: 'synced'
} as const;
export type ConsolidationUserOptionSourceEnum = typeof ConsolidationUserOptionSourceEnum[keyof typeof ConsolidationUserOptionSourceEnum];


/**
 * Check if a given object implements the ConsolidationUserOption interface.
 */
export function instanceOfConsolidationUserOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "slot" in value;
    isInstance = isInstance && "optionId" in value;

    return isInstance;
}

export function ConsolidationUserOptionFromJSON(json: any): ConsolidationUserOption {
    return ConsolidationUserOptionFromJSONTyped(json, false);
}

export function ConsolidationUserOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsolidationUserOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'slot': json['slot'],
        'optionId': json['optionId'],
        'cohort': !exists(json, 'cohort') ? undefined : json['cohort'],
        'source': !exists(json, 'source') ? undefined : json['source'],
    };
}

export function ConsolidationUserOptionToJSON(value?: ConsolidationUserOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slot': value.slot,
        'optionId': value.optionId,
        'cohort': value.cohort,
    };
}

