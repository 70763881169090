/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExternalIdentifier } from './ExternalIdentifier';
import {
    ExternalIdentifierFromJSON,
    ExternalIdentifierFromJSONTyped,
    ExternalIdentifierToJSON,
} from './ExternalIdentifier';

/**
 * 
 * @export
 * @interface BuildingLinks
 */
export interface BuildingLinks {
    /**
     * 
     * @type {Array<ExternalIdentifier>}
     * @memberof BuildingLinks
     */
    external?: Array<ExternalIdentifier>;
}

/**
 * Check if a given object implements the BuildingLinks interface.
 */
export function instanceOfBuildingLinks(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function BuildingLinksFromJSON(json: any): BuildingLinks {
    return BuildingLinksFromJSONTyped(json, false);
}

export function BuildingLinksFromJSONTyped(json: any, ignoreDiscriminator: boolean): BuildingLinks {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'external': !exists(json, 'external') ? undefined : ((json['external'] as Array<any>).map(ExternalIdentifierFromJSON)),
    };
}

export function BuildingLinksToJSON(value?: BuildingLinks | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'external': value.external === undefined ? undefined : ((value.external as Array<any>).map(ExternalIdentifierToJSON)),
    };
}

