/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StoredFile } from './StoredFile';
import {
    StoredFileFromJSON,
    StoredFileFromJSONTyped,
    StoredFileToJSON,
} from './StoredFile';

/**
 * 
 * @export
 * @interface JobArtifactEmbedded
 */
export interface JobArtifactEmbedded {
    /**
     * 
     * @type {StoredFile}
     * @memberof JobArtifactEmbedded
     */
    file?: StoredFile;
}

/**
 * Check if a given object implements the JobArtifactEmbedded interface.
 */
export function instanceOfJobArtifactEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobArtifactEmbeddedFromJSON(json: any): JobArtifactEmbedded {
    return JobArtifactEmbeddedFromJSONTyped(json, false);
}

export function JobArtifactEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobArtifactEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'file': !exists(json, 'file') ? undefined : StoredFileFromJSON(json['file']),
    };
}

export function JobArtifactEmbeddedToJSON(value?: JobArtifactEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'file': StoredFileToJSON(value.file),
    };
}

