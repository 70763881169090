/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChoiceEmbedded } from './ChoiceEmbedded';
import {
    ChoiceEmbeddedFromJSON,
    ChoiceEmbeddedFromJSONTyped,
    ChoiceEmbeddedToJSON,
} from './ChoiceEmbedded';
import type { ChoiceOption } from './ChoiceOption';
import {
    ChoiceOptionFromJSON,
    ChoiceOptionFromJSONTyped,
    ChoiceOptionToJSON,
} from './ChoiceOption';

/**
 * 
 * @export
 * @interface Choice
 */
export interface Choice {
    /**
     * 
     * @type {string}
     * @memberof Choice
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof Choice
     */
    readonly userId?: string;
    /**
     * Elective resource ID.
     * @type {string}
     * @memberof Choice
     */
    electiveId: string;
    /**
     * Choice is created as `active`. Use `?filter=status:active` to retrieve only active choices.
     * It becames `inactive` when same user creates new choice for the same `electiveId`.
     * @type {string}
     * @memberof Choice
     */
    readonly status?: ChoiceStatusEnum;
    /**
     * Options must be unique across both `primaryOptions` and `backupOptions`.
     * Option capacity is guarded and place is guaranteed.
     * 
     * The number of options must be equal to `Elective.flow.primaryOptions`.
     * Options must have `ElectiveOption.occupied` less than `ElectiveOption.capacity`.
     * @type {Array<ChoiceOption>}
     * @memberof Choice
     */
    primaryOptions: Array<ChoiceOption>;
    /**
     * Options must be unique across both `primaryOptions` and `backupOptions`.
     * Option capacity is not guarded and place is not guaranteed.
     * If primary options are not available backup options will be used in the order they are provided.
     * 
     * The number of options must be equal to `Elective.flow.backupOptions`.
     * It is recomended to choose options with `ElectiveOption.occupied` less than `ElectiveOption.capacity`.
     * @type {Array<ChoiceOption>}
     * @memberof Choice
     */
    backupOptions: Array<ChoiceOption>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Choice
     */
    statementIds?: Array<string>;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof Choice
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof Choice
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ChoiceEmbedded}
     * @memberof Choice
     */
    embedded?: ChoiceEmbedded;
}


/**
 * @export
 */
export const ChoiceStatusEnum = {
    Active: 'active',
    Inactive: 'inactive'
} as const;
export type ChoiceStatusEnum = typeof ChoiceStatusEnum[keyof typeof ChoiceStatusEnum];


/**
 * Check if a given object implements the Choice interface.
 */
export function instanceOfChoice(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "primaryOptions" in value;
    isInstance = isInstance && "backupOptions" in value;

    return isInstance;
}

export function ChoiceFromJSON(json: any): Choice {
    return ChoiceFromJSONTyped(json, false);
}

export function ChoiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Choice {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'electiveId': json['electiveId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'primaryOptions': ((json['primaryOptions'] as Array<any>).map(ChoiceOptionFromJSON)),
        'backupOptions': ((json['backupOptions'] as Array<any>).map(ChoiceOptionFromJSON)),
        'statementIds': !exists(json, 'statementIds') ? undefined : json['statementIds'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : ChoiceEmbeddedFromJSON(json['_embedded']),
    };
}

export function ChoiceToJSON(value?: Choice | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'primaryOptions': ((value.primaryOptions as Array<any>).map(ChoiceOptionToJSON)),
        'backupOptions': ((value.backupOptions as Array<any>).map(ChoiceOptionToJSON)),
        'statementIds': value.statementIds,
        '_embedded': ChoiceEmbeddedToJSON(value.embedded),
    };
}

