import { acceptHMRUpdate, defineStore } from "pinia";
import {
  CalendarElective,
  CalendarEvent,
  CalendarEventFlowIntersectionPolicyEnum,
  CalendarEventRegistrationAttendanceEnum,
  CalendarEventRegistrationStatusEnum,
  ExternalEvent,
  ResponseError,
} from "@/iot";
import { useToast } from "vue-toastification";
import router from "@/router";
import { format, formatRFC3339, offsetIsoDuration } from "@/utils/date";
import { useUser } from "@/stores/user";
import { i18n } from "@/locales";

type ProvidedRange = { start: string; end: string };
type InternalRange = { start: Date; end: Date };

export type ScheduleCalendarId =
  | "available"
  | "unavailable"
  | "registered"
  | "attended"
  | "absent"
  | "late"
  | "justified"
  | "pending-justification"
  | "external";

export type ScheduleEventShortInfo = {
  title: string;
  people?: string[];
  location?: string;
};

export type ScheduleEventFullInfo = ScheduleEventShortInfo & {
  description?: string;
};

export type ScheduleEvent = {
  // Mandatory for ScheduleX
  id: string;
  title: string;
  start: string;
  end: string;
  calendarId: ScheduleCalendarId;

  // Custom fields
  isExternal: boolean;
  canRegister: boolean;
  canRetract: boolean;
  isOrganizer: boolean;
  electiveId?: string;
  registrationId?: string;
  status?: CalendarEventRegistrationStatusEnum;
  attendance?: CalendarEventRegistrationAttendanceEnum;
  startAt: Date;
  endAt: Date;
  openedAt?: Date;
  closedAt?: Date;
  retractTill?: boolean | Date;
} & (
  | {
      isExternal: false;
      short: ScheduleEventShortInfo & {
        placesLeft: number;
      };
      full: ScheduleEventFullInfo & {
        placesLeft: number;
        capacity: number;
        occupied: number;
      };
    }
  | {
      isExternal: true;
      short: ScheduleEventShortInfo;
      full: ScheduleEventFullInfo;
    }
);

export type CalendarEventsState = {
  elective: CalendarElective | null;
  range: InternalRange | null;
  events: CalendarEvent[] | null;
  externalEvents: ExternalEvent[] | null;
  isLoading: boolean;
  filters: {
    external: boolean;
    otherOptions: boolean;
    otherOrganizers: boolean;
  };
  registering: null | {
    targetEvent: ScheduleEvent;
    conflicts: ScheduleEvent[];
  };
  currentDate: Date;
  search: string;
};

const getCalendarId = (
  event: CalendarEvent,
):
  | "available"
  | "unavailable"
  | "registered"
  | "attended"
  | "absent"
  | "late"
  | "justified"
  | "pending-justification" => {
  if (event.embedded.registration) {
    if (event.embedded.registration.attendance) {
      return event.embedded.registration.attendance;
    }
    if (event.embedded.registration.status === "registered") {
      return "registered";
    }
  }
  if (event.canRegister) {
    return "available";
  }
  return "unavailable";
};

const getEventPeriod = (event: CalendarEvent | ExternalEvent) => {
  if (
    event.startAt.getHours() === 0 &&
    event.startAt.getMinutes() === 0 &&
    event.endAt.getHours() === 23 &&
    event.endAt.getMinutes() === 59
  ) {
    return {
      start: format(event.startAt, "uuuu-MM-dd"),
      end: format(event.endAt, "uuuu-MM-dd"),
    };
  }
  return {
    start: format(event.startAt, "uuuu-MM-dd HH:mm"),
    end: format(event.endAt, "uuuu-MM-dd HH:mm"),
  };
};

const retractTill = (
  event: CalendarEvent,
  elective: CalendarElective,
): undefined | boolean | Date => {
  if (
    event.embedded?.registration?.status !==
    CalendarEventRegistrationStatusEnum.Registered
  ) {
    return undefined;
  }
  const retractInterval =
    event.flow.retractInterval ?? elective?.flow?.event?.retractInterval;
  if (!retractInterval) {
    return undefined;
  }
  switch (retractInterval) {
    case "never":
      return false;
    case "always":
      return true;
    case "before":
      return event.startAt;
    default:
      return offsetIsoDuration(event.startAt, retractInterval);
  }
};

const evaluateCanRetract = (
  event: CalendarEvent,
  elective: CalendarElective | null,
) => {
  const till = retractTill(event, elective);
  const now = new Date();
  if (typeof till === "undefined") {
    return false;
  }
  if (till instanceof Date) {
    return now < till;
  }
  return till;
};

export const useCalendarEvents = defineStore({
  id: "calendarEvents",
  state: () =>
    ({
      elective: null,
      range: null,
      events: null,
      externalEvents: null,
      isLoading: false,
      filters: {
        external: true,
        otherOptions: false,
        otherOrganizers: false,
      },
      registering: null,
      currentDate: new Date(),
      search: "",
    }) as CalendarEventsState,
  getters: {
    availableFilters({
      elective,
      events,
    }: CalendarEventsState): Array<keyof CalendarEventState.filters> {
      const filters = ["external"];
      const userId = useUser().profile?.id;
      if (elective?.isMember) {
        filters.push("otherOptions");
      }
      if (
        userId &&
        events?.some((event) => event.organizerIds?.includes(userId))
      ) {
        filters.push("otherOrganizers");
      }
      return filters;
    },
    internalScheduleEvents({
      events,
      elective,
      filters,
    }: CalendarEventsState): ScheduleEvent[] | null {
      const userId = useUser().profile?.id;
      let eventsRaw = events;
      if (
        this.availableFilters.includes("otherOptions") &&
        !filters.otherOptions
      ) {
        const optionIds =
          elective?.outcome?.map(({ optionId }) => optionId) ?? [];
        eventsRaw = eventsRaw?.filter(
          (event: CalendarEvent) =>
            event.embedded?.registration?.status ===
              CalendarEventRegistrationStatusEnum.Registered ||
            !event.optionId ||
            optionIds.includes(event.optionId),
        );
      }
      if (
        userId &&
        this.availableFilters.includes("otherOrganizers") &&
        !filters.otherOrganizers
      ) {
        eventsRaw = eventsRaw?.filter((event: CalendarEvent) =>
          event.organizerIds?.includes(userId),
        );
      }

      return eventsRaw?.map(
        (event: CalendarEvent): ScheduleEvent => ({
          id: event.id,
          title: event.name,
          ...getEventPeriod(event), // start & end
          calendarId: getCalendarId(event),

          isExternal: false,
          canRegister: event.canRegister,
          canRetract: evaluateCanRetract(event, elective),
          isOrganizer: event.organizerIds?.includes(userId),
          electiveId: event.electiveId,
          registrationId: event.embedded?.registration?.id,
          status: event.embedded?.registration?.status,
          attendance: event.embedded?.registration?.attendance,
          short: {
            title: event.name,
            location: event.embedded?.location?.shortName,
            people: event.embedded?.organizers?.map(({ lastName }) => lastName),
            placesLeft: Math.max(0, event.capacity - event.occupied),
          },
          full: {
            title: event.name,
            description: event.description,
            location: event.embedded?.location
              ? `${event.embedded.location.fullName} ${event.embedded.location.embedded?.building?.fullName}`.trim()
              : undefined,
            people: event.embedded?.organizers?.map((organizer) =>
              `${organizer.lastName} ${organizer.firstName} ${organizer.middleName}`.trim(),
            ),
            placesLeft: Math.max(0, event.capacity - event.occupied),
            capacity: event.capacity,
            occupied: event.occupied,
          },
          startAt: event.startAt,
          endAt: event.endAt,
          openedAt:
            event.openedAt ??
            (elective?.flow.event.registrationOpenInterval
              ? offsetIsoDuration(
                  event.startAt,
                  elective?.flow.event.registrationOpenInterval,
                )
              : undefined),
          closedAt:
            event.closedAt ??
            (elective?.flow.event.registrationCloseInterval
              ? offsetIsoDuration(
                  event.startAt,
                  elective?.flow.event.registrationCloseInterval,
                )
              : undefined),
          retractTill: retractTill(event, elective),
        }),
      );
    },
    externalScheduleEvents({
      externalEvents,
    }: CalendarEventsState): ScheduleEvent[] | null {
      return externalEvents?.map(
        (event): ScheduleEvent => ({
          id: event.id,
          title: event.name,
          ...getEventPeriod(event), // start & end
          calendarId: "external",

          isExternal: true,
          canRegister: false,
          canRetract: false,
          isOrganizer: false,
          short: {
            title: event.name,
            location: event.embedded?.location?.shortName,
            people: event.organizers?.map(
              (organizer) => organizer.split(" ")[0],
            ),
          },
          full: {
            title: event.name,
            description: event.description,
            location: event.embedded?.location
              ? `${event.embedded.location.fullName} ${event.embedded.location.embedded?.building?.fullName}`.trim()
              : undefined,
            people: event.organizers,
          },
          startAt: event.startAt,
          endAt: event.endAt,
        }),
      );
    },
    scheduleEvents({ filters, search }: CalendarEventsState): ScheduleEvent[] {
      let events = [
        ...(this.internalScheduleEvents ?? []),
        ...(filters.external ? this.externalScheduleEvents ?? [] : []),
      ];
      if (search.trim().length > 0) {
        const search = this.search.toLowerCase();
        events = events.filter(
          (event) =>
            event.title.toLowerCase().includes(search) ||
            (event.full.description &&
              event.full.description.toLowerCase().includes(search)) ||
            (event.short.location &&
              event.short.location.toLowerCase().includes(search)) ||
            (event.full.location &&
              event.full.location.toLowerCase().includes(search)) ||
            (event.full.people &&
              event.full.people.some((person) =>
                person.toLowerCase().includes(search),
              )),
        );
      }

      return events;
    },
  },
  actions: {
    async register(
      targetEvent: ScheduleEvent,
      ignoreWarning = false,
    ): Promise<boolean | ScheduleEvent[]> {
      const toast = useToast();
      if (targetEvent.isExternal || !targetEvent.canRegister) {
        toast.error(
          i18n.global.t(
            "calendar-event.registration.registration-unavailable",
            {
              title: targetEvent.title,
            },
          ),
        );
        return;
      }
      const calendarEvent = this.events?.find(
        ({ id }) => id === targetEvent.id,
      );
      if (!calendarEvent) {
        toast.error(
          i18n.global.t(
            "calendar-event.registration.registration-unavailable",
            {
              title: targetEvent.title,
            },
          ),
        );
        return;
      }
      const intersectionPolicy =
        calendarEvent.flow.intersectionPolicy ??
        this.elective?.flow.event.intersectionPolicy;
      if (
        !ignoreWarning &&
        intersectionPolicy !== CalendarEventFlowIntersectionPolicyEnum.Allow
      ) {
        const conflicts = this.scheduleEvents.filter(
          (event) =>
            (event.isExternal || event.status === "registered") &&
            event.startAt < targetEvent.endAt &&
            event.endAt > targetEvent.startAt,
        );
        if (
          conflicts.length > 0 &&
          intersectionPolicy === CalendarEventFlowIntersectionPolicyEnum.Ask
        ) {
          this.registering = {
            targetEvent,
            conflicts,
          };

          return;
        }
      }

      try {
        await this.$api.calendarEvent.postCalendarEventRegistration({
          calendarEventRegistration: {
            calendarEventId: targetEvent.id,
            autoRegister: true,
          },
        });
        toast.success(
          i18n.global.t("calendar-event.registration.registration-success", {
            title: targetEvent.title,
          }),
        );
      } catch (e) {
        if (e instanceof ResponseError) {
          const { errors } = await e.response.json();
          toast.error(
            i18n.global.t(
              "calendar-event.registration.registration-error-with-details",
              {
                title: targetEvent.title,
                details: Object.values(errors)
                  .map((error) => error.join("\n"))
                  .join("\n"),
              },
            ),
          );
        } else {
          toast.error(
            i18n.global.t("calendar-event.registration.registration-error", {
              title: targetEvent.title,
            }),
          );
        }
      } finally {
        this.cancelRegistering();
        await this.load(true);
      }
    },
    async retract(targetEvent: ScheduleEvent) {
      const toast = useToast();
      if (
        targetEvent.isExternal ||
        !targetEvent.canRetract ||
        !targetEvent.registrationId
      ) {
        toast.error(
          i18n.global.t("calendar-event.registration.retraction-unavailable", {
            title: targetEvent.title,
          }),
        );
        return;
      }

      try {
        await this.$api.calendarEvent.deleteCalendarEventRegistration({
          id: targetEvent.registrationId,
        });
        toast.success(
          i18n.global.t("calendar-event.registration.retraction-success", {
            title: targetEvent.title,
          }),
        );
      } catch (e) {
        toast.error(
          i18n.global.t("calendar-event.registration.retraction-error", {
            title: targetEvent.title,
          }),
        );
      } finally {
        await this.load(true);
      }
    },
    async confirmRegistration() {
      if (!this.registering) {
        return;
      }
      await this.register(this.registering.targetEvent, true);
    },
    cancelRegistering() {
      this.registering = null;
    },
    async load(noLoading = false) {
      if (this.slug === null || this.range === null) {
        return;
      }
      if (!noLoading) {
        this.isLoading = true;
      }
      try {
        const [internalPromise, externalPromise] = await Promise.allSettled([
          this.$api.calendarEvent.getCalendarEventCollection({
            filter: `electiveId:${this.elective.id};startAt:..${formatRFC3339(this.range.end)};endAt:${formatRFC3339(this.range.start)}..`,
            expand: "location.building,organizers,registration",
            limit: 1000,
          }),
          this.$api.calendarEvent.getExternalEventCollection({
            from: this.range.start,
            to: this.range.end,
            expand: "location.building",
          }),
        ]);
        if (internalPromise.status === "fulfilled") {
          this.events = internalPromise.value;
        } else {
          console.error(internalPromise.reason);
          useToast().error("Не удалось загрузить занятие");
          await router.replace({
            name: "elective:read",
            params: { slug: this.slug },
          });
        }
        if (externalPromise.status === "fulfilled") {
          this.externalEvents = externalPromise.value;
        } else {
          console.error(externalPromise.reason);
          useToast().error("Не удалось загрузить занятия из расписания");
        }
      } finally {
        if (!noLoading) {
          this.isLoading = false;
        }
      }
    },
    async setElectiveAndRange(
      newElective: CalendarElective,
      newRange: ProvidedRange,
      noLoading: boolean,
    ) {
      this.elective = newElective;
      this.range = {
        start: new Date(newRange.start),
        end: new Date(newRange.end),
      };
      await this.load(noLoading);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCalendarEvents, import.meta.hot));
}
