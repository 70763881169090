import { acceptHMRUpdate, defineStore } from "pinia";
import {
  JobResponseTypeEnum,
  ResponseError,
  OptionCohortSettings,
} from "@/iot";
import router from "@/router";
import { useJob } from "@/stores";
import { Filter } from "@/stores/common";

type CreateElectiveFromPlansForm = {
  dryRun: boolean;
  periodOfStudy: string;
  academicTerm: number | null;
  planFilters: Array<Filter>;
  planSection: string;
  name: string;
  cohort: OptionCohortSettings;
  partialIntersectionAllowed: boolean;
};

const emptyForm = (): CreateElectiveFromPlansForm =>
  ({
    dryRun: true,
    periodOfStudy: "2023/2024",
    academicTerm: 1,
    planFilters: [{}] as Array<Filter>,
    planSection: "",
    name: "",
    cohort: {
      name: "Уч.гр. {{short_name}}/б-{{y_start}}-{{number}}-о",
      minSize: 15,
      maxSize: 30,
      maxCount: 2,
    },
    partialIntersectionAllowed: false,
  }) as CreateElectiveFromPlansForm;

export type CreateElectiveFromPlansJobState = {
  isLoading: boolean;
  isSaving: boolean;
  isFormShown: boolean;
  form: CreateElectiveFromPlansForm | null;
  saveErrors: { message: string; errors: object } | null;
};

export const useCreateElectiveFromPlansJob = defineStore({
  id: "createElectiveFromPlansJob",
  state: () =>
    ({
      isLoading: false,
      isSaving: false,
      isFormShown: false,
      form: null,
      saveErrors: null,
    }) as CreateElectiveFromPlansJobState,
  getters: {
    planFilterStrings({ form }): Array<string> {
      return (
        form?.planFilters?.map((filterObject) =>
          Object.keys(filterObject)
            .map((field) => `${field}:${filterObject[field].join(",")}`)
            .join(";"),
        ) ?? []
      );
    },
  },
  actions: {
    openForm() {
      this.isFormShown = true;
      this.form = emptyForm();
    },
    async saveForm() {
      if (!this.form) {
        this.closeForm();
        return;
      }
      this.isSaving = true;
      try {
        const job = await this.$api.job.postJob({
          jobRequest: {
            ...this.form,
            planFilters: this.planFilterStrings,
            type: JobResponseTypeEnum.CreateElectiveFromPlans,
          },
        });
        this.closeForm();
        if (job.id) {
          const jobStore = useJob();
          jobStore.id = job.id;
          jobStore.job = job;
          this.closeForm();
          await router.replace({
            name: "job:read",
            params: { id: job.id },
          });
        }
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
        }
      } finally {
        this.isSaving = false;
      }
    },
    closeForm() {
      this.isFormShown = false;
      this.form = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCreateElectiveFromPlansJob, import.meta.hot),
  );
}
