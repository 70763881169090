<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.84 17.6667H7.50664C5.8277 17.6667 4.46664 19.0277 4.46664 20.7067V26.04C4.4631 26.8486 4.78182 27.6252 5.35231 28.1982C5.92281 28.7712 6.69807 29.0933 7.50664 29.0933H12.84C14.5263 29.0933 15.8933 27.7263 15.8933 26.04V20.7067C15.886 19.0256 14.5211 17.6667 12.84 17.6667ZM13.8933 25.9867C13.8933 26.5684 13.4217 27.04 12.84 27.04H7.50665C7.2285 27.04 6.96193 26.9286 6.76651 26.7307C6.57108 26.5328 6.46308 26.2648 6.46665 25.9867V20.6533C6.46665 20.079 6.93227 19.6133 7.50665 19.6133H12.84C13.4166 19.6133 13.886 20.0768 13.8933 20.6533V25.9867ZM10.1963 26.007C10.1615 26.0559 10.1217 26.1023 10.0767 26.1453C9.6935 26.5122 9.08542 26.4989 8.71854 26.1157L7.26211 24.5944C6.89523 24.2112 6.90848 23.6031 7.29171 23.2362C7.67493 22.8693 8.28301 22.8826 8.64989 23.2658L9.37094 24.019L11.3711 22.1112C11.7707 21.73 12.4104 21.752 12.7916 22.1516C13.1728 22.5512 13.1646 23.1913 12.7649 23.5725L10.3118 25.9123C10.2752 25.9473 10.2365 25.9788 10.1963 26.007Z"
      class="hover"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8533 3.22667L27.1867 12.56C27.3915 12.7634 27.4984 13.0453 27.48 13.3333V22.6667C27.48 26.1593 24.6526 28.9926 21.16 29H17.3333C16.7811 29 16.3333 28.5523 16.3333 28C16.3333 27.4477 16.7811 27 17.3333 27H21.1467C23.5369 26.9927 25.4727 25.0569 25.48 22.6667V14.3333H19.8133C17.7883 14.3333 16.1467 12.6917 16.1467 10.6667V4.96H13.1467C11.9915 4.96352 10.8853 5.42669 10.0723 6.24724C9.25924 7.06779 8.80625 8.17822 8.81334 9.33333V16C8.81334 16.5523 8.36562 17 7.81334 17C7.26105 17 6.81334 16.5523 6.81334 16V9.33333C6.79554 7.64211 7.45493 6.01402 8.64459 4.81183C9.83426 3.60964 11.4554 2.93324 13.1467 2.93333H17.1467C17.4118 2.93357 17.666 3.03908 17.8533 3.22667ZM18.1467 6.34667V10.6667C18.1684 11.5715 18.9083 12.2936 19.8133 12.2933H24.1067L18.1467 6.34667Z"
      class="hover"
    ></path>
    <path
      d="M 17.989 12.098 L 24.149 12.098 C 24.478 12.125 24.786 11.933 24.906 11.625 C 25.026 11.317 24.93 10.967 24.669 10.764 L 17.269 3.498 C 17.066 3.237 16.716 3.141 16.409 3.261 C 16.101 3.381 15.909 3.689 15.936 4.018 L 15.936 10.111 C 15.936 10.65 16.154 11.165 16.541 11.54 C 16.928 11.914 17.451 12.116 17.989 12.098 Z"
      class="normal"
    ></path>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 17.856 13.564 L 24.229 13.564 C 24.597 13.564 24.896 13.863 24.896 14.231 L 24.896 23.458 C 24.896 26.403 22.508 28.791 19.563 28.791 L 10.376 28.791 C 7.43 28.791 5.043 26.403 5.043 23.458 L 5.043 8.578 C 5.043 5.632 7.43 3.244 10.376 3.244 L 13.936 3.244 C 14.301 3.251 14.596 3.546 14.603 3.911 L 14.603 10.284 C 14.599 11.151 14.94 11.983 15.55 12.598 C 16.16 13.213 16.99 13.561 17.856 13.564 Z M 10.292 25.918 C 10.258 25.967 10.218 26.013 10.173 26.056 C 9.79 26.423 9.181 26.41 8.815 26.027 L 7.358 24.505 C 6.991 24.122 7.004 23.514 7.388 23.147 C 7.771 22.78 8.379 22.794 8.746 23.177 L 9.467 23.93 L 11.467 22.022 C 11.867 21.641 12.506 21.663 12.888 22.063 C 13.269 22.462 13.261 23.102 12.861 23.484 L 10.408 25.823 C 10.371 25.858 10.333 25.89 10.292 25.918 Z"
      class="normal"
    ></path>
  </svg>
</template>

<style scoped>
svg {
  border-radius: 8px;
}

.normal {
  fill: var(--c-white);
}

.hover {
  fill: none;
}

.active > svg,
:hover > svg {
  background-color: var(--c-white);
}

.active > svg > .normal,
:hover > svg > .normal {
  fill: none;
}

.active > svg > .hover,
:hover > svg > .hover {
  fill: var(--c-blue);
}

.btn__inline svg {
  background-color: transparent;
}

.btn__inline .normal {
  fill: none;
}

.btn__inline .hover {
  fill: var(--c-gray-4);
}

@media only screen and (max-width: 991.98px) {
  .normal {
    fill: var(--c-gray-4);
  }

  .hover {
    fill: none;
  }

  .active > svg,
  :hover > svg {
    background-color: transparent;
  }

  svg > .normal,
  svg > .normal {
    fill: none;
  }

  svg > .hover,
  svg > .hover {
    fill: var(--c-gray-4);
  }

  .active > svg > .hover,
  :hover > svg > .hover {
    fill: var(--c-blue);
  }
}
</style>
