/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChoiceOption
 */
export interface ChoiceOption {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChoiceOption
     */
    optionId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChoiceOption
     */
    subOptionIds?: Array<string>;
}

/**
 * Check if a given object implements the ChoiceOption interface.
 */
export function instanceOfChoiceOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "optionId" in value;

    return isInstance;
}

export function ChoiceOptionFromJSON(json: any): ChoiceOption {
    return ChoiceOptionFromJSONTyped(json, false);
}

export function ChoiceOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChoiceOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionId': json['optionId'],
        'subOptionIds': !exists(json, 'subOptionIds') ? undefined : json['subOptionIds'],
    };
}

export function ChoiceOptionToJSON(value?: ChoiceOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionId': value.optionId,
        'subOptionIds': value.subOptionIds,
    };
}

