/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarEventFlow
 */
export interface CalendarEventFlow {
    /**
     * 
     * @type {any}
     * @memberof CalendarEventFlow
     */
    retractInterval?: any | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEventFlow
     */
    intersectionPolicy?: CalendarEventFlowIntersectionPolicyEnum;
}


/**
 * @export
 */
export const CalendarEventFlowIntersectionPolicyEnum = {
    Allow: 'allow',
    Deny: 'deny',
    Ask: 'ask'
} as const;
export type CalendarEventFlowIntersectionPolicyEnum = typeof CalendarEventFlowIntersectionPolicyEnum[keyof typeof CalendarEventFlowIntersectionPolicyEnum];


/**
 * Check if a given object implements the CalendarEventFlow interface.
 */
export function instanceOfCalendarEventFlow(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventFlowFromJSON(json: any): CalendarEventFlow {
    return CalendarEventFlowFromJSONTyped(json, false);
}

export function CalendarEventFlowFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventFlow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'retractInterval': !exists(json, 'retractInterval') ? undefined : json['retractInterval'],
        'intersectionPolicy': !exists(json, 'intersectionPolicy') ? undefined : json['intersectionPolicy'],
    };
}

export function CalendarEventFlowToJSON(value?: CalendarEventFlow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'retractInterval': value.retractInterval,
        'intersectionPolicy': value.intersectionPolicy,
    };
}

