/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Elective } from './Elective';
import {
    ElectiveFromJSON,
    ElectiveFromJSONTyped,
    ElectiveToJSON,
} from './Elective';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface ConsolidationEmbedded
 */
export interface ConsolidationEmbedded {
    /**
     * 
     * @type {User}
     * @memberof ConsolidationEmbedded
     */
    author?: User;
    /**
     * 
     * @type {Elective}
     * @memberof ConsolidationEmbedded
     */
    elective?: Elective;
    /**
     * 
     * @type {Array<Option>}
     * @memberof ConsolidationEmbedded
     */
    options?: Array<Option>;
}

/**
 * Check if a given object implements the ConsolidationEmbedded interface.
 */
export function instanceOfConsolidationEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsolidationEmbeddedFromJSON(json: any): ConsolidationEmbedded {
    return ConsolidationEmbeddedFromJSONTyped(json, false);
}

export function ConsolidationEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsolidationEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'author': !exists(json, 'author') ? undefined : UserFromJSON(json['author']),
        'elective': !exists(json, 'elective') ? undefined : ElectiveFromJSON(json['elective']),
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(OptionFromJSON)),
    };
}

export function ConsolidationEmbeddedToJSON(value?: ConsolidationEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'author': UserToJSON(value.author),
        'elective': ElectiveToJSON(value.elective),
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(OptionToJSON)),
    };
}

