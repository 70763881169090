/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { JobResponseEmbedded } from './JobResponseEmbedded';
import {
    JobResponseEmbeddedFromJSON,
    JobResponseEmbeddedFromJSONTyped,
    JobResponseEmbeddedToJSON,
} from './JobResponseEmbedded';
import type { JobStep } from './JobStep';
import {
    JobStepFromJSON,
    JobStepFromJSONTyped,
    JobStepToJSON,
} from './JobStep';

/**
 * 
 * @export
 * @interface JobResponse
 */
export interface JobResponse {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof JobResponse
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof JobResponse
     */
    type?: JobResponseTypeEnum;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof JobResponse
     */
    userId?: string;
    /**
     * If the job should only return a preview or perform an action.
     * @type {boolean}
     * @memberof JobResponse
     */
    dryRun?: boolean;
    /**
     * Reuse pre-calculations from parent job if passed.
     * @type {string}
     * @memberof JobResponse
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof JobResponse
     */
    readonly status?: JobResponseStatusEnum;
    /**
     * 
     * @type {Array<JobStep>}
     * @memberof JobResponse
     */
    steps?: Array<JobStep>;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof JobResponse
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof JobResponse
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {JobResponseEmbedded}
     * @memberof JobResponse
     */
    embedded?: JobResponseEmbedded;
}


/**
 * @export
 */
export const JobResponseTypeEnum = {
    CreateElectiveFromDiscipline: 'create-elective-from-discipline',
    CreateElectiveFromPlans: 'create-elective-from-plans',
    CreateElectiveGroups: 'create-elective-groups',
    CreateElectiveLimitation: 'create-elective-limitation',
    DistributeCohortElectiveConsolidation: 'distribute-cohort-elective-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillElectiveConsolidation: 'fill-elective-consolidation',
    ProcessElectiveConsolidation: 'process-elective-consolidation',
    SyncElectiveConsolidationBack: 'sync-elective-consolidation-back'
} as const;
export type JobResponseTypeEnum = typeof JobResponseTypeEnum[keyof typeof JobResponseTypeEnum];

/**
 * @export
 */
export const JobResponseStatusEnum = {
    Queued: 'queued',
    Processing: 'processing',
    Failed: 'failed',
    Completed: 'completed'
} as const;
export type JobResponseStatusEnum = typeof JobResponseStatusEnum[keyof typeof JobResponseStatusEnum];


/**
 * Check if a given object implements the JobResponse interface.
 */
export function instanceOfJobResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function JobResponseFromJSON(json: any): JobResponse {
    return JobResponseFromJSONTyped(json, false);
}

export function JobResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'dryRun': !exists(json, 'dryRun') ? undefined : json['dryRun'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'steps': !exists(json, 'steps') ? undefined : ((json['steps'] as Array<any>).map(JobStepFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : JobResponseEmbeddedFromJSON(json['_embedded']),
    };
}

export function JobResponseToJSON(value?: JobResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'userId': value.userId,
        'dryRun': value.dryRun,
        'parentId': value.parentId,
        'steps': value.steps === undefined ? undefined : ((value.steps as Array<any>).map(JobStepToJSON)),
        '_embedded': JobResponseEmbeddedToJSON(value.embedded),
    };
}

