/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    EducationalPlan,
    instanceOfEducationalPlan,
    EducationalPlanFromJSON,
    EducationalPlanFromJSONTyped,
    EducationalPlanToJSON,
} from './EducationalPlan';
import {
    WorkingPlan,
    instanceOfWorkingPlan,
    WorkingPlanFromJSON,
    WorkingPlanFromJSONTyped,
    WorkingPlanToJSON,
} from './WorkingPlan';

/**
 * @type Plan
 * 
 * @export
 */
export type Plan = { type: 'educational' } & EducationalPlan | { type: 'working' } & WorkingPlan;

export function PlanFromJSON(json: any): Plan {
    return PlanFromJSONTyped(json, false);
}

export function PlanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Plan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'educational':
            return {...EducationalPlanFromJSONTyped(json, true), type: 'educational'};
        case 'working':
            return {...WorkingPlanFromJSONTyped(json, true), type: 'working'};
        default:
            throw new Error(`No variant of Plan exists with 'type=${json['type']}'`);
    }
}

export function PlanToJSON(value?: Plan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'educational':
            return EducationalPlanToJSON(value);
        case 'working':
            return WorkingPlanToJSON(value);
        default:
            throw new Error(`No variant of Plan exists with 'type=${value['type']}'`);
    }

}

