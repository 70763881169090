import { acceptHMRUpdate, defineStore } from "pinia";
import { StoredFile } from "@/iot";

export const useFile = defineStore({
  id: "file",
  state: () => ({}),
  actions: {
    async download(file: StoredFile | string) {
      if (typeof file === "string") {
        file = await this.$api.file.getFile({ id: file });
      }
      const fileBlob = await this.$api.file.getFileDownload({
        id: file.id as string,
        extension: file.extension,
      });
      this.downloadBlob(`${file.name}.${file.extension}`, fileBlob);
    },
    downloadBlob(name: string, content: Blob) {
      const a = document.createElement("a");
      a.style.display = "none";
      a.download = name;
      const url = window.URL.createObjectURL(content);
      a.href = url;
      document.body.appendChild(a);
      setTimeout(() => a.click(), 1);
      setTimeout(() => window.URL.revokeObjectURL(url), 100);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useFile, import.meta.hot));
}
