import { acceptHMRUpdate, defineStore } from "pinia";
import {
  Consolidation,
  ConsolidationRow,
  ConsolidationStatusEnum,
  ConsolidationUserOptionSourceEnum,
  Option,
  ResponseError,
  Specialty,
} from "@/iot";
import { format } from "@/utils/date";
import { userFullName } from "@/stores/user";
import { useElective } from "@/stores/elective";
import { cloneDeep } from "lodash";
import { useToast } from "vue-toastification";
import {
  abbreviateName,
  ChoicesMap,
  OptionsMap,
  SpecialtiesMap,
} from "@/stores/common";
import { useFile } from "@/stores/file";

const toast = useToast();

type ConsolidationTableRow = {
  key: string;
  value: {
    id: string;
    name: string;
    email: string;
    departments: string[];
    specialties: string[];
    userCohorts: string[];
    rating: number;
    primaryOptions: Option[];
    backupOptions: Option[];
    slot: number;
    optionId: string | null;
    option: Option | null;
    cohort: string | null;
    source: ConsolidationUserOptionSourceEnum | null;
    isOptionUnderfilled: boolean;
    isOptionOverfilled: boolean;
    isCohortUnderfilled: boolean;
    isCohortOverfilled: boolean;
  };
};

export type ConsolidationState = {
  id: string | null;
  isLoading: boolean;
  isSaving: boolean;
  isExporting: boolean;
  isExportFormShown: boolean;
  consolidation: Consolidation | null;
  rows: Array<ConsolidationRow> | null;
  specialties: SpecialtiesMap;
  choices: ChoicesMap;
  filterName: string;
  filterDepartment: Array<string>;
  filterSpecialty: Array<string>;
  filterOption: Array<string>;
  filterCohort: Array<string>;
  exportTitle: string;
};

export const ConsolidationStatuses = {
  [ConsolidationStatusEnum.Draft]: "Черновик",
  [ConsolidationStatusEnum.Processing]: "Обрабатывается",
  [ConsolidationStatusEnum.Finalized]: "Проведено",
  [ConsolidationStatusEnum.Obsolete]: "Устаревшее",
};

export const consolidationStatus = (consolidation: Consolidation | null) =>
  consolidation?.status ? ConsolidationStatuses[consolidation.status] : "";

export const consolidationTitle = (
  consolidation: Consolidation | null,
): string =>
  consolidation?.createdAt
    ? `Закрепление ${userFullName(consolidation.embedded?.author)} от
        ${format(consolidation.createdAt, "HH:mm dd.MM.uuuu")}`
    : "";

function buildEnhancedFilter(
  filters: Array<string>,
  optionsKey: "optionId" | "cohort",
): (row: ConsolidationTableRow) => boolean {
  if (!filters.length) {
    return () => true;
  }
  const callbacks: Array<(row: ConsolidationTableRow) => boolean> = [];
  if (filters.includes("!empty")) {
    callbacks.push(
      (row: ConsolidationTableRow) => !!row.value[optionsKey]?.length,
    );
  }
  if (filters.length > 0) {
    callbacks.push((row: ConsolidationTableRow) =>
      filters.includes(row.value[optionsKey] || ""),
    );
  }
  return (row: ConsolidationTableRow) =>
    callbacks.reduce((result, filter) => result || filter(row), false);
}

export function transformConsolidationRowForExport(
  row: ConsolidationTableRow,
): Array<string | number> {
  const data = [
    row.value.name,
    row.value.email,
    row.value.departments?.join(", ") || "",
    row.value.specialties?.join(", ") || "",
    row.value.userCohorts.join(", "),
    `${row.value.rating}`,
    row.value.source || "",
    row.value.option?.fullName || "",
    row.value.cohort || "",
  ];
  row.value.primaryOptions.forEach((option) => data.push(option.fullName));
  row.value.backupOptions.forEach((option) => data.push(option.fullName));

  return data;
}

export const useConsolidation = defineStore({
  id: "consolidation",
  state: () =>
    ({
      id: null,
      isLoading: false,
      isSaving: false,
      isExporting: false,
      isExportFormShown: false,
      consolidation: null,
      rows: null,
      specialties: {},
      choices: {},
      filterName: "",
      filterDepartment: [],
      filterSpecialty: [],
      filterOption: [],
      filterCohort: [],
      exportTitle: "",
    }) as ConsolidationState,
  getters: {
    isEditable: ({ isSaving, consolidation }) =>
      !isSaving && consolidation?.status === ConsolidationStatusEnum.Draft,
    status: ({ consolidation }) => consolidationStatus(consolidation),
    title: ({ consolidation }) => consolidationTitle(consolidation),
    options: (): OptionsMap => {
      return (
        useElective().elective?.embedded?.options?.reduce(
          (map, option) => ({ ...map, [option.id as string]: option }),
          {} as OptionsMap,
        ) || {}
      );
    },
    dataTable({ rows }): Array<ConsolidationTableRow> {
      return (
        rows?.flatMap(
          (row): Array<ConsolidationTableRow> =>
            row.options.map((slot): ConsolidationTableRow => {
              const user = row.embedded?.user || null;
              const option =
                (slot.optionId && this.options[slot.optionId]) || null;
              const optionOccupation =
                slot.optionId && this.occupiedOptions[slot.optionId];
              const cohortOccupation =
                slot.cohort && this.occupiedCohorts[slot.cohort];
              return {
                key: `${row.userId}:${slot.slot}`,
                value: {
                  id: row.userId as string,
                  name: userFullName(user),
                  email: user?.email?.trim() || "",
                  departments: user?.departments || [],
                  specialties: user?.specialties || [],
                  userCohorts:
                    user?.embedded?.cohorts
                      ?.filter(
                        ({ type, name }) =>
                          ["academic", "manual", "unknown"].includes(
                            type ?? "",
                          ) && !name?.includes("Уч.гр."),
                      )
                      ?.map(({ name }) => name || "") || [],
                  rating: user?.rating ?? 0,
                  primaryOptions:
                    this.choices[row.userId as string]?.primaryOptions?.map(
                      (optionId) => this.options[optionId],
                    ) || [],
                  backupOptions:
                    this.choices[row.userId as string]?.backupOptions?.map(
                      (optionId) => this.options[optionId],
                    ) || [],
                  slot: slot.slot,
                  optionId: slot.optionId,
                  option:
                    (slot.optionId && this.options[slot.optionId]) || null,
                  cohort: slot.cohort || null,
                  source: slot.source || null,
                  isOptionUnderfilled:
                    option?.cohort?.minSize && optionOccupation
                      ? option.cohort.minSize > optionOccupation
                      : false,
                  isOptionOverfilled:
                    option?.cohort?.maxCount &&
                    option?.cohort?.maxSize &&
                    optionOccupation
                      ? option.cohort.maxCount * option.cohort.maxSize <
                        optionOccupation
                      : false,
                  isCohortUnderfilled:
                    option?.cohort?.minSize && cohortOccupation
                      ? option.cohort.minSize > cohortOccupation
                      : false,
                  isCohortOverfilled:
                    option?.cohort?.maxCount &&
                    option?.cohort?.maxSize &&
                    cohortOccupation
                      ? option.cohort.maxSize < cohortOccupation
                      : false,
                },
              };
            }),
        ) || []
      ).sort((a, b) => a.value.name.localeCompare(b.value.name));
    },
    exportName: () => {
      return useElective().elective?.name;
    },
    exportHeader: () => {
      const header = [
        "ФИО",
        "Email",
        "Институт",
        "Направление",
        "Группа",
        "Рейтинг",
        "Распределение",
        "Дисциплина",
        "Группа",
      ];
      const elective = useElective().elective;
      if (elective?.flow) {
        for (let i = 1; i <= elective.flow.primaryOptions; i++) {
          header.push(
            `Основной выбор ${elective.flow.primaryOptions > 1 ? i : ""}`.trim(),
          );
        }
        for (let i = 1; i <= (elective.flow.backupOptions || 0); i++) {
          header.push(
            `Запасной выбор ${
              (elective.flow.backupOptions || 0) > 1 ? i : ""
            }`.trim(),
          );
        }
      }

      return header;
    },
    filter: ({
      filterName,
      filterDepartment,
      filterSpecialty,
      filterOption,
      filterCohort,
    }) => {
      const filters: Array<(row: ConsolidationTableRow) => boolean> = [];
      if (filterName.trim() !== "") {
        filters.push(
          (row: ConsolidationTableRow) =>
            !row.value.name ||
            row.value.name
              .toLowerCase()
              .includes(filterName.toLowerCase().trim()),
        );
      }
      if (filterDepartment.length) {
        filters.push(
          (row: ConsolidationTableRow) =>
            !row.value.name ||
            (filterDepartment.includes("") &&
              (row.value.departments || []).length === 0) ||
            (row.value.departments || []).some((department) =>
              filterDepartment.includes(department),
            ),
        );
      }
      if (filterSpecialty.length) {
        filters.push(
          (row: ConsolidationTableRow) =>
            !row.value.name ||
            (filterSpecialty.includes("") &&
              (row.value.specialties || []).length === 0) ||
            (row.value.specialties || []).some((specialty) =>
              filterSpecialty.includes(specialty),
            ),
        );
      }
      filters.push(buildEnhancedFilter(filterOption, "optionId"));
      filters.push(buildEnhancedFilter(filterCohort, "cohort"));

      return (row: ConsolidationTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },
    occupiedOptions({ rows }): { [optionId: string]: number } {
      const occupied: { [optionId: string]: number } = {};
      Object.keys(this.options || {}).forEach((optionId) => {
        occupied[optionId] = 0;
      });
      rows?.forEach((row) =>
        row.options.forEach((slot) => {
          if (!slot.optionId) {
            return;
          }
          occupied[slot.optionId]++;
        }),
      );
      return occupied;
    },
    occupiedCohorts({ rows }): { [cohort: string]: number } {
      const occupied: { [cohort: string]: number } = {};
      Object.values(this.options || {}).forEach((option) =>
        option.cohort?.availableNames?.forEach((name) => (occupied[name] = 0)),
      );
      rows?.forEach((row) =>
        row.options.forEach((slot) => {
          if (!slot.cohort) {
            return;
          }
          occupied[slot.cohort]++;
        }),
      );
      return occupied;
    },
    availableOptions(): Array<{ k: string; v: string }> {
      return (
        Object.values(this.options).map((option): { k: string; v: string } => ({
          k: option.id as string,
          v:
            option.cohort?.maxSize && option.cohort?.maxCount
              ? `${option.fullName} (${
                  this.occupiedOptions[option.id as string] || 0
                }/${option.cohort.maxSize * option.cohort.maxCount})`
              : option.fullName,
        })) || []
      );
    },
    filterableOptions(): Array<{ k: string; v: string }> {
      return [
        { k: "", v: "Нет дисциплины" },
        { k: "!empty", v: "Любая дисциплина" },
        ...this.availableOptions,
      ];
    },
    availableCohorts(): Array<{ k: string; v: string }> {
      return Object.values(this.availableOptionCohorts).flat();
    },
    availableOptionCohorts(): {
      [optionId: string]: Array<{ k: string; v: string }>;
    } {
      return (
        Object.values(this.options).reduce(
          (
            map: {
              [optionId: string]: Array<{ k: string; v: string }>;
            },
            option: Option,
          ) => ({
            ...map,
            [option.id as string]:
              option.cohort.availableNames?.map((name: string) => ({
                k: name,
                v: option.cohort?.maxSize
                  ? `${name} (${this.occupiedCohorts[name] || 0}/${
                      option.cohort.maxSize
                    })`
                  : name,
              })) || [],
          }),
          {},
        ) || {}
      );
    },
    filterableCohorts(): Array<{ k: string; v: string }> {
      return [
        { k: "", v: "Нет группы" },
        { k: "!empty", v: "Любая группа" },
        ...this.availableCohorts,
      ];
    },
    availableDepartments({ rows }): Array<{ k: string; v: string }> {
      return Object.values(
        rows
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.departments || [])
          ?.map((name) => ({ k: name, v: abbreviateName(name) }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableDepartments(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableDepartments];
    },
    availableSpecialties({
      rows,
      specialties,
    }): Array<{ k: string; v: string }> {
      return Object.values(
        rows
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.specialties || [])
          ?.map((code) => ({
            k: code,
            v: `${code} ${specialties[code]?.name || ""}`.trim(),
          }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableSpecialties(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableSpecialties];
    },
  },
  actions: {
    async setId(newId: string) {
      if (newId !== this.id) {
        this.id = newId;
        await this.load();
      }
    },
    async load() {
      if (!this.id) {
        return;
      }
      this.isLoading = true;
      try {
        [this.consolidation, this.rows] = await Promise.all([
          this.$api.consolidation.getConsolidation({
            id: this.id,
            expand: "author",
          }),
          await this.$api.consolidation.getConsolidationRowCollection({
            id: this.id,
            expand: "user.cohorts",
          }),
        ]);
        this.loadSpecialties();
        this.loadChoices();
      } finally {
        this.isLoading = false;
      }
    },
    loadSpecialties() {
      if (!this.rows) {
        return;
      }
      const specialtyCodes = Object.keys(
        this.rows
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.specialties || [])
          ?.reduce((map, el) => ({ ...map, [el]: el }), {}) || {},
      );
      this.$api.integration
        .getIntegrationSpecialtyCollection({
          filter: `code:${specialtyCodes.join(",")}`,
          limit: 1000,
        })
        .then((specialties) => {
          this.specialties = specialties.reduce(
            (map, specialty) => ({
              ...map,
              [specialty.code as string]: specialty,
            }),
            {} as { [code: string]: Specialty },
          );
        });
    },
    loadChoices() {
      if (!this.consolidation?.electiveId) {
        return;
      }
      this.$api.report
        .getElectiveChoicesReport({
          electiveId: this.consolidation.electiveId as string,
          limit: 10000,
        })
        .then(
          (report) =>
            (this.choices = report.reduce(
              (map, { userId, primaryOptions, backupOptions }) =>
                userId && primaryOptions && backupOptions
                  ? {
                      ...map,
                      [userId]: {
                        primaryOptions: primaryOptions,
                        backupOptions: backupOptions,
                      },
                    }
                  : map,
              {} as ChoicesMap,
            )),
        );
    },
    async save() {
      if (!this.id || !this.consolidation?.revision || !this.rows) {
        return;
      }
      this.isSaving = true;
      try {
        this.consolidation = await this.$api.consolidation.patchConsolidation({
          id: this.id,
          patchConsolidationRequest: {
            revision: this.consolidation.revision,
            users: this.rows,
          },
          expand: "author",
        });
        this.rows = await this.$api.consolidation.getConsolidationRowCollection(
          {
            id: this.id,
            expand: "user.cohorts",
          },
        );
      } catch (e) {
        if (e instanceof ResponseError) {
          const saveErrors = await e.response.json();
          toast.error(saveErrors.message);
        } else {
          toast.error("Не удалось сохранить закрепление");
        }
      } finally {
        this.isSaving = false;
      }
    },
    updateOption(key: string, optionId: string | null, selected: Set<string>) {
      if (!this.rows) {
        return;
      }
      const rows = cloneDeep(this.rows);
      const keys = selected.size ? selected : new Set([key]);
      keys.forEach((key) => {
        const [userId, slotString] = key.split(":");
        const slot = parseInt(slotString);
        const userIndex = rows.findIndex((row) => row.userId === userId);
        if (userIndex === -1) {
          return;
        }
        const slotIndex = rows[userIndex].options.findIndex(
          (row) => row.slot === slot,
        );
        if (
          slotIndex === -1 ||
          rows[userIndex].options[slotIndex].optionId === optionId
        ) {
          return;
        }
        rows[userIndex].options[slotIndex] = {
          ...rows[userIndex].options[slotIndex],
          optionId,
          cohort: null,
        };
      });
      this.rows = rows;
      return this.save();
    },
    updateCohort(key: string, cohort: string | null, selected: Set<string>) {
      if (!this.rows) {
        return;
      }
      const rows = cloneDeep(this.rows);
      const keys = selected.size ? selected : new Set([key]);
      let hasErrors = false;
      let optionId: string | null = null;
      keys.forEach((key) => {
        const [userId, slotString] = key.split(":");
        const slot = parseInt(slotString);
        const userIndex = rows.findIndex((row) => row.userId === userId);
        if (userIndex === -1) {
          return;
        }
        const slotIndex = rows[userIndex].options.findIndex(
          (row) => row.slot === slot,
        );
        if (slotIndex === -1) {
          return;
        }
        if (optionId === null) {
          optionId = rows[userIndex].options[slotIndex].optionId;
        }
        if (
          !optionId ||
          rows[userIndex].options[slotIndex].optionId !== optionId ||
          (cohort &&
            !this.options[optionId]?.cohort?.availableNames?.includes(cohort))
        ) {
          hasErrors = true;
        }
        rows[userIndex].options[slotIndex] = {
          ...rows[userIndex].options[slotIndex],
          cohort,
        };
      });
      if (hasErrors) {
        toast.error(
          "Массовое задание групп доступно только в пределах одной дисциплины",
        );
        return;
      }
      this.rows = rows;
      return this.save();
    },
    openExportForm() {
      this.isExportFormShown = true;
    },
    closeExportForm() {
      this.isExportFormShown = false;
      this.exportTitle = "";
    },
    async runExport() {
      if (!this.id) {
        return;
      }
      this.isExporting = true;
      try {
        const file = await this.$api.consolidation.postConsolidationExport({
          id: this.id,
          consolidationExport: { title: this.exportTitle },
        });
        await useFile().download(file);
        this.closeExportForm();
      } catch (e) {
        if (e instanceof ResponseError) {
          const saveErrors = await e.response.json();
          toast.error(saveErrors.message);
        } else {
          toast.error("Не удалось скачать приказ");
        }
      } finally {
        this.isExporting = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConsolidation, import.meta.hot));
}
