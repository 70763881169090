import { acceptHMRUpdate, defineStore } from "pinia";
import { JobResponseTypeEnum, ResponseError } from "@/iot";
import router from "@/router";
import { useJob } from "@/stores";

type CreateElectiveLimitationForm = {
  dryRun: boolean;
  electiveId: string;
};

const emptyForm = (): CreateElectiveLimitationForm =>
  ({
    dryRun: true,
    electiveId: "",
  }) as CreateElectiveLimitationForm;

export type CreateElectiveLimitationJobState = {
  isSaving: boolean;
  isFormShown: boolean;
  form: CreateElectiveLimitationForm | null;
  saveErrors: { message: string; errors: object } | null;
};

export const useCreateElectiveLimitationJob = defineStore({
  id: "createElectiveLimitationJob",
  state: () =>
    ({
      isSaving: false,
      isFormShown: false,
      form: null,
      saveErrors: null,
    }) as CreateElectiveLimitationJobState,
  actions: {
    openForm(electiveId: string) {
      this.isFormShown = true;
      this.form = emptyForm();
      this.form.electiveId = electiveId;
    },
    async saveForm() {
      if (this.form === null) {
        this.closeForm();
        return;
      }
      this.isSaving = true;
      try {
        const job = await this.$api.job.postJob({
          jobRequest: {
            ...this.form,
            type: JobResponseTypeEnum.CreateElectiveLimitation,
          },
          expand: "artifacts",
        });
        this.closeForm();
        if (job.id) {
          const jobStore = useJob();
          jobStore.id = job.id;
          jobStore.job = job;
          await router.replace({
            name: "job:read",
            params: { id: job.id },
          });
        }
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
        }
      } finally {
        this.isSaving = false;
      }
    },
    closeForm() {
      this.isFormShown = false;
      this.form = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useCreateElectiveLimitationJob, import.meta.hot),
  );
}
