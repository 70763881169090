/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarEventEmbedded } from './CalendarEventEmbedded';
import {
    CalendarEventEmbeddedFromJSON,
    CalendarEventEmbeddedFromJSONTyped,
    CalendarEventEmbeddedToJSON,
} from './CalendarEventEmbedded';
import type { CalendarEventFlow } from './CalendarEventFlow';
import {
    CalendarEventFlowFromJSON,
    CalendarEventFlowFromJSONTyped,
    CalendarEventFlowToJSON,
} from './CalendarEventFlow';

/**
 * 
 * @export
 * @interface CalendarEvent
 */
export interface CalendarEvent {
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    readonly id?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEvent
     */
    electiveId?: string | null;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEvent
     */
    optionId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    name: string;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    startAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CalendarEvent
     */
    endAt: Date;
    /**
     * Time event registration opens.
     * If `null`, elective registration setting is used or is open since event publish time.
     * @type {Date}
     * @memberof CalendarEvent
     */
    openedAt?: Date | null;
    /**
     * Time event registration closes.
     * If `null`, elective registration setting is used or is closed at the event start time.
     * @type {Date}
     * @memberof CalendarEvent
     */
    closedAt?: Date | null;
    /**
     * Maximum number of participants.
     * If 0, the event is not limited.
     * @type {number}
     * @memberof CalendarEvent
     */
    capacity: number;
    /**
     * Number of participants already registered.
     * If equal to `capacity`, the event is full (unless `capacity` is 0).
     * @type {number}
     * @memberof CalendarEvent
     */
    readonly occupied?: number;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CalendarEvent
     */
    locationId?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof CalendarEvent
     */
    organizerIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof CalendarEvent
     */
    status?: CalendarEventStatusEnum;
    /**
     * The reason for the event cancellation.
     * Required if `status` is `cancelled`.
     * @type {string}
     * @memberof CalendarEvent
     */
    cancellationReason?: string | null;
    /**
     * Indicates whether current user can register or not.
     * This takes timeframe, elective & option restrictions into an account.
     * @type {boolean}
     * @memberof CalendarEvent
     */
    readonly canRegister?: boolean;
    /**
     * 
     * @type {CalendarEventFlow}
     * @memberof CalendarEvent
     */
    flow: CalendarEventFlow;
    /**
     * 
     * @type {CalendarEventEmbedded}
     * @memberof CalendarEvent
     */
    embedded?: CalendarEventEmbedded;
}


/**
 * @export
 */
export const CalendarEventStatusEnum = {
    Active: 'active',
    Cancelled: 'cancelled'
} as const;
export type CalendarEventStatusEnum = typeof CalendarEventStatusEnum[keyof typeof CalendarEventStatusEnum];


/**
 * Check if a given object implements the CalendarEvent interface.
 */
export function instanceOfCalendarEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "startAt" in value;
    isInstance = isInstance && "endAt" in value;
    isInstance = isInstance && "capacity" in value;
    isInstance = isInstance && "flow" in value;

    return isInstance;
}

export function CalendarEventFromJSON(json: any): CalendarEvent {
    return CalendarEventFromJSONTyped(json, false);
}

export function CalendarEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'electiveId': !exists(json, 'electiveId') ? undefined : json['electiveId'],
        'optionId': !exists(json, 'optionId') ? undefined : json['optionId'],
        'name': json['name'],
        'startAt': (new Date(json['startAt'])),
        'endAt': (new Date(json['endAt'])),
        'openedAt': !exists(json, 'openedAt') ? undefined : (json['openedAt'] === null ? null : new Date(json['openedAt'])),
        'closedAt': !exists(json, 'closedAt') ? undefined : (json['closedAt'] === null ? null : new Date(json['closedAt'])),
        'capacity': json['capacity'],
        'occupied': !exists(json, 'occupied') ? undefined : json['occupied'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'organizerIds': !exists(json, 'organizerIds') ? undefined : json['organizerIds'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'cancellationReason': !exists(json, 'cancellationReason') ? undefined : json['cancellationReason'],
        'canRegister': !exists(json, 'canRegister') ? undefined : json['canRegister'],
        'flow': CalendarEventFlowFromJSON(json['flow']),
        'embedded': !exists(json, '_embedded') ? undefined : CalendarEventEmbeddedFromJSON(json['_embedded']),
    };
}

export function CalendarEventToJSON(value?: CalendarEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'optionId': value.optionId,
        'name': value.name,
        'startAt': (value.startAt.toISOString()),
        'endAt': (value.endAt.toISOString()),
        'openedAt': value.openedAt === undefined ? undefined : (value.openedAt === null ? null : value.openedAt.toISOString()),
        'closedAt': value.closedAt === undefined ? undefined : (value.closedAt === null ? null : value.closedAt.toISOString()),
        'capacity': value.capacity,
        'locationId': value.locationId,
        'organizerIds': value.organizerIds,
        'status': value.status,
        'cancellationReason': value.cancellationReason,
        'flow': CalendarEventFlowToJSON(value.flow),
        '_embedded': CalendarEventEmbeddedToJSON(value.embedded),
    };
}

