/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Discipline,
  Plan,
  Specialty,
} from '../models';
import {
    DisciplineFromJSON,
    DisciplineToJSON,
    PlanFromJSON,
    PlanToJSON,
    SpecialtyFromJSON,
    SpecialtyToJSON,
} from '../models';

export interface GetIntegrationDisciplineCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    sort?: Array<string>;
}

export interface GetIntegrationPlanRequest {
    id: string;
}

export interface GetIntegrationPlanCollectionRequest {
    filter?: string;
}

export interface GetIntegrationSpecialtyCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    sort?: Array<string>;
}

/**
 * 
 */
export class IntegrationApi extends runtime.BaseAPI {

    /**
     * Browse disciplines
     */
    async getIntegrationDisciplineCollectionRaw(requestParameters: GetIntegrationDisciplineCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Discipline>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/integration/disciplines`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DisciplineFromJSON));
    }

    /**
     * Browse disciplines
     */
    async getIntegrationDisciplineCollection(requestParameters: GetIntegrationDisciplineCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Discipline>> {
        const response = await this.getIntegrationDisciplineCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get integration plan
     */
    async getIntegrationPlanRaw(requestParameters: GetIntegrationPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Plan>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getIntegrationPlan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/integration/plans/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PlanFromJSON(jsonValue));
    }

    /**
     * Get integration plan
     */
    async getIntegrationPlan(requestParameters: GetIntegrationPlanRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Plan> {
        const response = await this.getIntegrationPlanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse integration plans
     */
    async getIntegrationPlanCollectionRaw(requestParameters: GetIntegrationPlanCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Plan>>> {
        const queryParameters: any = {};

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/integration/plans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PlanFromJSON));
    }

    /**
     * Browse integration plans
     */
    async getIntegrationPlanCollection(requestParameters: GetIntegrationPlanCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Plan>> {
        const response = await this.getIntegrationPlanCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Browse specialties
     */
    async getIntegrationSpecialtyCollectionRaw(requestParameters: GetIntegrationSpecialtyCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Specialty>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/integration/specialties`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SpecialtyFromJSON));
    }

    /**
     * Browse specialties
     */
    async getIntegrationSpecialtyCollection(requestParameters: GetIntegrationSpecialtyCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Specialty>> {
        const response = await this.getIntegrationSpecialtyCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
