import { acceptHMRUpdate, defineStore } from "pinia";

export const useSpecialty = defineStore({
  id: "specialty",
  state: (): {
    specialties: null | Array<{ k: string; v: string }>;
  } => ({
    specialties: null,
  }),
  actions: {
    async load() {
      if (this.specialties !== null) {
        return;
      }
      this.specialties = (
        await this.$api.integration.getIntegrationSpecialtyCollection({
          limit: 1000,
        })
      ).map(({ name, code }) => ({
        k: code as string,
        v: `${code} ${name}`.trim(),
      }));
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useSpecialty, import.meta.hot));
}
