// async getElectiveChoicesReportRaw(
//  requestParameters: GetElectiveChoicesReportRequest,
//  initOverrides?: RequestInit | runtime.InitOverrideFunction
// ): Promise<runtime.ApiResponse<Array<ElectiveChoicesReportInner>>> {

import { ApiResponse, InitOverrideFunction } from "@/iot";

export async function fetchAll<
  TRequest extends { limit: number; offset: number },
  TResponse extends Array<unknown>,
>(
  api: (
    requestParameters: TRequest,
    initOverrides?: RequestInit | InitOverrideFunction,
  ) => Promise<ApiResponse<TResponse>>,
  requestParameters: TRequest,
  options: {
    limit?: number;
    offset?: number;
    initOverrides?: RequestInit | InitOverrideFunction;
  },
): Promise<TResponse> {
  let response: ApiResponse<TResponse>;
  let limit = options.limit ?? 100;
  let offset = options.offset ?? 0;
  let total: number;
  const buffer: TResponse = [];
  while (!total || total > offset) {
    response = await api(
      { ...requestParameters, limit, offset },
      options.initOverrides,
    );
    total = Number.parseInt(response.raw.headers.get("Pagination-Total"));
    // Update limit in case we did initial request with the value greater than allowed
    limit = Math.min(
      limit,
      response.raw.headers.get("Pagination-Limit") ?? limit,
    );

    buffer.push(...(await response.value()));
    offset += limit;
  }

  return buffer;
}
