import { Option, Specialty } from "@/iot";

export type OptionsMap = { [optionId: string]: Option };
export type SpecialtiesMap = { [code: string]: Specialty };
export type ChoicesMap = {
  [userId: string]: {
    primaryOptions: Array<string>;
    backupOptions: Array<string>;
  };
};

const abbreviationSplitRegex = /[ -]/;
export function abbreviateName(name: string): string {
  return name
    .replace('"', "")
    .split(abbreviationSplitRegex)
    .filter((part) => part.length > 3)
    .map((part) => part.substring(0, 1).toLocaleUpperCase())
    .join("");
}

export type Filter = {
  [n: string]: Array<string>;
};
