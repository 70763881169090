/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarElectiveEmbedded } from './CalendarElectiveEmbedded';
import {
    CalendarElectiveEmbeddedFromJSON,
    CalendarElectiveEmbeddedFromJSONTyped,
    CalendarElectiveEmbeddedToJSON,
} from './CalendarElectiveEmbedded';
import type { CalendarElectiveFlow } from './CalendarElectiveFlow';
import {
    CalendarElectiveFlowFromJSON,
    CalendarElectiveFlowFromJSONTyped,
    CalendarElectiveFlowToJSON,
} from './CalendarElectiveFlow';
import type { CalendarElectiveOutcomeInner } from './CalendarElectiveOutcomeInner';
import {
    CalendarElectiveOutcomeInnerFromJSON,
    CalendarElectiveOutcomeInnerFromJSONTyped,
    CalendarElectiveOutcomeInnerToJSON,
} from './CalendarElectiveOutcomeInner';
import type { Elective } from './Elective';
import {
    ElectiveFromJSON,
    ElectiveFromJSONTyped,
    ElectiveToJSON,
} from './Elective';
import type { StatementTemplate } from './StatementTemplate';
import {
    StatementTemplateFromJSON,
    StatementTemplateFromJSONTyped,
    StatementTemplateToJSON,
} from './StatementTemplate';

/**
 * 
 * @export
 * @interface PatchElectiveRequest
 */
export interface PatchElectiveRequest extends Elective {
    /**
     * 
     * @type {any}
     * @memberof PatchElectiveRequest
     */
    readonly type?: any | null;
}

/**
 * Check if a given object implements the PatchElectiveRequest interface.
 */
export function instanceOfPatchElectiveRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PatchElectiveRequestFromJSON(json: any): PatchElectiveRequest {
    return PatchElectiveRequestFromJSONTyped(json, false);
}

export function PatchElectiveRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PatchElectiveRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...ElectiveFromJSONTyped(json, ignoreDiscriminator),
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function PatchElectiveRequestToJSON(value?: PatchElectiveRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...ElectiveToJSON(value),
    };
}

