/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Organizer
 */
export interface Organizer {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof Organizer
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Organizer
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organizer
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Organizer
     */
    middleName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Organizer
     */
    departments?: Array<string> | null;
}

/**
 * Check if a given object implements the Organizer interface.
 */
export function instanceOfOrganizer(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OrganizerFromJSON(json: any): Organizer {
    return OrganizerFromJSONTyped(json, false);
}

export function OrganizerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Organizer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'middleName': !exists(json, 'middleName') ? undefined : json['middleName'],
        'departments': !exists(json, 'departments') ? undefined : json['departments'],
    };
}

export function OrganizerToJSON(value?: Organizer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'lastName': value.lastName,
        'firstName': value.firstName,
        'middleName': value.middleName,
        'departments': value.departments,
    };
}

