import { createApp } from "vue";
import { createPinia } from "pinia";
import App from "./App.vue";
import router from "./router";
import VueKeyCloak from "@dsb-norge/vue-keycloak-js";
import { createVfm } from "vue-final-modal";
import Toast from "vue-toastification";
import { useApi } from "@/iot";
import { i18n } from "@/locales";
import { useUser } from "@/stores";
import * as Sentry from "@sentry/vue";

const app = createApp(App);
const pinia = createPinia();

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracePropagationTargets: [
      "localhost",
      new URL(import.meta.env.VITE_BASE_URL).host,
      new URL(import.meta.env.VITE_API_URL).host,
      /^\//,
    ],
    tracesSampleRate: Number.parseFloat(
      import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
    ),
    replaysSessionSampleRate: Number.parseFloat(
      import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    ),
    replaysOnErrorSampleRate: Number.parseFloat(
      import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
    ),
  });
}
pinia.use(() => ({
  $api: useApi(
    app.config.globalProperties.$keycloak,
    import.meta.env.VITE_API_URL,
  ),
}));
app.use(pinia);
app.use(router);
app.use(i18n);

app.use(VueKeyCloak, {
  initOptions: {
    flow: "standard",
    checkLoginIframe: true,
    onLoad: "login-required",
    silentCheckSsoRedirectUri: `${
      import.meta.env.VITE_BASE_URL
    }/silent-check-sso.html`,
  },
  config: {
    url: import.meta.env.VITE_AUTH_URL,
    realm: import.meta.env.VITE_AUTH_REALM,
    clientId: import.meta.env.VITE_AUTH_CLIENT,
  },
  onReady() {
    return useUser().load();
  },
  onInitError() {
    window.location.reload();
  },
});
app.use(createVfm());
app.use(Toast);

app.mount("#app");
