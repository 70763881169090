/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    ChangeElectiveOptionsStatement,
    instanceOfChangeElectiveOptionsStatement,
    ChangeElectiveOptionsStatementFromJSON,
    ChangeElectiveOptionsStatementFromJSONTyped,
    ChangeElectiveOptionsStatementToJSON,
} from './ChangeElectiveOptionsStatement';
import {
    ChooseElectiveOptionsStatement,
    instanceOfChooseElectiveOptionsStatement,
    ChooseElectiveOptionsStatementFromJSON,
    ChooseElectiveOptionsStatementFromJSONTyped,
    ChooseElectiveOptionsStatementToJSON,
} from './ChooseElectiveOptionsStatement';
import {
    VerifyElectiveChoiceStatement,
    instanceOfVerifyElectiveChoiceStatement,
    VerifyElectiveChoiceStatementFromJSON,
    VerifyElectiveChoiceStatementFromJSONTyped,
    VerifyElectiveChoiceStatementToJSON,
} from './VerifyElectiveChoiceStatement';

/**
 * @type Statement
 * 
 * @export
 */
export type Statement = { type: 'change-elective-options' } & ChangeElectiveOptionsStatement | { type: 'choose-elective-options' } & ChooseElectiveOptionsStatement | { type: 'verify-elective-choice' } & VerifyElectiveChoiceStatement;

export function StatementFromJSON(json: any): Statement {
    return StatementFromJSONTyped(json, false);
}

export function StatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): Statement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'change-elective-options':
            return {...ChangeElectiveOptionsStatementFromJSONTyped(json, true), type: 'change-elective-options'};
        case 'choose-elective-options':
            return {...ChooseElectiveOptionsStatementFromJSONTyped(json, true), type: 'choose-elective-options'};
        case 'verify-elective-choice':
            return {...VerifyElectiveChoiceStatementFromJSONTyped(json, true), type: 'verify-elective-choice'};
        default:
            throw new Error(`No variant of Statement exists with 'type=${json['type']}'`);
    }
}

export function StatementToJSON(value?: Statement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'change-elective-options':
            return ChangeElectiveOptionsStatementToJSON(value);
        case 'choose-elective-options':
            return ChooseElectiveOptionsStatementToJSON(value);
        case 'verify-elective-choice':
            return VerifyElectiveChoiceStatementToJSON(value);
        default:
            throw new Error(`No variant of Statement exists with 'type=${value['type']}'`);
    }

}

