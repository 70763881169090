/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Choice,
} from '../models';
import {
    ChoiceFromJSON,
    ChoiceToJSON,
} from '../models';

export interface GetChoiceRequest {
    id: string;
    expand?: string;
}

export interface GetChoiceCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface PostChoiceRequest {
    expand?: string;
    choice?: Choice;
}

/**
 * 
 */
export class ChoiceApi extends runtime.BaseAPI {

    /**
     * Get choice
     */
    async getChoiceRaw(requestParameters: GetChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Choice>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getChoice.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/choices/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChoiceFromJSON(jsonValue));
    }

    /**
     * Get choice
     */
    async getChoice(requestParameters: GetChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Choice> {
        const response = await this.getChoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get choices
     */
    async getChoiceCollectionRaw(requestParameters: GetChoiceCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Choice>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/choices`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ChoiceFromJSON));
    }

    /**
     * Get choices
     */
    async getChoiceCollection(requestParameters: GetChoiceCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Choice>> {
        const response = await this.getChoiceCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create choice
     */
    async postChoiceRaw(requestParameters: PostChoiceRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Choice>> {
        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/choices`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChoiceToJSON(requestParameters.choice),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ChoiceFromJSON(jsonValue));
    }

    /**
     * Create choice
     */
    async postChoice(requestParameters: PostChoiceRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Choice> {
        const response = await this.postChoiceRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
