/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Cohort user distribution settings.
 * @export
 * @interface OptionCohortSettings
 */
export interface OptionCohortSettings {
    /**
     * The name for the cohort. Use Mustache templates to substitute placeholders:
     * 
     * - `{{full_name}}` - the full discipline name.
     * - `{{short_name}}` - the shourt discipline name.
     * - `{{y_start}}` - the last 2 digits of the academic year start, e.g. 22 in 2022/2023.
     * - `{{year_start}}` - the academic year start, e.g. 2022 in 2022/2023.
     * - `{{y_end}}` - the last 2 digits of the academic year start, e.g. 23 in 2022/2023.
     * - `{{year_end}}` - the academic year end, e.g. 2023 in 2022/2023.
     * - `{{number}}` - sequential number of the cohort for a option, e.g. 1 for the first 30 people in option and 2 for the second.
     * @type {string}
     * @memberof OptionCohortSettings
     */
    name: string;
    /**
     * The minimum size of the cohort.
     * @type {number}
     * @memberof OptionCohortSettings
     */
    minSize?: number;
    /**
     * The maximum size of the cohort.
     * @type {number}
     * @memberof OptionCohortSettings
     */
    maxSize?: number;
    /**
     * The maxinum count of cohorts to create.
     * @type {number}
     * @memberof OptionCohortSettings
     */
    maxCount?: number;
    /**
     * The list of cohort names to be generated.
     * @type {Array<string>}
     * @memberof OptionCohortSettings
     */
    availableNames?: Array<string>;
}

/**
 * Check if a given object implements the OptionCohortSettings interface.
 */
export function instanceOfOptionCohortSettings(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function OptionCohortSettingsFromJSON(json: any): OptionCohortSettings {
    return OptionCohortSettingsFromJSONTyped(json, false);
}

export function OptionCohortSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionCohortSettings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'minSize': !exists(json, 'minSize') ? undefined : json['minSize'],
        'maxSize': !exists(json, 'maxSize') ? undefined : json['maxSize'],
        'maxCount': !exists(json, 'maxCount') ? undefined : json['maxCount'],
        'availableNames': !exists(json, 'availableNames') ? undefined : json['availableNames'],
    };
}

export function OptionCohortSettingsToJSON(value?: OptionCohortSettings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'minSize': value.minSize,
        'maxSize': value.maxSize,
        'maxCount': value.maxCount,
        'availableNames': value.availableNames,
    };
}

