import { acceptHMRUpdate, defineStore } from "pinia";
import {
  Consolidation,
  Elective,
  JobResponse,
  JobResponseStatusEnum,
  JobResponseTypeEnum,
  Option,
} from "@/iot";

const ReloadTimeout = 10000;

export type JobState = {
  isLoading: boolean;
  id: string | null;
  job: JobResponse | null;
  electiveLoader: Promise<void> | null;
  elective: Elective | null;
  optionsLoader: Promise<void> | null;
  options: Array<Option> | null;
  consolidationLoader: Promise<void> | null;
  consolidation: Consolidation | null;
};

export const useJob = defineStore({
  id: "job",
  state: () =>
    ({
      isLoading: false,
      id: null,
      job: null,
      electiveLoader: null,
      elective: null,
      optionsLoader: null,
      options: null,
      consolidationLoader: null,
      consolidation: null,
    }) as JobState,
  getters: {
    isCreateElectiveFromPlans: ({ job }) =>
      job && job.type === JobResponseTypeEnum.CreateElectiveFromPlans,
    isStatusFinal: ({ job }) =>
      job &&
      job.status &&
      (job.status === JobResponseStatusEnum.Completed ||
        job.status === JobResponseStatusEnum.Failed),
    optionsMap: ({ options }) =>
      options?.reduce(
        (map, option) => ({
          ...map,
          [option.id as string]: option,
        }),
        {},
      ) ?? {},
  },
  actions: {
    async setId(id: string) {
      this.id = id;
      this.elective = null;
      this.options = null;
      this.consolidation = null;
      await this.load();
    },
    setJob(job: JobResponse) {
      if (!job.id) {
        return;
      }
      this.id = job.id;
      this.job = job;
      if (!this.isStatusFinal) {
        setTimeout(() => this.load(true), ReloadTimeout);
      }
    },
    async load(noLoading = false) {
      if (this.id === null) {
        return;
      }
      if (!noLoading) {
        this.isLoading = true;
      }
      try {
        this.setJob(
          await this.$api.job.getJob({
            id: this.id,
            expand: "artifacts.file,messages",
          }),
        );
      } finally {
        if (!noLoading) {
          this.isLoading = false;
        }
      }
    },
    async loadElective(electiveId: string) {
      if (this.electiveLoader !== null) {
        await this.electiveLoader;
      }
      if (this.elective?.id === electiveId) {
        return;
      }
      this.electiveLoader = new Promise((resolve) =>
        this.$api.elective
          .getElective({
            id: electiveId,
            slug: false,
          })
          .then((elective) => {
            this.elective = elective;
            resolve();
          }),
      );
    },
    async loadOptions(optionIds: Array<string>) {
      if (this.optionsLoader !== null) {
        await this.optionsLoader;
      }
      const existingOptionIds = this.options?.map(({ id }) => id) ?? [];
      const optionIdsToLoad = optionIds.filter(
        (optionId) => !existingOptionIds.includes(optionId),
      );
      if (!optionIdsToLoad.length) {
        return;
      }
      this.optionsLoader = new Promise((resolve) =>
        this.$api.option
          .getOptionCollection({
            filter: `id:${optionIds.join(",")}`,
          })
          .then((options) => {
            this.options = [...(this.options ?? []), ...options];
            resolve();
          }),
      );
    },
    async loadConsolidation(consolidationId: string) {
      if (this.consolidationLoader !== null) {
        await this.consolidationLoader;
      }
      if (this.consolidation?.id === consolidationId) {
        return;
      }
      this.consolidationLoader = new Promise((resolve) =>
        this.$api.consolidation
          .getConsolidation({
            id: consolidationId,
            expand: "author,elective",
          })
          .then((consolidation) => {
            this.consolidation = consolidation;
            resolve();
          }),
      );
    },
    // async retry(withoutDryRun = false) {
    //   if (!this.job || !this.job.dryRun || !this.job.id) {
    //     return;
    //   }
    //   const job = cloneDeep(this.job);
    //   if (withoutDryRun) {
    //     job.dryRun = false;
    //     job.parentId = this.job.id;
    //   }
    //   const newJob = await this.$api.job.postJob({ jobRequest: job });
    //   this.setJob(newJob);
    //   await router.replace({
    //     name: "job:read",
    //     params: { id: newJob.id },
    //   });
    // },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useJob, import.meta.hot));
}
