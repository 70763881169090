/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChangeElectiveOptionsStatementAllOfChanges } from './ChangeElectiveOptionsStatementAllOfChanges';
import {
    ChangeElectiveOptionsStatementAllOfChangesFromJSON,
    ChangeElectiveOptionsStatementAllOfChangesFromJSONTyped,
    ChangeElectiveOptionsStatementAllOfChangesToJSON,
} from './ChangeElectiveOptionsStatementAllOfChanges';
import type { ChangeElectiveOptionsStatementAllOfEmbedded } from './ChangeElectiveOptionsStatementAllOfEmbedded';
import {
    ChangeElectiveOptionsStatementAllOfEmbeddedFromJSON,
    ChangeElectiveOptionsStatementAllOfEmbeddedFromJSONTyped,
    ChangeElectiveOptionsStatementAllOfEmbeddedToJSON,
} from './ChangeElectiveOptionsStatementAllOfEmbedded';

/**
 * 
 * @export
 * @interface ChangeElectiveOptionsStatement
 */
export interface ChangeElectiveOptionsStatement {
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly userId?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly printFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    type: ChangeElectiveOptionsStatementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly status?: ChangeElectiveOptionsStatementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly response?: string | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof ChangeElectiveOptionsStatement
     */
    readonly updatedAt?: Date;
    /**
     * The academic year of the elective.
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    academicYear: string;
    /**
     * The number of academic term within the academic year, e.g. 1 for first semester on the year of study.
     * @type {number}
     * @memberof ChangeElectiveOptionsStatement
     */
    academicTerm: ChangeElectiveOptionsStatementAcademicTermEnum;
    /**
     * 
     * @type {string}
     * @memberof ChangeElectiveOptionsStatement
     */
    reason: string;
    /**
     * 
     * @type {Array<ChangeElectiveOptionsStatementAllOfChanges>}
     * @memberof ChangeElectiveOptionsStatement
     */
    changes: Array<ChangeElectiveOptionsStatementAllOfChanges>;
    /**
     * 
     * @type {ChangeElectiveOptionsStatementAllOfEmbedded}
     * @memberof ChangeElectiveOptionsStatement
     */
    embedded?: ChangeElectiveOptionsStatementAllOfEmbedded;
}


/**
 * @export
 */
export const ChangeElectiveOptionsStatementTypeEnum = {
    ChangeElectiveOptions: 'change-elective-options',
    ChooseElectiveOptions: 'choose-elective-options',
    VerifyElectiveChoice: 'verify-elective-choice'
} as const;
export type ChangeElectiveOptionsStatementTypeEnum = typeof ChangeElectiveOptionsStatementTypeEnum[keyof typeof ChangeElectiveOptionsStatementTypeEnum];

/**
 * @export
 */
export const ChangeElectiveOptionsStatementStatusEnum = {
    Filed: 'filed',
    Withdrawn: 'withdrawn',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Fulfilled: 'fulfilled',
    Formed: 'formed'
} as const;
export type ChangeElectiveOptionsStatementStatusEnum = typeof ChangeElectiveOptionsStatementStatusEnum[keyof typeof ChangeElectiveOptionsStatementStatusEnum];

/**
 * @export
 */
export const ChangeElectiveOptionsStatementAcademicTermEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;
export type ChangeElectiveOptionsStatementAcademicTermEnum = typeof ChangeElectiveOptionsStatementAcademicTermEnum[keyof typeof ChangeElectiveOptionsStatementAcademicTermEnum];


/**
 * Check if a given object implements the ChangeElectiveOptionsStatement interface.
 */
export function instanceOfChangeElectiveOptionsStatement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "academicYear" in value;
    isInstance = isInstance && "academicTerm" in value;
    isInstance = isInstance && "reason" in value;
    isInstance = isInstance && "changes" in value;

    return isInstance;
}

export function ChangeElectiveOptionsStatementFromJSON(json: any): ChangeElectiveOptionsStatement {
    return ChangeElectiveOptionsStatementFromJSONTyped(json, false);
}

export function ChangeElectiveOptionsStatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeElectiveOptionsStatement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'printFileId': !exists(json, 'printFileId') ? undefined : json['printFileId'],
        'type': json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'academicYear': json['academicYear'],
        'academicTerm': json['academicTerm'],
        'reason': json['reason'],
        'changes': ((json['changes'] as Array<any>).map(ChangeElectiveOptionsStatementAllOfChangesFromJSON)),
        'embedded': !exists(json, '_embedded') ? undefined : ChangeElectiveOptionsStatementAllOfEmbeddedFromJSON(json['_embedded']),
    };
}

export function ChangeElectiveOptionsStatementToJSON(value?: ChangeElectiveOptionsStatement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'academicYear': value.academicYear,
        'academicTerm': value.academicTerm,
        'reason': value.reason,
        'changes': ((value.changes as Array<any>).map(ChangeElectiveOptionsStatementAllOfChangesToJSON)),
        '_embedded': ChangeElectiveOptionsStatementAllOfEmbeddedToJSON(value.embedded),
    };
}

