/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    AbstractJobStep,
    instanceOfAbstractJobStep,
    AbstractJobStepFromJSON,
    AbstractJobStepFromJSONTyped,
    AbstractJobStepToJSON,
} from './AbstractJobStep';
import {
    BuildElectiveJobStep,
    instanceOfBuildElectiveJobStep,
    BuildElectiveJobStepFromJSON,
    BuildElectiveJobStepFromJSONTyped,
    BuildElectiveJobStepToJSON,
} from './BuildElectiveJobStep';
import {
    CreateGroupsJobStep,
    instanceOfCreateGroupsJobStep,
    CreateGroupsJobStepFromJSON,
    CreateGroupsJobStepFromJSONTyped,
    CreateGroupsJobStepToJSON,
} from './CreateGroupsJobStep';
import {
    CreateLimitationJobStep,
    instanceOfCreateLimitationJobStep,
    CreateLimitationJobStepFromJSON,
    CreateLimitationJobStepFromJSONTyped,
    CreateLimitationJobStepToJSON,
} from './CreateLimitationJobStep';
import {
    DistributeCohortConsolidationJobStep,
    instanceOfDistributeCohortConsolidationJobStep,
    DistributeCohortConsolidationJobStepFromJSON,
    DistributeCohortConsolidationJobStepFromJSONTyped,
    DistributeCohortConsolidationJobStepToJSON,
} from './DistributeCohortConsolidationJobStep';
import {
    ExportConsolidationsJobStep,
    instanceOfExportConsolidationsJobStep,
    ExportConsolidationsJobStepFromJSON,
    ExportConsolidationsJobStepFromJSONTyped,
    ExportConsolidationsJobStepToJSON,
} from './ExportConsolidationsJobStep';
import {
    FillConsolidationJobStep,
    instanceOfFillConsolidationJobStep,
    FillConsolidationJobStepFromJSON,
    FillConsolidationJobStepFromJSONTyped,
    FillConsolidationJobStepToJSON,
} from './FillConsolidationJobStep';
import {
    FillLimitationJobStep,
    instanceOfFillLimitationJobStep,
    FillLimitationJobStepFromJSON,
    FillLimitationJobStepFromJSONTyped,
    FillLimitationJobStepToJSON,
} from './FillLimitationJobStep';
import {
    FindPlansJobStep,
    instanceOfFindPlansJobStep,
    FindPlansJobStepFromJSON,
    FindPlansJobStepFromJSONTyped,
    FindPlansJobStepToJSON,
} from './FindPlansJobStep';
import {
    GenerateGroupNamesJobStep,
    instanceOfGenerateGroupNamesJobStep,
    GenerateGroupNamesJobStepFromJSON,
    GenerateGroupNamesJobStepFromJSONTyped,
    GenerateGroupNamesJobStepToJSON,
} from './GenerateGroupNamesJobStep';
import {
    PrepareSectionOptionsJobStep,
    instanceOfPrepareSectionOptionsJobStep,
    PrepareSectionOptionsJobStepFromJSON,
    PrepareSectionOptionsJobStepFromJSONTyped,
    PrepareSectionOptionsJobStepToJSON,
} from './PrepareSectionOptionsJobStep';
import {
    SyncConsolidationBackJobStep,
    instanceOfSyncConsolidationBackJobStep,
    SyncConsolidationBackJobStepFromJSON,
    SyncConsolidationBackJobStepFromJSONTyped,
    SyncConsolidationBackJobStepToJSON,
} from './SyncConsolidationBackJobStep';
import {
    SyncConsolidationUserOptionsJobStep,
    instanceOfSyncConsolidationUserOptionsJobStep,
    SyncConsolidationUserOptionsJobStepFromJSON,
    SyncConsolidationUserOptionsJobStepFromJSONTyped,
    SyncConsolidationUserOptionsJobStepToJSON,
} from './SyncConsolidationUserOptionsJobStep';
import {
    ValidateConsolidationJobStep,
    instanceOfValidateConsolidationJobStep,
    ValidateConsolidationJobStepFromJSON,
    ValidateConsolidationJobStepFromJSONTyped,
    ValidateConsolidationJobStepToJSON,
} from './ValidateConsolidationJobStep';

/**
 * @type JobStep
 * 
 * @export
 */
export type JobStep = { type: 'build-elective' } & BuildElectiveJobStep | { type: 'create-groups' } & CreateGroupsJobStep | { type: 'create-limitation' } & CreateLimitationJobStep | { type: 'distribute-cohort-consolidation' } & DistributeCohortConsolidationJobStep | { type: 'export-consolidations' } & ExportConsolidationsJobStep | { type: 'fill-consolidation' } & FillConsolidationJobStep | { type: 'fill-limitation' } & FillLimitationJobStep | { type: 'find-plans' } & FindPlansJobStep | { type: 'find-similar-sections' } & AbstractJobStep | { type: 'generate-group-names' } & GenerateGroupNamesJobStep | { type: 'load-plans' } & AbstractJobStep | { type: 'prepare-section-options' } & PrepareSectionOptionsJobStep | { type: 'sync-consolidation-back' } & SyncConsolidationBackJobStep | { type: 'sync-consolidation-user-options' } & SyncConsolidationUserOptionsJobStep | { type: 'sync-consolidation-users-back' } & AbstractJobStep | { type: 'validate-consolidation' } & ValidateConsolidationJobStep;

export function JobStepFromJSON(json: any): JobStep {
    return JobStepFromJSONTyped(json, false);
}

export function JobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'build-elective':
            return {...BuildElectiveJobStepFromJSONTyped(json, true), type: 'build-elective'};
        case 'create-groups':
            return {...CreateGroupsJobStepFromJSONTyped(json, true), type: 'create-groups'};
        case 'create-limitation':
            return {...CreateLimitationJobStepFromJSONTyped(json, true), type: 'create-limitation'};
        case 'distribute-cohort-consolidation':
            return {...DistributeCohortConsolidationJobStepFromJSONTyped(json, true), type: 'distribute-cohort-consolidation'};
        case 'export-consolidations':
            return {...ExportConsolidationsJobStepFromJSONTyped(json, true), type: 'export-consolidations'};
        case 'fill-consolidation':
            return {...FillConsolidationJobStepFromJSONTyped(json, true), type: 'fill-consolidation'};
        case 'fill-limitation':
            return {...FillLimitationJobStepFromJSONTyped(json, true), type: 'fill-limitation'};
        case 'find-plans':
            return {...FindPlansJobStepFromJSONTyped(json, true), type: 'find-plans'};
        case 'find-similar-sections':
            return {...AbstractJobStepFromJSONTyped(json, true), type: 'find-similar-sections'};
        case 'generate-group-names':
            return {...GenerateGroupNamesJobStepFromJSONTyped(json, true), type: 'generate-group-names'};
        case 'load-plans':
            return {...AbstractJobStepFromJSONTyped(json, true), type: 'load-plans'};
        case 'prepare-section-options':
            return {...PrepareSectionOptionsJobStepFromJSONTyped(json, true), type: 'prepare-section-options'};
        case 'sync-consolidation-back':
            return {...SyncConsolidationBackJobStepFromJSONTyped(json, true), type: 'sync-consolidation-back'};
        case 'sync-consolidation-user-options':
            return {...SyncConsolidationUserOptionsJobStepFromJSONTyped(json, true), type: 'sync-consolidation-user-options'};
        case 'sync-consolidation-users-back':
            return {...AbstractJobStepFromJSONTyped(json, true), type: 'sync-consolidation-users-back'};
        case 'validate-consolidation':
            return {...ValidateConsolidationJobStepFromJSONTyped(json, true), type: 'validate-consolidation'};
        default:
            throw new Error(`No variant of JobStep exists with 'type=${json['type']}'`);
    }
}

export function JobStepToJSON(value?: JobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'build-elective':
            return BuildElectiveJobStepToJSON(value);
        case 'create-groups':
            return CreateGroupsJobStepToJSON(value);
        case 'create-limitation':
            return CreateLimitationJobStepToJSON(value);
        case 'distribute-cohort-consolidation':
            return DistributeCohortConsolidationJobStepToJSON(value);
        case 'export-consolidations':
            return ExportConsolidationsJobStepToJSON(value);
        case 'fill-consolidation':
            return FillConsolidationJobStepToJSON(value);
        case 'fill-limitation':
            return FillLimitationJobStepToJSON(value);
        case 'find-plans':
            return FindPlansJobStepToJSON(value);
        case 'find-similar-sections':
            return AbstractJobStepToJSON(value);
        case 'generate-group-names':
            return GenerateGroupNamesJobStepToJSON(value);
        case 'load-plans':
            return AbstractJobStepToJSON(value);
        case 'prepare-section-options':
            return PrepareSectionOptionsJobStepToJSON(value);
        case 'sync-consolidation-back':
            return SyncConsolidationBackJobStepToJSON(value);
        case 'sync-consolidation-user-options':
            return SyncConsolidationUserOptionsJobStepToJSON(value);
        case 'sync-consolidation-users-back':
            return AbstractJobStepToJSON(value);
        case 'validate-consolidation':
            return ValidateConsolidationJobStepToJSON(value);
        default:
            throw new Error(`No variant of JobStep exists with 'type=${value['type']}'`);
    }

}

