/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PlanDisciplineParameters
 */
export interface PlanDisciplineParameters {
    /**
     * 
     * @type {string}
     * @memberof PlanDisciplineParameters
     */
    index?: string;
    /**
     * 
     * @type {string}
     * @memberof PlanDisciplineParameters
     */
    section?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PlanDisciplineParameters
     */
    isOptional?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PlanDisciplineParameters
     */
    z?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDisciplineParameters
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof PlanDisciplineParameters
     */
    hoursZ?: number;
}

/**
 * Check if a given object implements the PlanDisciplineParameters interface.
 */
export function instanceOfPlanDisciplineParameters(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlanDisciplineParametersFromJSON(json: any): PlanDisciplineParameters {
    return PlanDisciplineParametersFromJSONTyped(json, false);
}

export function PlanDisciplineParametersFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDisciplineParameters {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'index': !exists(json, 'index') ? undefined : json['index'],
        'section': !exists(json, 'section') ? undefined : json['section'],
        'isOptional': !exists(json, 'isOptional') ? undefined : json['isOptional'],
        'z': !exists(json, 'Z') ? undefined : json['Z'],
        'hours': !exists(json, 'hours') ? undefined : json['hours'],
        'hoursZ': !exists(json, 'hoursZ') ? undefined : json['hoursZ'],
    };
}

export function PlanDisciplineParametersToJSON(value?: PlanDisciplineParameters | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'index': value.index,
        'section': value.section,
        'isOptional': value.isOptional,
        'Z': value.z,
        'hours': value.hours,
        'hoursZ': value.hoursZ,
    };
}

