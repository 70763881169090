/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarEvent } from './CalendarEvent';
import {
    CalendarEventFromJSON,
    CalendarEventFromJSONTyped,
    CalendarEventToJSON,
} from './CalendarEvent';
import type { CalendarEventRegistrationEmbeddedAbsentProofFile } from './CalendarEventRegistrationEmbeddedAbsentProofFile';
import {
    CalendarEventRegistrationEmbeddedAbsentProofFileFromJSON,
    CalendarEventRegistrationEmbeddedAbsentProofFileFromJSONTyped,
    CalendarEventRegistrationEmbeddedAbsentProofFileToJSON,
} from './CalendarEventRegistrationEmbeddedAbsentProofFile';
import type { CalendarEventRegistrationEmbeddedRegisteredBy } from './CalendarEventRegistrationEmbeddedRegisteredBy';
import {
    CalendarEventRegistrationEmbeddedRegisteredByFromJSON,
    CalendarEventRegistrationEmbeddedRegisteredByFromJSONTyped,
    CalendarEventRegistrationEmbeddedRegisteredByToJSON,
} from './CalendarEventRegistrationEmbeddedRegisteredBy';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface CalendarEventRegistrationEmbedded
 */
export interface CalendarEventRegistrationEmbedded {
    /**
     * 
     * @type {CalendarEvent}
     * @memberof CalendarEventRegistrationEmbedded
     */
    calendarEvent?: CalendarEvent;
    /**
     * 
     * @type {User}
     * @memberof CalendarEventRegistrationEmbedded
     */
    user?: User;
    /**
     * 
     * @type {CalendarEventRegistrationEmbeddedAbsentProofFile}
     * @memberof CalendarEventRegistrationEmbedded
     */
    absentProofFile?: CalendarEventRegistrationEmbeddedAbsentProofFile | null;
    /**
     * 
     * @type {CalendarEventRegistrationEmbeddedRegisteredBy}
     * @memberof CalendarEventRegistrationEmbedded
     */
    registeredBy?: CalendarEventRegistrationEmbeddedRegisteredBy | null;
}

/**
 * Check if a given object implements the CalendarEventRegistrationEmbedded interface.
 */
export function instanceOfCalendarEventRegistrationEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventRegistrationEmbeddedFromJSON(json: any): CalendarEventRegistrationEmbedded {
    return CalendarEventRegistrationEmbeddedFromJSONTyped(json, false);
}

export function CalendarEventRegistrationEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventRegistrationEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'calendarEvent': !exists(json, 'calendarEvent') ? undefined : CalendarEventFromJSON(json['calendarEvent']),
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'absentProofFile': !exists(json, 'absentProofFile') ? undefined : CalendarEventRegistrationEmbeddedAbsentProofFileFromJSON(json['absentProofFile']),
        'registeredBy': !exists(json, 'registeredBy') ? undefined : CalendarEventRegistrationEmbeddedRegisteredByFromJSON(json['registeredBy']),
    };
}

export function CalendarEventRegistrationEmbeddedToJSON(value?: CalendarEventRegistrationEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'calendarEvent': CalendarEventToJSON(value.calendarEvent),
        'user': UserToJSON(value.user),
        'absentProofFile': CalendarEventRegistrationEmbeddedAbsentProofFileToJSON(value.absentProofFile),
        'registeredBy': CalendarEventRegistrationEmbeddedRegisteredByToJSON(value.registeredBy),
    };
}

