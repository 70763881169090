/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface ConsolidationRowEmbedded
 */
export interface ConsolidationRowEmbedded {
    /**
     * 
     * @type {User}
     * @memberof ConsolidationRowEmbedded
     */
    user?: User;
}

/**
 * Check if a given object implements the ConsolidationRowEmbedded interface.
 */
export function instanceOfConsolidationRowEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ConsolidationRowEmbeddedFromJSON(json: any): ConsolidationRowEmbedded {
    return ConsolidationRowEmbeddedFromJSONTyped(json, false);
}

export function ConsolidationRowEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsolidationRowEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
    };
}

export function ConsolidationRowEmbeddedToJSON(value?: ConsolidationRowEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
    };
}

