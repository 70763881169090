import { acceptHMRUpdate, defineStore } from "pinia";
import {
  CalendarEvent,
  CalendarEventRegistration,
  CalendarEventRegistrationAttendanceEnum,
  CalendarEventRegistrationStatusEnum,
  Specialty,
} from "@/iot";
import { useToast } from "vue-toastification";
import router from "@/router";
import { userFullName } from "@/stores/user";
import { format } from "@/utils/date";
import { abbreviateName, SpecialtiesMap } from "@/stores/common";
import { useI18n } from "@/locales";
import { buildEnhancedFilter } from "@/utils/stores";

export type EventAttendanceTableRow = {
  key: string;
  value: {
    registrationId: string;
    user: string;
    departments: string[];
    specialties: string[];
    userCohorts: string[];
    rating: number;
    status: CalendarEventRegistrationStatusEnum;
    attendance?: CalendarEventRegistrationAttendanceEnum;
    absenceReason?: string;
    createdAt: string;
    updatedAt: string;
  };
};

export type CalendarEventState = {
  isLoading: boolean;
  eventId: string | null;
  event: CalendarEvent | null;
  registrations: CalendarEventRegistration[] | null;
  specialties: SpecialtiesMap;
  filterName: string;
  filterDepartment: string[];
  filterSpecialty: string[];
  filterCohort: string[];
  filterStatus: string[];
  filterAttendance: string[];
};

export const useCalendarEvent = defineStore({
  id: "calendarEvent",
  state: () =>
    ({
      eventId: null,
      event: null,
      registrations: null,
      specialties: {},
      filterName: "",
      filterDepartment: [],
      filterSpecialty: [],
      filterCohort: [],
      filterStatus: ["registered"],
      filterAttendance: [],
      isLoading: false,
    }) as CalendarEventState,
  getters: {
    dataTable: function ({
      registrations,
    }: CalendarEventState): Array<EventAttendanceTableRow> {
      return (
        registrations?.map(
          (registration): EventAttendanceTableRow => ({
            key: registration.id!,
            value: {
              registrationId: registration.id!,
              user: userFullName(registration.embedded?.user),
              departments: registration.embedded?.user?.departments || [],
              specialties: registration.embedded?.user?.specialties || [],
              userCohorts:
                registration.embedded?.user?.embedded?.cohorts
                  ?.filter(
                    ({ type, name }) =>
                      ["academic", "manual", "unknown"].includes(type ?? "") &&
                      !name?.includes("Уч.гр."),
                  )
                  ?.map(({ name }) => name || "") || [],
              rating: registration.embedded?.user?.rating ?? 0,
              status: registration.status,
              attendance: registration.attendance,
              createdAt: registration.createdAt
                ? format(registration.createdAt, "HH:mm dd.MM.uuuu")
                : "",
              updatedAt: registration.updatedAt
                ? format(registration.updatedAt, "HH:mm dd.MM.uuuu")
                : "",
            },
          }),
        ) || []
      ).sort((a, b) => a.value.user.localeCompare(b.value.user));
    },
    filter: ({
      filterName,
      filterDepartment,
      filterSpecialty,
      filterCohort,
      filterStatus,
      filterAttendance,
    }: CalendarEventState) => {
      const filters: Array<(row: EventAttendanceTableRow) => boolean> = [];
      if (filterName.trim() !== "") {
        const filterNameLower = filterName.toLowerCase();
        filters.push(
          (row: EventAttendanceTableRow) =>
            !row.value.user ||
            row.value.user.toLowerCase().includes(filterNameLower),
        );
      }
      filters.push(
        buildEnhancedFilter<EventAttendanceTableRow["value"]>(
          filterDepartment,
          "departments",
        ),
      );
      filters.push(
        buildEnhancedFilter<EventAttendanceTableRow["value"]>(
          filterSpecialty,
          "specialties",
        ),
      );
      filters.push(
        buildEnhancedFilter<EventAttendanceTableRow["value"]>(
          filterCohort,
          "userCohorts",
        ),
      );
      if (filterStatus.length) {
        filters.push((row: EventAttendanceTableRow) =>
          filterStatus.includes(row.value.status ?? ""),
        );
      }
      if (filterAttendance.length) {
        filters.push(
          (row: EventAttendanceTableRow) =>
            (filterAttendance.includes("") && !row.value.attendance) ||
            filterAttendance.includes(row.value.attendance),
        );
      }

      return (row: EventAttendanceTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },

    availableCohorts({
      registrations,
    }: CalendarEventState): Array<{ k: string; v: string }> {
      return Object.values(
        registrations
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.embedded?.cohorts || [])
          ?.filter(
            ({ type, name }) =>
              ["academic", "manual", "unknown"].includes(type ?? "") &&
              !name?.includes("Уч.гр."),
          )
          ?.map(({ name }) => ({ k: name, v: name }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableCohorts(): Array<{ k: string; v: string }> {
      return [
        { k: "", v: "Нет группы" },
        { k: "!empty", v: "Любая группа" },
        ...this.availableCohorts,
      ];
    },
    availableDepartments({
      registrations,
    }: CalendarEventState): Array<{ k: string; v: string }> {
      return Object.values(
        registrations
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.departments || [])
          ?.map((name) => ({ k: name, v: abbreviateName(name) }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableDepartments(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableDepartments];
    },
    availableSpecialties({
      registrations,
      specialties,
    }: CalendarEventState): Array<{ k: string; v: string }> {
      return Object.values(
        registrations
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.specialties || [])
          ?.map((code) => ({
            k: code,
            v: `${code} ${specialties[code]?.name || ""}`.trim(),
          }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableSpecialties(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableSpecialties];
    },
    filterableStatuses(): Array<{ k: string; v: string }> {
      const { t } = useI18n();

      return Object.values(CalendarEventRegistrationStatusEnum).map(
        (status) => ({
          k: status,
          v: t(`calendar-event.registration.status.${status}`),
        }),
      );
    },
    filterableAttendances(): Array<{ k: string; v: string }> {
      const { t } = useI18n();

      return [
        { k: "", v: "Без отметки" },
        ...Object.values(CalendarEventRegistrationAttendanceEnum).map(
          (status) => ({
            k: status,
            v: t(`calendar-event.registration.attendance.${status}`),
          }),
        ),
      ];
    },
  },
  actions: {
    async updateAttendance(
      registrationId: string,
      attendance: CalendarEventRegistrationAttendanceEnum,
    ) {
      const registration =
        await this.$api.calendarEvent.patchCalendarEventRegistration({
          id: registrationId,
          expand: "user.cohorts",
          calendarEventRegistration: {
            attendance,
          },
        });

      const existingIndex = this.registrations!.findIndex(
        ({ id }) => id === registrationId,
      );
      this.registrations![existingIndex] = registration;
    },
    async setId(newId: string) {
      if (this.eventId === newId) {
        return;
      }
      this.eventId = newId;
      return await this.load();
    },
    async load(noLoading = false) {
      if (this.eventId === null) {
        return;
      }
      if (!noLoading) {
        this.isLoading = true;
      }
      try {
        [this.event, this.registrations] = await Promise.all([
          this.$api.calendarEvent.getCalendarEvent({
            id: this.eventId,
            expand: "elective",
          }),
          this.$api.calendarEvent.getCalendarEventRegistrationCollection({
            filter: `calendarEventId:${this.eventId}`,
            expand: "user.cohorts",
          }),
        ]);
        this.loadSpecialties();
      } catch (e) {
        useToast().error("Не удалось загрузить занятие");
        await router.replace({ name: "home" });
      } finally {
        if (!noLoading) {
          this.isLoading = false;
        }
      }
    },
    async loadSpecialties() {
      if (!this.registrations) {
        return;
      }
      const specialtyCodes = Object.keys(
        this.registrations
          ?.map((row) => row.embedded?.user)
          ?.flatMap((user) => user?.specialties || [])
          ?.reduce((map, el) => ({ ...map, [el]: el }), {}) || {},
      );
      const specialtiesRaw =
        await this.$api.integration.getIntegrationSpecialtyCollection({
          filter: `code:${specialtyCodes.join(",")}`,
          limit: 1000,
        });
      this.specialties = specialtiesRaw.reduce(
        (map, specialty) => ({
          ...map,
          [specialty.code as string]: specialty,
        }),
        {} as { [code: string]: Specialty },
      );
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCalendarEvent, import.meta.hot));
}
