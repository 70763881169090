/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FindPlansJobStep
 */
export interface FindPlansJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof FindPlansJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FindPlansJobStep
     */
    type?: FindPlansJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FindPlansJobStep
     */
    status?: FindPlansJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FindPlansJobStep
     */
    progress?: number;
    /**
     * The elective period of study.
     * @type {string}
     * @memberof FindPlansJobStep
     */
    periodOfStudy?: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof FindPlansJobStep
     */
    academicTerm?: number | null;
    /**
     * A list of filters against Plan integration resource.
     * Plan is used if any of the filters match.
     * @type {Array<string>}
     * @memberof FindPlansJobStep
     */
    planFilters?: Array<string>;
}


/**
 * @export
 */
export const FindPlansJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type FindPlansJobStepTypeEnum = typeof FindPlansJobStepTypeEnum[keyof typeof FindPlansJobStepTypeEnum];

/**
 * @export
 */
export const FindPlansJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type FindPlansJobStepStatusEnum = typeof FindPlansJobStepStatusEnum[keyof typeof FindPlansJobStepStatusEnum];


/**
 * Check if a given object implements the FindPlansJobStep interface.
 */
export function instanceOfFindPlansJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FindPlansJobStepFromJSON(json: any): FindPlansJobStep {
    return FindPlansJobStepFromJSONTyped(json, false);
}

export function FindPlansJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): FindPlansJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'periodOfStudy': !exists(json, 'periodOfStudy') ? undefined : json['periodOfStudy'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
        'planFilters': !exists(json, 'planFilters') ? undefined : json['planFilters'],
    };
}

export function FindPlansJobStepToJSON(value?: FindPlansJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'periodOfStudy': value.periodOfStudy,
        'academicTerm': value.academicTerm,
        'planFilters': value.planFilters,
    };
}

