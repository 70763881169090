/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OptionCohortSettings } from './OptionCohortSettings';
import {
    OptionCohortSettingsFromJSON,
    OptionCohortSettingsFromJSONTyped,
    OptionCohortSettingsToJSON,
} from './OptionCohortSettings';
import type { OptionEmbedded } from './OptionEmbedded';
import {
    OptionEmbeddedFromJSON,
    OptionEmbeddedFromJSONTyped,
    OptionEmbeddedToJSON,
} from './OptionEmbedded';
import type { StatementTemplate } from './StatementTemplate';
import {
    StatementTemplateFromJSON,
    StatementTemplateFromJSONTyped,
    StatementTemplateToJSON,
} from './StatementTemplate';

/**
 * 
 * @export
 * @interface Option
 */
export interface Option {
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    readonly id?: string;
    /**
     * The resource slug. Defaults to resource ID. Must be unique across resources of the same type.
     * @type {string}
     * @memberof Option
     */
    slug?: string;
    /**
     * The elective resource ID.
     * @type {string}
     * @memberof Option
     */
    electiveId: string;
    /**
     * The parent option resource ID.
     * @type {string}
     * @memberof Option
     */
    parentId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    fullName: string;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    shortName?: string;
    /**
     * The discipline resource ID.
     * @type {string}
     * @memberof Option
     */
    disciplineId?: string;
    /**
     * 
     * @type {OptionCohortSettings}
     * @memberof Option
     */
    cohort: OptionCohortSettings;
    /**
     * 
     * @type {string}
     * @memberof Option
     */
    description?: string;
    /**
     * Poster file ID.
     * @type {string}
     * @memberof Option
     */
    posterId?: string;
    /**
     * Supported services (**https only**):
     * - disk.sevsu.ru
     * - Youtube
     * @type {string}
     * @memberof Option
     */
    videoUrl?: string;
    /**
     * Only *.sevsu.ru domains are supported (**https only**).
     * @type {string}
     * @memberof Option
     */
    detailsUrl?: string;
    /**
     * If true - mass decision jobs would be able to fill this option with forced choices.
     * @type {boolean}
     * @memberof Option
     */
    isFillingAllowed?: boolean;
    /**
     * The number of places available for the option.
     * 
     * Calculated as `cohort.maxCount * cohort.maxSize`.
     * @type {number}
     * @memberof Option
     */
    readonly capacity?: number;
    /**
     * The number of places occupied for the option.
     * @type {number}
     * @memberof Option
     */
    readonly occupied?: number;
    /**
     * List of Tag identifiers.
     * @type {Array<string>}
     * @memberof Option
     */
    tagIds?: Array<string>;
    /**
     * A list of filters against User resource.
     * Option is availabe to the user if any filter matches.
     * @type {Array<string>}
     * @memberof Option
     */
    userRequisites?: Array<string>;
    /**
     * Are requisites satisfied by the current user.
     * Unless they are – user can not pick a given option.
     * @type {boolean}
     * @memberof Option
     */
    requisitesSatisfied?: boolean;
    /**
     * If false and this option is chosen as primary – user can not select any backups.
     * @type {boolean}
     * @memberof Option
     */
    isBackupAllowed?: boolean;
    /**
     * 
     * @type {StatementTemplate}
     * @memberof Option
     */
    statementTemplate?: StatementTemplate | null;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    subOptionsMinimum?: number;
    /**
     * 
     * @type {number}
     * @memberof Option
     */
    subOptionsMaximum?: number;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof Option
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof Option
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {OptionEmbedded}
     * @memberof Option
     */
    embedded?: OptionEmbedded;
}

/**
 * Check if a given object implements the Option interface.
 */
export function instanceOfOption(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "fullName" in value;
    isInstance = isInstance && "cohort" in value;

    return isInstance;
}

export function OptionFromJSON(json: any): Option {
    return OptionFromJSONTyped(json, false);
}

export function OptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Option {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'electiveId': json['electiveId'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'fullName': json['fullName'],
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'disciplineId': !exists(json, 'disciplineId') ? undefined : json['disciplineId'],
        'cohort': OptionCohortSettingsFromJSON(json['cohort']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'posterId': !exists(json, 'posterId') ? undefined : json['posterId'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'detailsUrl': !exists(json, 'detailsUrl') ? undefined : json['detailsUrl'],
        'isFillingAllowed': !exists(json, 'isFillingAllowed') ? undefined : json['isFillingAllowed'],
        'capacity': !exists(json, 'capacity') ? undefined : json['capacity'],
        'occupied': !exists(json, 'occupied') ? undefined : json['occupied'],
        'tagIds': !exists(json, 'tagIds') ? undefined : json['tagIds'],
        'userRequisites': !exists(json, 'userRequisites') ? undefined : json['userRequisites'],
        'requisitesSatisfied': !exists(json, 'requisitesSatisfied') ? undefined : json['requisitesSatisfied'],
        'isBackupAllowed': !exists(json, 'isBackupAllowed') ? undefined : json['isBackupAllowed'],
        'statementTemplate': !exists(json, 'statementTemplate') ? undefined : StatementTemplateFromJSON(json['statementTemplate']),
        'subOptionsMinimum': !exists(json, 'subOptionsMinimum') ? undefined : json['subOptionsMinimum'],
        'subOptionsMaximum': !exists(json, 'subOptionsMaximum') ? undefined : json['subOptionsMaximum'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : OptionEmbeddedFromJSON(json['_embedded']),
    };
}

export function OptionToJSON(value?: Option | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'slug': value.slug,
        'electiveId': value.electiveId,
        'parentId': value.parentId,
        'fullName': value.fullName,
        'shortName': value.shortName,
        'disciplineId': value.disciplineId,
        'cohort': OptionCohortSettingsToJSON(value.cohort),
        'description': value.description,
        'posterId': value.posterId,
        'videoUrl': value.videoUrl,
        'detailsUrl': value.detailsUrl,
        'isFillingAllowed': value.isFillingAllowed,
        'tagIds': value.tagIds,
        'userRequisites': value.userRequisites,
        'requisitesSatisfied': value.requisitesSatisfied,
        'isBackupAllowed': value.isBackupAllowed,
        'statementTemplate': StatementTemplateToJSON(value.statementTemplate),
        'subOptionsMinimum': value.subOptionsMinimum,
        'subOptionsMaximum': value.subOptionsMaximum,
        '_embedded': OptionEmbeddedToJSON(value.embedded),
    };
}

