/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UserCohort } from './UserCohort';
import {
    UserCohortFromJSON,
    UserCohortFromJSONTyped,
    UserCohortToJSON,
} from './UserCohort';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface CalendarEventRegistrationEmbeddedRegisteredByEmbedded
 */
export interface CalendarEventRegistrationEmbeddedRegisteredByEmbedded {
    /**
     * 
     * @type {Array<UserCohort>}
     * @memberof CalendarEventRegistrationEmbeddedRegisteredByEmbedded
     */
    cohorts?: Array<UserCohort>;
}

/**
 * Check if a given object implements the CalendarEventRegistrationEmbeddedRegisteredByEmbedded interface.
 */
export function instanceOfCalendarEventRegistrationEmbeddedRegisteredByEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSON(json: any): CalendarEventRegistrationEmbeddedRegisteredByEmbedded {
    return CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSONTyped(json, false);
}

export function CalendarEventRegistrationEmbeddedRegisteredByEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventRegistrationEmbeddedRegisteredByEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cohorts': !exists(json, 'cohorts') ? undefined : ((json['cohorts'] as Array<any>).map(UserCohortFromJSON)),
    };
}

export function CalendarEventRegistrationEmbeddedRegisteredByEmbeddedToJSON(value?: CalendarEventRegistrationEmbeddedRegisteredByEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cohorts': value.cohorts === undefined ? undefined : ((value.cohorts as Array<any>).map(UserCohortToJSON)),
    };
}

