/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ElectiveType = {
    Section: 'section',
    Discipline: 'discipline',
    Facultative: 'facultative',
    Leveled: 'leveled',
    Unrestricted: 'unrestricted'
} as const;
export type ElectiveType = typeof ElectiveType[keyof typeof ElectiveType];


export function ElectiveTypeFromJSON(json: any): ElectiveType {
    return ElectiveTypeFromJSONTyped(json, false);
}

export function ElectiveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveType {
    return json as ElectiveType;
}

export function ElectiveTypeToJSON(value?: ElectiveType | null): any {
    return value as any;
}

