import { acceptHMRUpdate, defineStore } from "pinia";
import { Tag } from "@/iot";

export const useTag = defineStore({
  id: "tag",
  state: (): {
    tags: null | Array<Tag>;
  } => ({
    tags: null,
  }),
  actions: {
    async load() {
      if (this.tags !== null) {
        return;
      }
      this.tags = await this.$api.tag.getTagCollection({
        limit: 1000,
      });
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useTag, import.meta.hot));
}
