/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CalendarElective } from './CalendarElective';
import {
    CalendarElectiveFromJSON,
    CalendarElectiveFromJSONTyped,
    CalendarElectiveToJSON,
} from './CalendarElective';
import type { CalendarEventRegistration } from './CalendarEventRegistration';
import {
    CalendarEventRegistrationFromJSON,
    CalendarEventRegistrationFromJSONTyped,
    CalendarEventRegistrationToJSON,
} from './CalendarEventRegistration';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { Organizer } from './Organizer';
import {
    OrganizerFromJSON,
    OrganizerFromJSONTyped,
    OrganizerToJSON,
} from './Organizer';

/**
 * 
 * @export
 * @interface CalendarEventEmbedded
 */
export interface CalendarEventEmbedded {
    /**
     * 
     * @type {CalendarElective}
     * @memberof CalendarEventEmbedded
     */
    elective?: CalendarElective;
    /**
     * 
     * @type {Option}
     * @memberof CalendarEventEmbedded
     */
    option?: Option;
    /**
     * 
     * @type {Location}
     * @memberof CalendarEventEmbedded
     */
    location?: Location;
    /**
     * 
     * @type {Array<Organizer>}
     * @memberof CalendarEventEmbedded
     */
    organizers?: Array<Organizer>;
    /**
     * 
     * @type {CalendarEventRegistration}
     * @memberof CalendarEventEmbedded
     */
    registration?: CalendarEventRegistration;
}

/**
 * Check if a given object implements the CalendarEventEmbedded interface.
 */
export function instanceOfCalendarEventEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function CalendarEventEmbeddedFromJSON(json: any): CalendarEventEmbedded {
    return CalendarEventEmbeddedFromJSONTyped(json, false);
}

export function CalendarEventEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elective': !exists(json, 'elective') ? undefined : CalendarElectiveFromJSON(json['elective']),
        'option': !exists(json, 'option') ? undefined : OptionFromJSON(json['option']),
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'organizers': !exists(json, 'organizers') ? undefined : ((json['organizers'] as Array<any>).map(OrganizerFromJSON)),
        'registration': !exists(json, 'registration') ? undefined : CalendarEventRegistrationFromJSON(json['registration']),
    };
}

export function CalendarEventEmbeddedToJSON(value?: CalendarEventEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elective': CalendarElectiveToJSON(value.elective),
        'option': OptionToJSON(value.option),
        'location': LocationToJSON(value.location),
        'organizers': value.organizers === undefined ? undefined : ((value.organizers as Array<any>).map(OrganizerToJSON)),
        'registration': CalendarEventRegistrationToJSON(value.registration),
    };
}

