/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChooseElectiveOptionsStatementAllOfChoices
 */
export interface ChooseElectiveOptionsStatementAllOfChoices {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ChooseElectiveOptionsStatementAllOfChoices
     */
    electiveId: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ChooseElectiveOptionsStatementAllOfChoices
     */
    optionIds?: Array<string>;
}

/**
 * Check if a given object implements the ChooseElectiveOptionsStatementAllOfChoices interface.
 */
export function instanceOfChooseElectiveOptionsStatementAllOfChoices(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;

    return isInstance;
}

export function ChooseElectiveOptionsStatementAllOfChoicesFromJSON(json: any): ChooseElectiveOptionsStatementAllOfChoices {
    return ChooseElectiveOptionsStatementAllOfChoicesFromJSONTyped(json, false);
}

export function ChooseElectiveOptionsStatementAllOfChoicesFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChooseElectiveOptionsStatementAllOfChoices {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'electiveId': json['electiveId'],
        'optionIds': !exists(json, 'optionIds') ? undefined : json['optionIds'],
    };
}

export function ChooseElectiveOptionsStatementAllOfChoicesToJSON(value?: ChooseElectiveOptionsStatementAllOfChoices | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'optionIds': value.optionIds,
    };
}

