/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Option } from './Option';
import {
    OptionFromJSON,
    OptionFromJSONTyped,
    OptionToJSON,
} from './Option';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface ChoiceEmbedded
 */
export interface ChoiceEmbedded {
    /**
     * 
     * @type {User}
     * @memberof ChoiceEmbedded
     */
    user?: User;
    /**
     * 
     * @type {Array<Option>}
     * @memberof ChoiceEmbedded
     */
    primaryOptions?: Array<Option>;
    /**
     * 
     * @type {Array<Option>}
     * @memberof ChoiceEmbedded
     */
    backupOptions?: Array<Option>;
}

/**
 * Check if a given object implements the ChoiceEmbedded interface.
 */
export function instanceOfChoiceEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ChoiceEmbeddedFromJSON(json: any): ChoiceEmbedded {
    return ChoiceEmbeddedFromJSONTyped(json, false);
}

export function ChoiceEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChoiceEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'primaryOptions': !exists(json, 'primaryOptions') ? undefined : ((json['primaryOptions'] as Array<any>).map(OptionFromJSON)),
        'backupOptions': !exists(json, 'backupOptions') ? undefined : ((json['backupOptions'] as Array<any>).map(OptionFromJSON)),
    };
}

export function ChoiceEmbeddedToJSON(value?: ChoiceEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'primaryOptions': value.primaryOptions === undefined ? undefined : ((value.primaryOptions as Array<any>).map(OptionToJSON)),
        'backupOptions': value.backupOptions === undefined ? undefined : ((value.backupOptions as Array<any>).map(OptionToJSON)),
    };
}

