import { Duration } from "date-fns";
import { ru } from "date-fns/locale";
import { sub } from "date-fns/sub";
import { format as fnsFormat } from "date-fns/format";

const ISO_PERIOD =
  /^([-+])?P(?:([-+]?[0-9,.]*)Y)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)W)?(?:([-+]?[0-9,.]*)D)?(?:T(?:([-+]?[0-9,.]*)H)?(?:([-+]?[0-9,.]*)M)?(?:([-+]?[0-9,.]*)S)?)?$/;

export type SignedDuration = Duration & { sign: "-" | "+" };

export function format(
  date: Date | number,
  format: string,
  options?: {
    locale?: Locale;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: number;
    useAdditionalWeekYearTokens?: boolean;
    useAdditionalDayOfYearTokens?: boolean;
  },
): string {
  options = {
    locale: ru,
    ...(options ?? {}),
  };
  return fnsFormat(date, format, options);
}

export function parseIsoDuration(isoDuration: string): SignedDuration {
  isoDuration = isoDuration.trim();
  const matches = ISO_PERIOD.exec(isoDuration);
  if (!matches || isoDuration.length < 3) {
    throw new TypeError(
      `duration invalid: "${isoDuration}". Must be a ISO 8601 duration. See https://en.wikipedia.org/wiki/ISO_8601#Durations`,
    );
  }
  const [, sign, years, months, weeks, days, hours, minutes, seconds] = matches;

  return {
    sign,
    years: Number.parseInt(years ?? 0),
    months: Number.parseInt(months ?? 0),
    weeks: Number.parseInt(weeks ?? 0),
    days: Number.parseInt(days ?? 0),
    hours: Number.parseInt(hours ?? 0),
    minutes: Number.parseInt(minutes ?? 0),
    seconds: Number.parseInt(seconds ?? 0),
  };
}

export const offsetIsoDuration = (date: Date, isoDuration: string): Date =>
  sub(date, parseIsoDuration(isoDuration));

export function weekNumber(date: Date): number {
  const dateCopy = new Date(date.getTime());
  dateCopy.setHours(0, 0, 0, 0);
  dateCopy.setDate(dateCopy.getDate() + 3 - ((dateCopy.getDay() + 6) % 7));
  const week1 = new Date(
    dateCopy.getFullYear() - (dateCopy.getMonth() < 8 ? 1 : 0),
    8,
    1,
  );
  if (week1.getDay() === 0) {
    week1.setDate(2);
  }

  return (
    1 +
    Math.round(
      ((dateCopy.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )
  );
}

export { formatRFC3339 } from "date-fns";
