/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ProcessElectiveConsolidationJob
 */
export interface ProcessElectiveConsolidationJob {
    /**
     * 
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    readonly userId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    type: ProcessElectiveConsolidationJobTypeEnum;
    /**
     * If the job should only return a preview or perform an action.
     * @type {boolean}
     * @memberof ProcessElectiveConsolidationJob
     */
    dryRun: boolean;
    /**
     * Reuse pre-calculations from parent job if passed.
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    readonly status?: ProcessElectiveConsolidationJobStatusEnum;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof ProcessElectiveConsolidationJob
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof ProcessElectiveConsolidationJob
     */
    readonly updatedAt?: Date;
    /**
     * Consolidation resource ID.
     * @type {string}
     * @memberof ProcessElectiveConsolidationJob
     */
    consolidationId: string;
}


/**
 * @export
 */
export const ProcessElectiveConsolidationJobTypeEnum = {
    CreateElectiveFromDiscipline: 'create-elective-from-discipline',
    CreateElectiveFromPlans: 'create-elective-from-plans',
    CreateElectiveGroups: 'create-elective-groups',
    CreateElectiveLimitation: 'create-elective-limitation',
    DistributeCohortElectiveConsolidation: 'distribute-cohort-elective-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillElectiveConsolidation: 'fill-elective-consolidation',
    ProcessElectiveConsolidation: 'process-elective-consolidation',
    SyncElectiveConsolidationBack: 'sync-elective-consolidation-back'
} as const;
export type ProcessElectiveConsolidationJobTypeEnum = typeof ProcessElectiveConsolidationJobTypeEnum[keyof typeof ProcessElectiveConsolidationJobTypeEnum];

/**
 * @export
 */
export const ProcessElectiveConsolidationJobStatusEnum = {
    Queued: 'queued',
    Processing: 'processing',
    Failed: 'failed',
    Completed: 'completed'
} as const;
export type ProcessElectiveConsolidationJobStatusEnum = typeof ProcessElectiveConsolidationJobStatusEnum[keyof typeof ProcessElectiveConsolidationJobStatusEnum];


/**
 * Check if a given object implements the ProcessElectiveConsolidationJob interface.
 */
export function instanceOfProcessElectiveConsolidationJob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "dryRun" in value;
    isInstance = isInstance && "consolidationId" in value;

    return isInstance;
}

export function ProcessElectiveConsolidationJobFromJSON(json: any): ProcessElectiveConsolidationJob {
    return ProcessElectiveConsolidationJobFromJSONTyped(json, false);
}

export function ProcessElectiveConsolidationJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProcessElectiveConsolidationJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'type': json['type'],
        'dryRun': json['dryRun'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'consolidationId': json['consolidationId'],
    };
}

export function ProcessElectiveConsolidationJobToJSON(value?: ProcessElectiveConsolidationJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'dryRun': value.dryRun,
        'parentId': value.parentId,
        'consolidationId': value.consolidationId,
    };
}

