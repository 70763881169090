/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  PostStatementAcceptRequest,
  PostStatementRejectRequest,
  Statement,
} from '../models';
import {
    PostStatementAcceptRequestFromJSON,
    PostStatementAcceptRequestToJSON,
    PostStatementRejectRequestFromJSON,
    PostStatementRejectRequestToJSON,
    StatementFromJSON,
    StatementToJSON,
} from '../models';

export interface DeleteStatementRequest {
    id: string;
}

export interface GetStatementRequest {
    id: string;
    expand?: string;
}

export interface GetStatementCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface PostStatementRequest {
    statement?: Statement;
}

export interface PostStatementAcceptOperationRequest {
    id: string;
    postStatementAcceptRequest?: PostStatementAcceptRequest;
}

export interface PostStatementRejectOperationRequest {
    id: string;
    postStatementRejectRequest?: PostStatementRejectRequest;
}

/**
 * 
 */
export class StatementApi extends runtime.BaseAPI {

    /**
     * Withdraw statement
     */
    async deleteStatementRaw(requestParameters: DeleteStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteStatement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementFromJSON(jsonValue));
    }

    /**
     * Withdraw statement
     */
    async deleteStatement(requestParameters: DeleteStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statement> {
        const response = await this.deleteStatementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get statement
     */
    async getStatementRaw(requestParameters: GetStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getStatement.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statements/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementFromJSON(jsonValue));
    }

    /**
     * Get statement
     */
    async getStatement(requestParameters: GetStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statement> {
        const response = await this.getStatementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve statements collection. If scope `statement:admin` not present - only resources availabe to user are sent. 
     * Browse statement
     */
    async getStatementCollectionRaw(requestParameters: GetStatementCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Statement>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/statements`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(StatementFromJSON));
    }

    /**
     * Retrieve statements collection. If scope `statement:admin` not present - only resources availabe to user are sent. 
     * Browse statement
     */
    async getStatementCollection(requestParameters: GetStatementCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Statement>> {
        const response = await this.getStatementCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create statement
     */
    async postStatementRaw(requestParameters: PostStatementRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statement>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/statements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StatementToJSON(requestParameters.statement),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementFromJSON(jsonValue));
    }

    /**
     * Create statement
     */
    async postStatement(requestParameters: PostStatementRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statement> {
        const response = await this.postStatementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Accept statement
     */
    async postStatementAcceptRaw(requestParameters: PostStatementAcceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postStatementAccept.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/statements/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostStatementAcceptRequestToJSON(requestParameters.postStatementAcceptRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementFromJSON(jsonValue));
    }

    /**
     * Accept statement
     */
    async postStatementAccept(requestParameters: PostStatementAcceptOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statement> {
        const response = await this.postStatementAcceptRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject statement
     */
    async postStatementRejectRaw(requestParameters: PostStatementRejectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Statement>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postStatementReject.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/statements/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostStatementRejectRequestToJSON(requestParameters.postStatementRejectRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatementFromJSON(jsonValue));
    }

    /**
     * Reject statement
     */
    async postStatementReject(requestParameters: PostStatementRejectOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Statement> {
        const response = await this.postStatementRejectRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
