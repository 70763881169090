import { acceptHMRUpdate, defineStore } from "pinia";
import {
  ResponseError,
  OptionCohortSettings,
  Option,
  Elective,
  StatementTemplate,
} from "@/iot";
import { Filter } from "@/stores/common";
import { useToast } from "vue-toastification";
import { useElective } from "@/stores/elective";
import { cloneDeep } from "lodash";
import { parseFilters } from "@/util";
import { stringify } from "csv/browser/esm/sync";

type OptionForm = {
  id?: string;
  slug?: string;
  electiveId: string;
  fullName: string;
  shortName?: string;
  disciplineId?: string;
  cohort: OptionCohortSettings;
  description?: string;
  posterId?: string;
  videoUrl?: string;
  detailsUrl?: string;
  isFillingAllowed: boolean;
  isBackupAllowed: boolean;
  statementTemplate?: StatementTemplate;
  subOptionsMinimum?: number;
  subOptionsMaximum?: number;
  tagIds: Array<string>;
  userRequisites?: Array<Filter>;
};

const emptyOption = (elective: Elective): OptionForm =>
  ({
    electiveId: elective.id as string,
    cohort: {
      name: "Уч.гр. {{short_name}}/б-{{y_start}}-{{number}}-о",
      minSize: 15,
      maxSize: 30,
      maxCount: 2,
    },
    userRequisites: [{}],
    tagIds: [] as string[],
    isFillingAllowed: true,
    isBackupAllowed: true,
    subOptionsMinimum: 0,
    subOptionsMaximum: 0,
  }) as OptionForm;

export type OptionState = {
  isShown: boolean;
  isSaving: boolean;
  form: OptionForm | null;
  saveErrors: { message: string; errors: object } | null;
};

function buildForm(option: Option): OptionForm {
  return cloneDeep({
    ...option,
    isFillingAllowed: option.isFillingAllowed ?? true,
    isBackupAllowed: option.isBackupAllowed ?? true,
    tagIds: option.tagIds ?? [],
    statementTemplate: option.statementTemplate ?? undefined,
    userRequisites: parseFilters(option.userRequisites ?? []),
  });
}

export const useOption = defineStore({
  id: "option",
  state: () =>
    ({
      isShown: false,
      isSaving: false,
      form: null,
      saveErrors: null,
    }) as OptionState,
  getters: {
    userRequisiteStrings({ form }): Array<string> {
      return (
        form?.userRequisites
          ?.map((filterObject) =>
            Object.keys(filterObject)
              .map(
                (field) =>
                  `${field}:${stringify([filterObject[field]], {
                    quote: true,
                  }).trim()}`,
              )
              .join(";"),
          )
          ?.filter((filterString) => filterString.length > 0) ?? []
      );
    },
  },
  actions: {
    openCreate(elective: Elective) {
      this.form = emptyOption(elective);
      this.isShown = true;
    },
    openEdit(option: Option) {
      this.form = buildForm(option);
      this.isShown = true;
    },
    async saveForm() {
      if (!this.form) {
        this.closeForm();
        return;
      }
      const toast = useToast();
      this.isSaving = true;
      try {
        this.form.id
          ? await this.$api.option.patchOption({
              id: this.form.id,
              option: {
                ...this.form,
                userRequisites: this.userRequisiteStrings,
              },
            })
          : await this.$api.option.postOption({
              option: {
                ...this.form,
                userRequisites: this.userRequisiteStrings,
              },
            });
        toast.success("Дисциплина успешно сохранена");
        this.closeForm();
        // noinspection ES6MissingAwait
        useElective().load(true);
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
        }
        toast.error(
          this.saveErrors?.message || "Не удалось сохранить дисциплину",
        );
      } finally {
        this.isSaving = false;
      }
    },
    closeForm() {
      this.form = null;
      this.isShown = false;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useOption, import.meta.hot));
}
