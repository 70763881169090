/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Elective } from './Elective';
import {
    ElectiveFromJSON,
    ElectiveFromJSONTyped,
    ElectiveToJSON,
} from './Elective';

/**
 * Embedded resources. To load specific embedded provide `?expand={resourceKey}` query param.
 * @export
 * @interface ElectiveChoiceChangesReportInnerEmbedded
 */
export interface ElectiveChoiceChangesReportInnerEmbedded {
    /**
     * 
     * @type {Elective}
     * @memberof ElectiveChoiceChangesReportInnerEmbedded
     */
    elective?: Elective;
}

/**
 * Check if a given object implements the ElectiveChoiceChangesReportInnerEmbedded interface.
 */
export function instanceOfElectiveChoiceChangesReportInnerEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ElectiveChoiceChangesReportInnerEmbeddedFromJSON(json: any): ElectiveChoiceChangesReportInnerEmbedded {
    return ElectiveChoiceChangesReportInnerEmbeddedFromJSONTyped(json, false);
}

export function ElectiveChoiceChangesReportInnerEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveChoiceChangesReportInnerEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'elective': !exists(json, 'elective') ? undefined : ElectiveFromJSON(json['elective']),
    };
}

export function ElectiveChoiceChangesReportInnerEmbeddedToJSON(value?: ElectiveChoiceChangesReportInnerEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'elective': ElectiveToJSON(value.elective),
    };
}

