export function buildEnhancedFilter<TValue>(
  filters: Array<string>,
  optionsKey: keyof TValue,
): (row: { key: string; value: TValue }) => boolean {
  type TRow = { key: string; value: TValue };
  if (!filters.length) {
    return () => true;
  }
  const callbacks: Array<(row: TRow) => boolean> = [];
  if (filters.includes("!empty")) {
    callbacks.push((row: TRow) => !!row.value[optionsKey]?.length);
  }
  if (filters.length > 0) {
    callbacks.push((row: TRow) =>
      Array.isArray(row.value[optionsKey])
        ? row.value[optionsKey].some((val) => filters.includes(val))
        : filters.includes(row.value[optionsKey] || ""),
    );
  }
  return (row: TRow) =>
    callbacks.reduce((result, filter) => result || filter(row), false);
}

export function debounce<T extends (...args: unknown[]) => void>(
  fn: T,
  delay: number,
): T {
  let timeoutID: number | null = null;

  return function (this: unknown, ...args: unknown[]) {
    clearTimeout(timeoutID);
    timeoutID = setTimeout(() => {
      fn.apply(this, args);
    }, delay);
  } as T;
}
