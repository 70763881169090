/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PrepareSectionOptionsJobStep
 */
export interface PrepareSectionOptionsJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof PrepareSectionOptionsJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PrepareSectionOptionsJobStep
     */
    type?: PrepareSectionOptionsJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PrepareSectionOptionsJobStep
     */
    status?: PrepareSectionOptionsJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof PrepareSectionOptionsJobStep
     */
    progress?: number;
    /**
     * 
     * @type {string}
     * @memberof PrepareSectionOptionsJobStep
     */
    planSection?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PrepareSectionOptionsJobStep
     */
    partialIntersectionAllowed?: boolean;
}


/**
 * @export
 */
export const PrepareSectionOptionsJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type PrepareSectionOptionsJobStepTypeEnum = typeof PrepareSectionOptionsJobStepTypeEnum[keyof typeof PrepareSectionOptionsJobStepTypeEnum];

/**
 * @export
 */
export const PrepareSectionOptionsJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type PrepareSectionOptionsJobStepStatusEnum = typeof PrepareSectionOptionsJobStepStatusEnum[keyof typeof PrepareSectionOptionsJobStepStatusEnum];


/**
 * Check if a given object implements the PrepareSectionOptionsJobStep interface.
 */
export function instanceOfPrepareSectionOptionsJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PrepareSectionOptionsJobStepFromJSON(json: any): PrepareSectionOptionsJobStep {
    return PrepareSectionOptionsJobStepFromJSONTyped(json, false);
}

export function PrepareSectionOptionsJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): PrepareSectionOptionsJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'planSection': !exists(json, 'planSection') ? undefined : json['planSection'],
        'partialIntersectionAllowed': !exists(json, 'partialIntersectionAllowed') ? undefined : json['partialIntersectionAllowed'],
    };
}

export function PrepareSectionOptionsJobStepToJSON(value?: PrepareSectionOptionsJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'planSection': value.planSection,
        'partialIntersectionAllowed': value.partialIntersectionAllowed,
    };
}

