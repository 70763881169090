/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeleteCalendarEventRequest
 */
export interface DeleteCalendarEventRequest {
    /**
     * 
     * @type {string}
     * @memberof DeleteCalendarEventRequest
     */
    cancellationReason: string;
    /**
     * 
     * @type {boolean}
     * @memberof DeleteCalendarEventRequest
     */
    force?: boolean;
}

/**
 * Check if a given object implements the DeleteCalendarEventRequest interface.
 */
export function instanceOfDeleteCalendarEventRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "cancellationReason" in value;

    return isInstance;
}

export function DeleteCalendarEventRequestFromJSON(json: any): DeleteCalendarEventRequest {
    return DeleteCalendarEventRequestFromJSONTyped(json, false);
}

export function DeleteCalendarEventRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeleteCalendarEventRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'cancellationReason': json['cancellationReason'],
        'force': !exists(json, 'force') ? undefined : json['force'],
    };
}

export function DeleteCalendarEventRequestToJSON(value?: DeleteCalendarEventRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'cancellationReason': value.cancellationReason,
        'force': value.force,
    };
}

