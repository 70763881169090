/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarEventRegistrationEmbeddedAbsentProofFile
 */
export interface CalendarEventRegistrationEmbeddedAbsentProofFile {
    /**
     * 
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly id?: string;
    /**
     * Original file name.
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    name: string;
    /**
     * The file extension.
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    extension: string;
    /**
     * File contents in binary format, see `multipart/form-data`.
     * @type {Blob}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    file: Blob;
    /**
     * The file description.
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    description?: string;
    /**
     * Is the file available publicly (without authentication). If true, the view link does not require authentication.
     * @type {boolean}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    isPublic?: boolean;
    /**
     * The mime type.
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly mime?: CalendarEventRegistrationEmbeddedAbsentProofFileMimeEnum;
    /**
     * The file size in bytes.
     * @type {number}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly size?: number;
    /**
     * Image width, applicable to images only.
     * @type {number}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly width?: number;
    /**
     * Image height, applicable to images only.
     * @type {number}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly height?: number;
    /**
     * Hash sum of the file.
     * @type {string}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly sha1?: string;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof CalendarEventRegistrationEmbeddedAbsentProofFile
     */
    readonly updatedAt?: Date;
}


/**
 * @export
 */
export const CalendarEventRegistrationEmbeddedAbsentProofFileMimeEnum = {
    ImagePng: 'image/png',
    ImageJpeg: 'image/jpeg',
    ImageGif: 'image/gif',
    ApplicationPdf: 'application/pdf',
    AudioMpeg: 'audio/mpeg'
} as const;
export type CalendarEventRegistrationEmbeddedAbsentProofFileMimeEnum = typeof CalendarEventRegistrationEmbeddedAbsentProofFileMimeEnum[keyof typeof CalendarEventRegistrationEmbeddedAbsentProofFileMimeEnum];


/**
 * Check if a given object implements the CalendarEventRegistrationEmbeddedAbsentProofFile interface.
 */
export function instanceOfCalendarEventRegistrationEmbeddedAbsentProofFile(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "extension" in value;
    isInstance = isInstance && "file" in value;

    return isInstance;
}

export function CalendarEventRegistrationEmbeddedAbsentProofFileFromJSON(json: any): CalendarEventRegistrationEmbeddedAbsentProofFile {
    return CalendarEventRegistrationEmbeddedAbsentProofFileFromJSONTyped(json, false);
}

export function CalendarEventRegistrationEmbeddedAbsentProofFileFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarEventRegistrationEmbeddedAbsentProofFile {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'extension': json['extension'],
        'file': json['file'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isPublic': !exists(json, 'isPublic') ? undefined : json['isPublic'],
        'mime': !exists(json, 'mime') ? undefined : json['mime'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'width': !exists(json, 'width') ? undefined : json['width'],
        'height': !exists(json, 'height') ? undefined : json['height'],
        'sha1': !exists(json, 'sha1') ? undefined : json['sha1'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
    };
}

export function CalendarEventRegistrationEmbeddedAbsentProofFileToJSON(value?: CalendarEventRegistrationEmbeddedAbsentProofFile | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'extension': value.extension,
        'file': value.file,
        'description': value.description,
        'isPublic': value.isPublic,
    };
}

