/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Discipline } from './Discipline';
import {
    DisciplineFromJSON,
    DisciplineFromJSONTyped,
    DisciplineToJSON,
} from './Discipline';
import type { PlanDisciplineParameters } from './PlanDisciplineParameters';
import {
    PlanDisciplineParametersFromJSON,
    PlanDisciplineParametersFromJSONTyped,
    PlanDisciplineParametersToJSON,
} from './PlanDisciplineParameters';

/**
 * 
 * @export
 * @interface PlanDiscipline
 */
export interface PlanDiscipline {
    /**
     * 
     * @type {Discipline}
     * @memberof PlanDiscipline
     */
    discipline?: Discipline;
    /**
     * 
     * @type {string}
     * @memberof PlanDiscipline
     */
    department?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PlanDiscipline
     */
    terms?: Array<number>;
    /**
     * 
     * @type {PlanDisciplineParameters}
     * @memberof PlanDiscipline
     */
    parameters?: PlanDisciplineParameters;
}

/**
 * Check if a given object implements the PlanDiscipline interface.
 */
export function instanceOfPlanDiscipline(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PlanDisciplineFromJSON(json: any): PlanDiscipline {
    return PlanDisciplineFromJSONTyped(json, false);
}

export function PlanDisciplineFromJSONTyped(json: any, ignoreDiscriminator: boolean): PlanDiscipline {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'discipline': !exists(json, 'discipline') ? undefined : DisciplineFromJSON(json['discipline']),
        'department': !exists(json, 'department') ? undefined : json['department'],
        'terms': !exists(json, 'terms') ? undefined : json['terms'],
        'parameters': !exists(json, 'parameters') ? undefined : PlanDisciplineParametersFromJSON(json['parameters']),
    };
}

export function PlanDisciplineToJSON(value?: PlanDiscipline | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'discipline': DisciplineToJSON(value.discipline),
        'department': value.department,
        'terms': value.terms,
        'parameters': PlanDisciplineParametersToJSON(value.parameters),
    };
}

