import { acceptHMRUpdate, defineStore } from "pinia";
import {
  Consolidation,
  ConsolidationStatusEnum,
  Elective,
  ResponseError,
} from "@/iot";
import { useElective, userFullName } from "@/stores";
import router from "@/router";
import { useToast } from "vue-toastification";
import { format } from "@/utils/date";

export type ConsolidationsState = {
  isLoading: boolean;
  isCreating: boolean;
  consolidations: Consolidation[];
  filterAuthor: string;
  filterElective: string[];
  filterStatus: string[];
  filterAcademicYear: string[];
  filterAcademicTerm: string;
};

type ConsolidationTableRow = {
  key: string | number;
  value: {
    elective: Elective | null;
    author: string | null;
    revision: number;
    status: ConsolidationStatusEnum | null;
    academicYear: string | null;
    academicTerm: number | null;
    createdAt: string;
    updatedAt: string;
  };
};

export const useConsolidations = defineStore({
  id: "consolidations",
  state: () =>
    ({
      isLoading: false,
      isCreating: false,
      consolidations: [],
      filterAuthor: "",
      filterElective: [],
      filterStatus: [],
      filterAcademicYear: [],
      filterAcademicTerm: "",
    }) as ConsolidationsState,
  getters: {
    dataTable: function ({ consolidations }): Array<ConsolidationTableRow> {
      return (
        consolidations?.map((consolidation) => ({
          key: consolidation.id as string,
          value: {
            elective: consolidation.embedded?.elective || null,
            author: userFullName(consolidation.embedded?.author),
            revision: consolidation.revision,
            status: consolidation.status ?? null,
            academicYear:
              consolidation.embedded?.elective?.academicYear ?? null,
            academicTerm:
              consolidation.embedded?.elective?.academicTerm ?? null,
            createdAt: consolidation.createdAt
              ? format(consolidation.createdAt, "HH:mm dd.MM.uuuu")
              : "",
            updatedAt: consolidation.updatedAt
              ? format(consolidation.updatedAt, "HH:mm dd.MM.uuuu")
              : "",
          },
        })) || []
      );
    },
    filter: ({
      filterAuthor,
      filterElective,
      filterStatus,
      filterAcademicYear,
      filterAcademicTerm,
    }) => {
      const filters: Array<(row: ConsolidationTableRow) => boolean> = [];
      if (filterAuthor.trim() !== "") {
        const filterAuthorLower = filterAuthor.toLowerCase();
        filters.push(
          (row: ConsolidationTableRow) =>
            !row.value.author ||
            row.value.author.toLowerCase().includes(filterAuthorLower),
        );
      }
      if (filterElective.length) {
        filters.push((row: ConsolidationTableRow) =>
          filterElective.includes(row.value.elective?.id as string),
        );
      }
      if (filterStatus.length) {
        filters.push((row: ConsolidationTableRow) =>
          filterStatus.includes(row.value.status ?? ""),
        );
      }
      if (filterAcademicYear.length) {
        filters.push(
          (row: ConsolidationTableRow) =>
            !row.value.academicYear ||
            filterAcademicYear.includes(row.value.academicYear),
        );
      }
      if (filterAcademicTerm.trim() !== "") {
        filters.push(
          (row: ConsolidationTableRow) =>
            `${row.value.academicTerm}` === filterAcademicTerm,
        );
      }

      return (row: ConsolidationTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },
    filterableElectives: ({ consolidations }) => {
      const electives: { [id: string]: string } = {};
      consolidations?.forEach((consolidation) => {
        if (
          consolidation.embedded?.elective?.id &&
          consolidation.embedded?.elective?.name
        ) {
          electives[consolidation.embedded.elective.id] =
            consolidation.embedded.elective.name;
        }
      });

      return Object.keys(electives).map((electiveId) => ({
        k: electiveId,
        v: electives[electiveId],
      }));
    },
    filterableAcademicYears: ({ consolidations }) => {
      const academicYears: { [n: string]: string } = {};
      consolidations?.forEach((consolidation) => {
        const academicYear =
          consolidation.embedded?.elective?.academicYear || "";
        academicYears[academicYear] = academicYear;
      });

      return Object.values(academicYears).map((academicYear) => ({
        k: academicYear,
        v: academicYear,
      }));
    },
    finalizedConsolidations: ({ consolidations }): Consolidation[] =>
      consolidations.filter(
        (consolidation) =>
          consolidation.status === ConsolidationStatusEnum.Finalized,
      ),
    lastFinalizedConsolidation(): Consolidation | null {
      return this.finalizedConsolidations.reduce(
        (
          last: Consolidation | null,
          consolidation: Consolidation,
        ): Consolidation | null =>
          last && last.revision > consolidation.revision ? last : consolidation,
        null,
      );
    },
  },
  actions: {
    async load(elective: Elective | null) {
      this.isLoading = true;
      try {
        this.consolidations =
          await this.$api.consolidation.getConsolidationCollection({
            filter: elective ? `electiveId:${elective?.id}` : undefined,
            sort: ["-createdAt"],
            expand: "author,elective",
            limit: 10000,
          });
      } catch (e) {
        useToast().error("Не удалось загрузить закрепления");
        await router.replace(
          elective
            ? {
                name: "elective:read",
                params: { slug: elective.slug },
              }
            : { name: "home" },
        );
      } finally {
        this.isLoading = false;
      }
    },
    async create() {
      const electiveStore = useElective();
      if (!electiveStore.elective?.id) {
        return;
      }
      const toast = useToast(),
        revision = this.lastFinalizedConsolidation?.revision || 1;
      this.isCreating = true;
      try {
        const consolidation = await this.$api.consolidation.postConsolidation({
          postConsolidationRequest: {
            electiveId: electiveStore.elective.id,
            revision,
            users: [],
          },
        });
        toast.success("Закрепление успешно создано");
        await router.replace({
          name: "consolidation:read",
          params: { slug: electiveStore.slug, id: consolidation.id },
        });
      } catch (e) {
        let message = "Не удалось создать закрепление";
        if (e instanceof ResponseError) {
          message = `${message}: ${(await e.response.json()).message}`;
        }
        toast.error(message);
      } finally {
        this.isCreating = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useConsolidations, import.meta.hot));
}
