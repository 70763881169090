import { acceptHMRUpdate, defineStore } from "pinia";
import { Elective, ElectiveType, ResponseError } from "@/iot";
import { useElective, useUser } from "@/stores";
import { cloneDeep } from "lodash";
import router from "@/router";
import { useToast } from "vue-toastification";

const emptyElective = (): Elective => {
  const openedAt = new Date();
  openedAt.setMilliseconds(0);

  return {
    type: ElectiveType.Section,
    flow: {
      choiceWaves: [{ threshold: 0, openedAt }],
      primaryOptions: 1,
      backupOptions: 5,
      statementsOpenedAt: null,
      statementsClosedAt: null,
    },
    userFilters: ["foo:bar"] as string[], // TODO ???
    academicYear: "2024/2025", // TODO: auto-adjust
    academicTerm: 1, // TODO: auto-adjust
  } as Elective;
};

export type ElectivesState = {
  academicYear: string;
  academicTerm: number;
  electives: Elective[] | null;
  isLoading: boolean;
  formElective: Elective | null;
  isFormShown: boolean;
  isSaving: boolean;
  saveErrors: { message: string; errors: object } | null;
};

export const useElectives = defineStore({
  id: "electives",
  state: () =>
    ({
      academicYear: "2023/2024",
      academicTerm: 1,
      electives: null,
      isLoading: false,
      formElective: null,
      isFormShown: false,
      isSaving: false,
      saveErrors: null,
    }) as ElectivesState,
  getters: {
    allAcademicTerms({ academicTerm }) {
      return academicTerm === 1 ? [1, 3, 5, 7, 9, 11] : [2, 4, 6, 8, 10, 12];
    },
  },
  actions: {
    async setPeriod(
      academicYear: string | undefined,
      academicTerm: string | number | undefined,
    ) {
      const user = useUser();
      academicYear ??= user.defaultAcademicYear;
      academicTerm ??= user.defaultAcademicTerm;

      let hasChange = false;
      if (academicYear && academicYear !== this.academicYear) {
        this.academicYear = academicYear;
        hasChange = true;
      }
      if (academicTerm) {
        const academicTermInt =
          typeof academicTerm === "number"
            ? academicTerm
            : Number.parseInt(academicTerm);
        if (academicTermInt !== this.academicTerm) {
          this.academicTerm = academicTermInt;
          hasChange = true;
        }
      }
      if (hasChange || this.electives === null) {
        await this.load();
      }
    },
    async load() {
      this.isLoading = true;
      try {
        this.electives = await this.$api.elective.getElectiveCollection({
          expand: "options",
          filter: `academicYear:${this.academicYear};academicTerm:${this.allAcademicTerms}`,
          sort: ["-flow.choiceClosedAt"],
        });
      } catch (e) {
        useToast().error("Не удалось загрузить список блоков");
        await router.replace({ name: "home" });
      } finally {
        this.isLoading = false;
      }
    },
    openForm(elective: Elective | null = null) {
      this.isFormShown = true;
      this.formElective = cloneDeep(elective || emptyElective());
    },
    async saveForm() {
      if (!this.formElective) {
        this.closeForm();
        return;
      }
      const toast = useToast();
      this.isSaving = true;
      try {
        const electiveStore = useElective();
        let reloadElectiveStore = false;
        if (this.formElective.id) {
          const elective = await this.$api.elective.patchElective({
            id: this.formElective.id,
            patchElectiveRequest: this.formElective,
          });
          reloadElectiveStore =
            electiveStore.elective !== null &&
            electiveStore.elective.id === this.formElective.id;
          if (
            reloadElectiveStore &&
            elective.slug !== electiveStore.elective?.slug
          ) {
            await router.replace({
              name: "elective:read",
              params: { slug: elective.slug },
            });
            reloadElectiveStore = false;
          }
        } else {
          await this.$api.elective.postElective({
            elective: this.formElective,
          });
        }
        toast.success("Блок успешно сохранён");
        this.closeForm();
        // noinspection ES6MissingAwait
        this.load();
        if (reloadElectiveStore) {
          // noinspection ES6MissingAwait
          electiveStore.load(true);
        }
      } catch (e) {
        if (e instanceof ResponseError) {
          this.saveErrors = await e.response.json();
        }
        toast.error(
          this.saveErrors?.message ||
            "Не удалось сохранить блок, убедитесь, что все обязательные поля заполнены",
        );
      } finally {
        this.isSaving = false;
      }
    },
    closeForm() {
      this.isFormShown = false;
      this.saveErrors = null;
      this.formElective = null;
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useElectives, import.meta.hot));
}
