/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Choice } from './Choice';
import {
    ChoiceFromJSON,
    ChoiceFromJSONTyped,
    ChoiceToJSON,
} from './Choice';
import type { Elective } from './Elective';
import {
    ElectiveFromJSON,
    ElectiveFromJSONTyped,
    ElectiveToJSON,
} from './Elective';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './User';

/**
 * 
 * @export
 * @interface VerifyElectiveChoiceStatementAllOfEmbedded
 */
export interface VerifyElectiveChoiceStatementAllOfEmbedded {
    /**
     * 
     * @type {User}
     * @memberof VerifyElectiveChoiceStatementAllOfEmbedded
     */
    user?: User;
    /**
     * 
     * @type {Elective}
     * @memberof VerifyElectiveChoiceStatementAllOfEmbedded
     */
    elective?: Elective;
    /**
     * 
     * @type {Choice}
     * @memberof VerifyElectiveChoiceStatementAllOfEmbedded
     */
    choice?: Choice;
}

/**
 * Check if a given object implements the VerifyElectiveChoiceStatementAllOfEmbedded interface.
 */
export function instanceOfVerifyElectiveChoiceStatementAllOfEmbedded(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function VerifyElectiveChoiceStatementAllOfEmbeddedFromJSON(json: any): VerifyElectiveChoiceStatementAllOfEmbedded {
    return VerifyElectiveChoiceStatementAllOfEmbeddedFromJSONTyped(json, false);
}

export function VerifyElectiveChoiceStatementAllOfEmbeddedFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyElectiveChoiceStatementAllOfEmbedded {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'elective': !exists(json, 'elective') ? undefined : ElectiveFromJSON(json['elective']),
        'choice': !exists(json, 'choice') ? undefined : ChoiceFromJSON(json['choice']),
    };
}

export function VerifyElectiveChoiceStatementAllOfEmbeddedToJSON(value?: VerifyElectiveChoiceStatementAllOfEmbedded | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'user': UserToJSON(value.user),
        'elective': ElectiveToJSON(value.elective),
        'choice': ChoiceToJSON(value.choice),
    };
}

