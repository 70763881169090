<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      class="normal"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M 19.272 5.071 L 25.941 9.997 C 27.226 10.927 27.991 12.415 28 14.002 L 28 22.919 C 27.917 25.779 25.542 28.036 22.682 27.972 L 9.331 27.972 C 6.466 28.043 4.083 25.784 4 22.919 L 4 14.002 C 4.009 12.415 4.774 10.927 6.059 9.997 L 12.728 5.071 C 14.676 3.643 17.324 3.643 19.272 5.071 Z M 10.316 22.629 L 21.684 22.629 C 22.207 22.629 22.632 22.205 22.632 21.682 C 22.632 21.158 22.207 20.734 21.684 20.734 L 10.316 20.734 C 9.793 20.734 9.368 21.158 9.368 21.682 C 9.368 22.205 9.793 22.629 10.316 22.629 Z"
      fill="white"
    ></path>

    <path
      class="hover"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.64 3.85398L27.0533 9.33398C28.4756 10.3677 29.3219 12.0158 29.3333 13.774V23.694C29.2533 26.885 26.6045 29.4085 23.4133 29.334H8.58667C5.39554 29.4085 2.7467 26.885 2.66667 23.694V13.7873C2.67481 12.0215 3.52726 10.3662 4.96001 9.33398L12.36 3.85398C14.5285 2.2709 17.4715 2.2709 19.64 3.85398ZM23.4133 27.334C25.5001 27.409 27.2538 25.7806 27.3333 23.694V13.774C27.3265 12.6478 26.775 11.5945 25.8533 10.9473L18.4533 5.46731C16.9919 4.40008 15.0081 4.40008 13.5467 5.46731L6.14667 10.934C5.22423 11.5926 4.67379 12.6539 4.66667 13.7873V23.694C4.75337 25.7773 6.50264 27.4016 8.58667 27.334H23.4133Z"
    />
    <path
      class="hover"
      d="M22 21.0006H10C9.44772 21.0006 9 21.4484 9 22.0006C9 22.5529 9.44772 23.0006 10 23.0006H22C22.5523 23.0006 23 22.5529 23 22.0006C23 21.4484 22.5523 21.0006 22 21.0006Z"
    />
  </svg>
</template>

<style scoped>
svg {
  border-radius: 8px;
}

.normal {
  fill: var(--c-white);
}

.hover {
  fill: none;
}

.active > svg,
:hover > svg {
  background-color: var(--c-white);
}

.active > svg > .normal,
:hover > svg > .normal {
  fill: none;
}

.active > svg > .hover,
:hover > svg > .hover {
  fill: var(--c-blue);
}

@media only screen and (max-width: 991.98px) {
  .normal {
    fill: var(--c-gray-4);
  }

  .hover {
    fill: none;
  }

  .active > svg,
  :hover > svg {
    background-color: transparent;
  }

  svg > .normal,
  svg > .normal {
    fill: none;
  }

  svg > .hover,
  svg > .hover {
    fill: var(--c-gray-4);
  }

  .active > svg > .hover,
  :hover > svg > .hover {
    fill: var(--c-blue);
  }
}
</style>
