/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AbstractJobStep
 */
export interface AbstractJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof AbstractJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AbstractJobStep
     */
    type?: AbstractJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof AbstractJobStep
     */
    status?: AbstractJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof AbstractJobStep
     */
    progress?: number;
}


/**
 * @export
 */
export const AbstractJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type AbstractJobStepTypeEnum = typeof AbstractJobStepTypeEnum[keyof typeof AbstractJobStepTypeEnum];

/**
 * @export
 */
export const AbstractJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type AbstractJobStepStatusEnum = typeof AbstractJobStepStatusEnum[keyof typeof AbstractJobStepStatusEnum];


/**
 * Check if a given object implements the AbstractJobStep interface.
 */
export function instanceOfAbstractJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function AbstractJobStepFromJSON(json: any): AbstractJobStep {
    return AbstractJobStepFromJSONTyped(json, false);
}

export function AbstractJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): AbstractJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
    };
}

export function AbstractJobStepToJSON(value?: AbstractJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
    };
}

