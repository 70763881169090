/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FillLimitationJobStep
 */
export interface FillLimitationJobStep {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof FillLimitationJobStep
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FillLimitationJobStep
     */
    type?: FillLimitationJobStepTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FillLimitationJobStep
     */
    status?: FillLimitationJobStepStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof FillLimitationJobStep
     */
    progress?: number;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof FillLimitationJobStep
     */
    electiveId?: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof FillLimitationJobStep
     */
    academicTerm?: number | null;
}


/**
 * @export
 */
export const FillLimitationJobStepTypeEnum = {
    BuildElective: 'build-elective',
    CreateGroups: 'create-groups',
    CreateLimitation: 'create-limitation',
    DistributeCohortConsolidation: 'distribute-cohort-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillConsolidation: 'fill-consolidation',
    FillLimitation: 'fill-limitation',
    FindPlans: 'find-plans',
    FindSimilarSections: 'find-similar-sections',
    GenerateGroupNames: 'generate-group-names',
    LoadPlans: 'load-plans',
    PrepareSectionOptions: 'prepare-section-options',
    SyncConsolidationBack: 'sync-consolidation-back',
    SyncConsolidationUserOptions: 'sync-consolidation-user-options',
    SyncConsolidationUsersBack: 'sync-consolidation-users-back',
    ValidateConsolidation: 'validate-consolidation'
} as const;
export type FillLimitationJobStepTypeEnum = typeof FillLimitationJobStepTypeEnum[keyof typeof FillLimitationJobStepTypeEnum];

/**
 * @export
 */
export const FillLimitationJobStepStatusEnum = {
    Awaiting: 'awaiting',
    Queued: 'queued',
    Processing: 'processing',
    Completed: 'completed',
    Failed: 'failed'
} as const;
export type FillLimitationJobStepStatusEnum = typeof FillLimitationJobStepStatusEnum[keyof typeof FillLimitationJobStepStatusEnum];


/**
 * Check if a given object implements the FillLimitationJobStep interface.
 */
export function instanceOfFillLimitationJobStep(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function FillLimitationJobStepFromJSON(json: any): FillLimitationJobStep {
    return FillLimitationJobStepFromJSONTyped(json, false);
}

export function FillLimitationJobStepFromJSONTyped(json: any, ignoreDiscriminator: boolean): FillLimitationJobStep {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'progress': !exists(json, 'progress') ? undefined : json['progress'],
        'electiveId': !exists(json, 'electiveId') ? undefined : json['electiveId'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
    };
}

export function FillLimitationJobStepToJSON(value?: FillLimitationJobStep | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'status': value.status,
        'progress': value.progress,
        'electiveId': value.electiveId,
        'academicTerm': value.academicTerm,
    };
}

