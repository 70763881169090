/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OptionCohortSettings } from './OptionCohortSettings';
import {
    OptionCohortSettingsFromJSON,
    OptionCohortSettingsFromJSONTyped,
    OptionCohortSettingsToJSON,
} from './OptionCohortSettings';

/**
 * 
 * @export
 * @interface CreateElectiveFromPlansJob
 */
export interface CreateElectiveFromPlansJob {
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    readonly userId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    type: CreateElectiveFromPlansJobTypeEnum;
    /**
     * If the job should only return a preview or perform an action.
     * @type {boolean}
     * @memberof CreateElectiveFromPlansJob
     */
    dryRun: boolean;
    /**
     * Reuse pre-calculations from parent job if passed.
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    readonly status?: CreateElectiveFromPlansJobStatusEnum;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof CreateElectiveFromPlansJob
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof CreateElectiveFromPlansJob
     */
    readonly updatedAt?: Date;
    /**
     * The elective period of study.
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    periodOfStudy: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof CreateElectiveFromPlansJob
     */
    academicTerm?: number | null;
    /**
     * A list of filters against Plan integration resource.
     * Plan is used if any of the filters match.
     * @type {Array<string>}
     * @memberof CreateElectiveFromPlansJob
     */
    planFilters: Array<string>;
    /**
     * The name of the academic plan section.
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    planSection?: string | null;
    /**
     * The name of the elective to create.
     * @type {string}
     * @memberof CreateElectiveFromPlansJob
     */
    name: string;
    /**
     * Default cohort user distribution settings.
     * @type {OptionCohortSettings}
     * @memberof CreateElectiveFromPlansJob
     */
    cohort: OptionCohortSettings;
    /**
     * 
     * @type {boolean}
     * @memberof CreateElectiveFromPlansJob
     */
    partialIntersectionAllowed?: boolean;
}


/**
 * @export
 */
export const CreateElectiveFromPlansJobTypeEnum = {
    CreateElectiveFromDiscipline: 'create-elective-from-discipline',
    CreateElectiveFromPlans: 'create-elective-from-plans',
    CreateElectiveGroups: 'create-elective-groups',
    CreateElectiveLimitation: 'create-elective-limitation',
    DistributeCohortElectiveConsolidation: 'distribute-cohort-elective-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillElectiveConsolidation: 'fill-elective-consolidation',
    ProcessElectiveConsolidation: 'process-elective-consolidation',
    SyncElectiveConsolidationBack: 'sync-elective-consolidation-back'
} as const;
export type CreateElectiveFromPlansJobTypeEnum = typeof CreateElectiveFromPlansJobTypeEnum[keyof typeof CreateElectiveFromPlansJobTypeEnum];

/**
 * @export
 */
export const CreateElectiveFromPlansJobStatusEnum = {
    Queued: 'queued',
    Processing: 'processing',
    Failed: 'failed',
    Completed: 'completed'
} as const;
export type CreateElectiveFromPlansJobStatusEnum = typeof CreateElectiveFromPlansJobStatusEnum[keyof typeof CreateElectiveFromPlansJobStatusEnum];


/**
 * Check if a given object implements the CreateElectiveFromPlansJob interface.
 */
export function instanceOfCreateElectiveFromPlansJob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "dryRun" in value;
    isInstance = isInstance && "periodOfStudy" in value;
    isInstance = isInstance && "planFilters" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "cohort" in value;

    return isInstance;
}

export function CreateElectiveFromPlansJobFromJSON(json: any): CreateElectiveFromPlansJob {
    return CreateElectiveFromPlansJobFromJSONTyped(json, false);
}

export function CreateElectiveFromPlansJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateElectiveFromPlansJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'type': json['type'],
        'dryRun': json['dryRun'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'periodOfStudy': json['periodOfStudy'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
        'planFilters': json['planFilters'],
        'planSection': !exists(json, 'planSection') ? undefined : json['planSection'],
        'name': json['name'],
        'cohort': OptionCohortSettingsFromJSON(json['cohort']),
        'partialIntersectionAllowed': !exists(json, 'partialIntersectionAllowed') ? undefined : json['partialIntersectionAllowed'],
    };
}

export function CreateElectiveFromPlansJobToJSON(value?: CreateElectiveFromPlansJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'dryRun': value.dryRun,
        'parentId': value.parentId,
        'periodOfStudy': value.periodOfStudy,
        'academicTerm': value.academicTerm,
        'planFilters': value.planFilters,
        'planSection': value.planSection,
        'name': value.name,
        'cohort': OptionCohortSettingsToJSON(value.cohort),
        'partialIntersectionAllowed': value.partialIntersectionAllowed,
    };
}

