/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  JobRequest,
  JobResponse,
} from '../models';
import {
    JobRequestFromJSON,
    JobRequestToJSON,
    JobResponseFromJSON,
    JobResponseToJSON,
} from '../models';

export interface GetJobRequest {
    id: string;
    expand?: string;
}

export interface PostJobRequest {
    expand?: string;
    jobRequest?: JobRequest;
}

/**
 * 
 */
export class JobApi extends runtime.BaseAPI {

    /**
     * Get job
     */
    async getJobRaw(requestParameters: GetJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/jobs/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobResponseFromJSON(jsonValue));
    }

    /**
     * Get job
     */
    async getJob(requestParameters: GetJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobResponse> {
        const response = await this.getJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create job
     */
    async postJobRaw(requestParameters: PostJobRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<JobResponse>> {
        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/jobs`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobRequestToJSON(requestParameters.jobRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => JobResponseFromJSON(jsonValue));
    }

    /**
     * Create job
     */
    async postJob(requestParameters: PostJobRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<JobResponse> {
        const response = await this.postJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
