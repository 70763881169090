/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ExternalIdentifier
 */
export interface ExternalIdentifier {
    /**
     * 
     * @type {string}
     * @memberof ExternalIdentifier
     */
    service?: ExternalIdentifierServiceEnum;
    /**
     * 
     * @type {string}
     * @memberof ExternalIdentifier
     */
    entity?: string;
    /**
     * 
     * @type {string}
     * @memberof ExternalIdentifier
     */
    entityId?: string;
}


/**
 * @export
 */
export const ExternalIdentifierServiceEnum = {
    _1C: '1C',
    Timetable: 'timetable'
} as const;
export type ExternalIdentifierServiceEnum = typeof ExternalIdentifierServiceEnum[keyof typeof ExternalIdentifierServiceEnum];


/**
 * Check if a given object implements the ExternalIdentifier interface.
 */
export function instanceOfExternalIdentifier(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function ExternalIdentifierFromJSON(json: any): ExternalIdentifier {
    return ExternalIdentifierFromJSONTyped(json, false);
}

export function ExternalIdentifierFromJSONTyped(json: any, ignoreDiscriminator: boolean): ExternalIdentifier {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'service': !exists(json, 'service') ? undefined : json['service'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
    };
}

export function ExternalIdentifierToJSON(value?: ExternalIdentifier | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'service': value.service,
        'entity': value.entity,
        'entityId': value.entityId,
    };
}

