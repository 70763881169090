/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import {
    CreateElectiveFromDisciplineJob,
    instanceOfCreateElectiveFromDisciplineJob,
    CreateElectiveFromDisciplineJobFromJSON,
    CreateElectiveFromDisciplineJobFromJSONTyped,
    CreateElectiveFromDisciplineJobToJSON,
} from './CreateElectiveFromDisciplineJob';
import {
    CreateElectiveFromPlansJob,
    instanceOfCreateElectiveFromPlansJob,
    CreateElectiveFromPlansJobFromJSON,
    CreateElectiveFromPlansJobFromJSONTyped,
    CreateElectiveFromPlansJobToJSON,
} from './CreateElectiveFromPlansJob';
import {
    CreateElectiveGroupsJob,
    instanceOfCreateElectiveGroupsJob,
    CreateElectiveGroupsJobFromJSON,
    CreateElectiveGroupsJobFromJSONTyped,
    CreateElectiveGroupsJobToJSON,
} from './CreateElectiveGroupsJob';
import {
    CreateElectiveLimitationJob,
    instanceOfCreateElectiveLimitationJob,
    CreateElectiveLimitationJobFromJSON,
    CreateElectiveLimitationJobFromJSONTyped,
    CreateElectiveLimitationJobToJSON,
} from './CreateElectiveLimitationJob';
import {
    DistributeCohortElectiveConsolidationJob,
    instanceOfDistributeCohortElectiveConsolidationJob,
    DistributeCohortElectiveConsolidationJobFromJSON,
    DistributeCohortElectiveConsolidationJobFromJSONTyped,
    DistributeCohortElectiveConsolidationJobToJSON,
} from './DistributeCohortElectiveConsolidationJob';
import {
    ExportConsolidationsJob,
    instanceOfExportConsolidationsJob,
    ExportConsolidationsJobFromJSON,
    ExportConsolidationsJobFromJSONTyped,
    ExportConsolidationsJobToJSON,
} from './ExportConsolidationsJob';
import {
    FillElectiveConsolidationJob,
    instanceOfFillElectiveConsolidationJob,
    FillElectiveConsolidationJobFromJSON,
    FillElectiveConsolidationJobFromJSONTyped,
    FillElectiveConsolidationJobToJSON,
} from './FillElectiveConsolidationJob';
import {
    ProcessElectiveConsolidationJob,
    instanceOfProcessElectiveConsolidationJob,
    ProcessElectiveConsolidationJobFromJSON,
    ProcessElectiveConsolidationJobFromJSONTyped,
    ProcessElectiveConsolidationJobToJSON,
} from './ProcessElectiveConsolidationJob';
import {
    SyncElectiveConsolidationBackJob,
    instanceOfSyncElectiveConsolidationBackJob,
    SyncElectiveConsolidationBackJobFromJSON,
    SyncElectiveConsolidationBackJobFromJSONTyped,
    SyncElectiveConsolidationBackJobToJSON,
} from './SyncElectiveConsolidationBackJob';

/**
 * @type JobRequest
 * 
 * @export
 */
export type JobRequest = { type: 'create-elective-from-discipline' } & CreateElectiveFromDisciplineJob | { type: 'create-elective-from-plans' } & CreateElectiveFromPlansJob | { type: 'create-elective-groups' } & CreateElectiveGroupsJob | { type: 'create-elective-limitation' } & CreateElectiveLimitationJob | { type: 'distribute-cohort-elective-consolidation' } & DistributeCohortElectiveConsolidationJob | { type: 'export-consolidations' } & ExportConsolidationsJob | { type: 'fill-elective-consolidation' } & FillElectiveConsolidationJob | { type: 'process-elective-consolidation' } & ProcessElectiveConsolidationJob | { type: 'sync-elective-consolidation-back' } & SyncElectiveConsolidationBackJob;

export function JobRequestFromJSON(json: any): JobRequest {
    return JobRequestFromJSONTyped(json, false);
}

export function JobRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JobRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'create-elective-from-discipline':
            return {...CreateElectiveFromDisciplineJobFromJSONTyped(json, true), type: 'create-elective-from-discipline'};
        case 'create-elective-from-plans':
            return {...CreateElectiveFromPlansJobFromJSONTyped(json, true), type: 'create-elective-from-plans'};
        case 'create-elective-groups':
            return {...CreateElectiveGroupsJobFromJSONTyped(json, true), type: 'create-elective-groups'};
        case 'create-elective-limitation':
            return {...CreateElectiveLimitationJobFromJSONTyped(json, true), type: 'create-elective-limitation'};
        case 'distribute-cohort-elective-consolidation':
            return {...DistributeCohortElectiveConsolidationJobFromJSONTyped(json, true), type: 'distribute-cohort-elective-consolidation'};
        case 'export-consolidations':
            return {...ExportConsolidationsJobFromJSONTyped(json, true), type: 'export-consolidations'};
        case 'fill-elective-consolidation':
            return {...FillElectiveConsolidationJobFromJSONTyped(json, true), type: 'fill-elective-consolidation'};
        case 'process-elective-consolidation':
            return {...ProcessElectiveConsolidationJobFromJSONTyped(json, true), type: 'process-elective-consolidation'};
        case 'sync-elective-consolidation-back':
            return {...SyncElectiveConsolidationBackJobFromJSONTyped(json, true), type: 'sync-elective-consolidation-back'};
        default:
            throw new Error(`No variant of JobRequest exists with 'type=${json['type']}'`);
    }
}

export function JobRequestToJSON(value?: JobRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'create-elective-from-discipline':
            return CreateElectiveFromDisciplineJobToJSON(value);
        case 'create-elective-from-plans':
            return CreateElectiveFromPlansJobToJSON(value);
        case 'create-elective-groups':
            return CreateElectiveGroupsJobToJSON(value);
        case 'create-elective-limitation':
            return CreateElectiveLimitationJobToJSON(value);
        case 'distribute-cohort-elective-consolidation':
            return DistributeCohortElectiveConsolidationJobToJSON(value);
        case 'export-consolidations':
            return ExportConsolidationsJobToJSON(value);
        case 'fill-elective-consolidation':
            return FillElectiveConsolidationJobToJSON(value);
        case 'process-elective-consolidation':
            return ProcessElectiveConsolidationJobToJSON(value);
        case 'sync-elective-consolidation-back':
            return SyncElectiveConsolidationBackJobToJSON(value);
        default:
            throw new Error(`No variant of JobRequest exists with 'type=${value['type']}'`);
    }

}

