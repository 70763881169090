/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PostStatementAcceptRequest
 */
export interface PostStatementAcceptRequest {
    /**
     * 
     * @type {string}
     * @memberof PostStatementAcceptRequest
     */
    response?: string | null;
}

/**
 * Check if a given object implements the PostStatementAcceptRequest interface.
 */
export function instanceOfPostStatementAcceptRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PostStatementAcceptRequestFromJSON(json: any): PostStatementAcceptRequest {
    return PostStatementAcceptRequestFromJSONTyped(json, false);
}

export function PostStatementAcceptRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostStatementAcceptRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'response': !exists(json, 'response') ? undefined : json['response'],
    };
}

export function PostStatementAcceptRequestToJSON(value?: PostStatementAcceptRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'response': value.response,
    };
}

