import { acceptHMRUpdate, defineStore } from "pinia";
import { ElectiveChoiceStatsReportInner } from "@/iot";
import { abbreviateName, useElective } from "@/stores";
import { useToast } from "vue-toastification";
import router from "@/router";

export type ElectiveChoiceStatsReportState = {
  isLoading: boolean;
  report: Array<ElectiveChoiceStatsReportInner> | null;
  filterDepartment: Array<string | null>;
};

type ElectiveChoiceStatsReportTableRow = {
  key: string | number;
  value: {
    department: string;
    choices: number;
    users: number;
    percent: number;
  };
};

export function transformElectiveChoiceStatsReportRowForExport(
  row: ElectiveChoiceStatsReportTableRow,
): Array<string | number> {
  return [
    row.value.department,
    row.value.choices,
    row.value.users,
    row.value.percent,
  ];
}

export const useElectiveChoiceStatsReport = defineStore({
  id: "electiveChoiceStatsReport",
  state: () =>
    ({
      isLoading: false,
      report: null,
      filterDepartment: [],
    }) as ElectiveChoiceStatsReportState,
  getters: {
    dataTable: function ({ report }): Array<ElectiveChoiceStatsReportTableRow> {
      return (
        report?.map((row) => ({
          key: row.department as string,
          value: {
            department: row.department,
            choices: row.choices,
            users: row.users,
            percent: row.percent,
          },
        })) || []
      );
    },
    exportName: () => {
      return useElective().elective?.name;
    },
    exportHeader: () => {
      return [
        "Институт",
        "Количество выборов",
        "Количество студентов",
        "Доля выбравших",
      ];
    },
    filter: ({ filterDepartment }) => {
      const filters: Array<
        (row: ElectiveChoiceStatsReportTableRow) => boolean
      > = [];
      if (filterDepartment.length) {
        filters.push(
          (row: ElectiveChoiceStatsReportTableRow) =>
            (filterDepartment.includes("") &&
              row.value.department.length === 0) ||
            filterDepartment.includes(row.value.department),
        );
      }

      return (row: ElectiveChoiceStatsReportTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },
    availableDepartments({ report }): Array<{ k: string; v: string }> {
      return Object.values(
        report
          ?.map((row) => ({
            k: row?.department,
            v: abbreviateName(row?.department),
          }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableDepartments(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableDepartments];
    },
  },
  actions: {
    async load() {
      const electiveStore = useElective();
      if (!electiveStore.elective?.id) {
        return;
      }
      this.isLoading = true;
      try {
        this.report = await this.$api.report.getElectiveChoiceStatsReport({
          electiveId: electiveStore.elective.id,
        });
        this.report.sort((a, b) => a.department.localeCompare(b.department));
      } catch (e) {
        useToast().error("Не удалось загрузить отчёт");
        await router.replace({
          name: "elective:read",
          params: { slug: electiveStore.elective.slug },
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useElectiveChoiceStatsReport, import.meta.hot),
  );
}
