/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatementTemplate } from './StatementTemplate';
import {
    StatementTemplateFromJSON,
    StatementTemplateFromJSONTyped,
    StatementTemplateToJSON,
} from './StatementTemplate';
import type { VerifyElectiveChoiceStatementAllOfEmbedded } from './VerifyElectiveChoiceStatementAllOfEmbedded';
import {
    VerifyElectiveChoiceStatementAllOfEmbeddedFromJSON,
    VerifyElectiveChoiceStatementAllOfEmbeddedFromJSONTyped,
    VerifyElectiveChoiceStatementAllOfEmbeddedToJSON,
} from './VerifyElectiveChoiceStatementAllOfEmbedded';

/**
 * 
 * @export
 * @interface VerifyElectiveChoiceStatement
 */
export interface VerifyElectiveChoiceStatement {
    /**
     * 
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly userId?: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly printFileId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    type: VerifyElectiveChoiceStatementTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly status?: VerifyElectiveChoiceStatementStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly response?: string | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof VerifyElectiveChoiceStatement
     */
    readonly updatedAt?: Date;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    electiveId: string;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof VerifyElectiveChoiceStatement
     */
    choiceId: string;
    /**
     * 
     * @type {StatementTemplate}
     * @memberof VerifyElectiveChoiceStatement
     */
    template: StatementTemplate | null;
    /**
     * 
     * @type {VerifyElectiveChoiceStatementAllOfEmbedded}
     * @memberof VerifyElectiveChoiceStatement
     */
    embedded?: VerifyElectiveChoiceStatementAllOfEmbedded;
}


/**
 * @export
 */
export const VerifyElectiveChoiceStatementTypeEnum = {
    ChangeElectiveOptions: 'change-elective-options',
    ChooseElectiveOptions: 'choose-elective-options',
    VerifyElectiveChoice: 'verify-elective-choice'
} as const;
export type VerifyElectiveChoiceStatementTypeEnum = typeof VerifyElectiveChoiceStatementTypeEnum[keyof typeof VerifyElectiveChoiceStatementTypeEnum];

/**
 * @export
 */
export const VerifyElectiveChoiceStatementStatusEnum = {
    Filed: 'filed',
    Withdrawn: 'withdrawn',
    Accepted: 'accepted',
    Rejected: 'rejected',
    Fulfilled: 'fulfilled',
    Formed: 'formed'
} as const;
export type VerifyElectiveChoiceStatementStatusEnum = typeof VerifyElectiveChoiceStatementStatusEnum[keyof typeof VerifyElectiveChoiceStatementStatusEnum];


/**
 * Check if a given object implements the VerifyElectiveChoiceStatement interface.
 */
export function instanceOfVerifyElectiveChoiceStatement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "choiceId" in value;
    isInstance = isInstance && "template" in value;

    return isInstance;
}

export function VerifyElectiveChoiceStatementFromJSON(json: any): VerifyElectiveChoiceStatement {
    return VerifyElectiveChoiceStatementFromJSONTyped(json, false);
}

export function VerifyElectiveChoiceStatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): VerifyElectiveChoiceStatement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'printFileId': !exists(json, 'printFileId') ? undefined : json['printFileId'],
        'type': json['type'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'response': !exists(json, 'response') ? undefined : json['response'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'electiveId': json['electiveId'],
        'choiceId': json['choiceId'],
        'template': StatementTemplateFromJSON(json['template']),
        'embedded': !exists(json, '_embedded') ? undefined : VerifyElectiveChoiceStatementAllOfEmbeddedFromJSON(json['_embedded']),
    };
}

export function VerifyElectiveChoiceStatementToJSON(value?: VerifyElectiveChoiceStatement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'electiveId': value.electiveId,
        'choiceId': value.choiceId,
        'template': StatementTemplateToJSON(value.template),
        '_embedded': VerifyElectiveChoiceStatementAllOfEmbeddedToJSON(value.embedded),
    };
}

