/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarElectiveFlowChoiceWavesInner
 */
export interface CalendarElectiveFlowChoiceWavesInner {
    /**
     * 
     * @type {number}
     * @memberof CalendarElectiveFlowChoiceWavesInner
     */
    threshold: number;
    /**
     * 
     * @type {Date}
     * @memberof CalendarElectiveFlowChoiceWavesInner
     */
    openedAt: Date;
}

/**
 * Check if a given object implements the CalendarElectiveFlowChoiceWavesInner interface.
 */
export function instanceOfCalendarElectiveFlowChoiceWavesInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "threshold" in value;
    isInstance = isInstance && "openedAt" in value;

    return isInstance;
}

export function CalendarElectiveFlowChoiceWavesInnerFromJSON(json: any): CalendarElectiveFlowChoiceWavesInner {
    return CalendarElectiveFlowChoiceWavesInnerFromJSONTyped(json, false);
}

export function CalendarElectiveFlowChoiceWavesInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarElectiveFlowChoiceWavesInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'threshold': json['threshold'],
        'openedAt': (new Date(json['openedAt'])),
    };
}

export function CalendarElectiveFlowChoiceWavesInnerToJSON(value?: CalendarElectiveFlowChoiceWavesInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'threshold': value.threshold,
        'openedAt': (value.openedAt.toISOString()),
    };
}

