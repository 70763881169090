/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsolidationRowEmbedded } from './ConsolidationRowEmbedded';
import {
    ConsolidationRowEmbeddedFromJSON,
    ConsolidationRowEmbeddedFromJSONTyped,
    ConsolidationRowEmbeddedToJSON,
} from './ConsolidationRowEmbedded';
import type { ConsolidationUserOption } from './ConsolidationUserOption';
import {
    ConsolidationUserOptionFromJSON,
    ConsolidationUserOptionFromJSONTyped,
    ConsolidationUserOptionToJSON,
} from './ConsolidationUserOption';

/**
 * 
 * @export
 * @interface ConsolidationRow
 */
export interface ConsolidationRow {
    /**
     * 
     * @type {string}
     * @memberof ConsolidationRow
     */
    userId: string;
    /**
     * 
     * @type {Array<ConsolidationUserOption>}
     * @memberof ConsolidationRow
     */
    options: Array<ConsolidationUserOption>;
    /**
     * 
     * @type {ConsolidationRowEmbedded}
     * @memberof ConsolidationRow
     */
    embedded?: ConsolidationRowEmbedded;
}

/**
 * Check if a given object implements the ConsolidationRow interface.
 */
export function instanceOfConsolidationRow(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "userId" in value;
    isInstance = isInstance && "options" in value;

    return isInstance;
}

export function ConsolidationRowFromJSON(json: any): ConsolidationRow {
    return ConsolidationRowFromJSONTyped(json, false);
}

export function ConsolidationRowFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConsolidationRow {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userId': json['userId'],
        'options': ((json['options'] as Array<any>).map(ConsolidationUserOptionFromJSON)),
        'embedded': !exists(json, '_embedded') ? undefined : ConsolidationRowEmbeddedFromJSON(json['_embedded']),
    };
}

export function ConsolidationRowToJSON(value?: ConsolidationRow | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userId': value.userId,
        'options': ((value.options as Array<any>).map(ConsolidationUserOptionToJSON)),
        '_embedded': ConsolidationRowEmbeddedToJSON(value.embedded),
    };
}

