/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CalendarElectiveFlowEvent
 */
export interface CalendarElectiveFlowEvent {
    /**
     * Time interval event registration opens relative to event `startAt`.
     * If `null`, registration is opened at the time of event creation.
     * @type {string}
     * @memberof CalendarElectiveFlowEvent
     */
    registrationOpenInterval: string | null;
    /**
     * Time interval event registration closes relative to event `startAt`.
     * If `null`, registration is closed at the time of event start.
     * @type {string}
     * @memberof CalendarElectiveFlowEvent
     */
    registrationCloseInterval: string | null;
    /**
     * 
     * @type {string}
     * @memberof CalendarElectiveFlowEvent
     */
    retractInterval: string;
    /**
     * 
     * @type {string}
     * @memberof CalendarElectiveFlowEvent
     */
    intersectionPolicy: CalendarElectiveFlowEventIntersectionPolicyEnum;
    /**
     * Number of events allowed per day.
     * If 0, no limit.
     * @type {number}
     * @memberof CalendarElectiveFlowEvent
     */
    limitPerDay?: number;
    /**
     * Number of events allowed per week.
     * If 0, no limit.
     * @type {number}
     * @memberof CalendarElectiveFlowEvent
     */
    limitPerWeek?: number;
    /**
     * Number of events allowed per month.
     * If 0, no limit.
     * @type {number}
     * @memberof CalendarElectiveFlowEvent
     */
    limitPerMonth?: number;
    /**
     * Time to automatically register the user.
     * If `null`, the user must register manually.
     * @type {string}
     * @memberof CalendarElectiveFlowEvent
     */
    autoRegisterPolicy: CalendarElectiveFlowEventAutoRegisterPolicyEnum;
    /**
     * Number of events user is allowed to miss before disabling automatic registration for further events.
     * If 0, automatic registrations would not be disabled.
     * @type {number}
     * @memberof CalendarElectiveFlowEvent
     */
    cancelForMissedEvents?: number;
}


/**
 * @export
 */
export const CalendarElectiveFlowEventIntersectionPolicyEnum = {
    Allow: 'allow',
    Deny: 'deny',
    Ask: 'ask'
} as const;
export type CalendarElectiveFlowEventIntersectionPolicyEnum = typeof CalendarElectiveFlowEventIntersectionPolicyEnum[keyof typeof CalendarElectiveFlowEventIntersectionPolicyEnum];

/**
 * @export
 */
export const CalendarElectiveFlowEventAutoRegisterPolicyEnum = {
    WeekEnd: 'week-end',
    WeekStart: 'week-start',
    MonthEnd: 'month-end',
    MonthStart: 'month-start',
    AfterEvent: 'after-event',
    BeforeEvent: 'before-event'
} as const;
export type CalendarElectiveFlowEventAutoRegisterPolicyEnum = typeof CalendarElectiveFlowEventAutoRegisterPolicyEnum[keyof typeof CalendarElectiveFlowEventAutoRegisterPolicyEnum];


/**
 * Check if a given object implements the CalendarElectiveFlowEvent interface.
 */
export function instanceOfCalendarElectiveFlowEvent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "registrationOpenInterval" in value;
    isInstance = isInstance && "registrationCloseInterval" in value;
    isInstance = isInstance && "retractInterval" in value;
    isInstance = isInstance && "intersectionPolicy" in value;
    isInstance = isInstance && "autoRegisterPolicy" in value;

    return isInstance;
}

export function CalendarElectiveFlowEventFromJSON(json: any): CalendarElectiveFlowEvent {
    return CalendarElectiveFlowEventFromJSONTyped(json, false);
}

export function CalendarElectiveFlowEventFromJSONTyped(json: any, ignoreDiscriminator: boolean): CalendarElectiveFlowEvent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'registrationOpenInterval': json['registrationOpenInterval'],
        'registrationCloseInterval': json['registrationCloseInterval'],
        'retractInterval': json['retractInterval'],
        'intersectionPolicy': json['intersectionPolicy'],
        'limitPerDay': !exists(json, 'limitPerDay') ? undefined : json['limitPerDay'],
        'limitPerWeek': !exists(json, 'limitPerWeek') ? undefined : json['limitPerWeek'],
        'limitPerMonth': !exists(json, 'limitPerMonth') ? undefined : json['limitPerMonth'],
        'autoRegisterPolicy': json['autoRegisterPolicy'],
        'cancelForMissedEvents': !exists(json, 'cancelForMissedEvents') ? undefined : json['cancelForMissedEvents'],
    };
}

export function CalendarElectiveFlowEventToJSON(value?: CalendarElectiveFlowEvent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'registrationOpenInterval': value.registrationOpenInterval,
        'registrationCloseInterval': value.registrationCloseInterval,
        'retractInterval': value.retractInterval,
        'intersectionPolicy': value.intersectionPolicy,
        'limitPerDay': value.limitPerDay,
        'limitPerWeek': value.limitPerWeek,
        'limitPerMonth': value.limitPerMonth,
        'autoRegisterPolicy': value.autoRegisterPolicy,
        'cancelForMissedEvents': value.cancelForMissedEvents,
    };
}

