import { acceptHMRUpdate, defineStore } from "pinia";
import { Elective, Statement } from "@/iot";
import router from "@/router";
import { useToast } from "vue-toastification";
import { format } from "@/utils/date";
import { userFullName } from "@/stores/user";

export type StatementsState = {
  statements: Statement[] | null;
  isLoading: boolean;
  filterName: string;
  filterEmail: string;
  filterType: string[];
  filterStatus: string[];
  filterAcademicYear: string[];
  filterAcademicTerm: string;
  filterElective: string[];
};

type StatementTableRow = {
  key: string | number;
  value: {
    name: string;
    email: string;
    type: string;
    status: string | null;
    academicYear: string | null;
    academicTerm: number | null;
    printFileId: string | null;
    electives: Array<Elective>;
    createdAt: string;
  };
};

export const useStatements = defineStore({
  id: "statements",
  state: () =>
    ({
      statements: null,
      isLoading: false,
      filterName: "",
      filterEmail: "",
      filterType: [],
      filterStatus: [],
      filterAcademicYear: [],
      filterAcademicTerm: "",
      filterElective: [],
    }) as StatementsState,
  getters: {
    dataTable: function ({ statements }): Array<StatementTableRow> {
      return (
        statements?.map((statement) => ({
          key: statement.id as string,
          value: {
            name: userFullName(statement.embedded?.user),
            email: statement.embedded?.user?.email?.trim() || "",
            type: statement.type,
            status: statement.status ?? null,
            academicYear: statement.academicYear ?? null,
            academicTerm: statement.academicTerm ?? null,
            printFileId: statement.printFileId ?? null,
            electives: statement.embedded?.electives || [],
            createdAt: statement.createdAt
              ? format(statement.createdAt, "HH:mm dd.MM.uuuu")
              : "",
          },
        })) || []
      );
    },
    filter: ({
      filterName,
      filterEmail,
      filterType,
      filterStatus,
      filterAcademicYear,
      filterAcademicTerm,
      filterElective,
    }) => {
      const filters: Array<(row: StatementTableRow) => boolean> = [];
      if (filterName.trim() !== "") {
        filters.push((row: StatementTableRow) =>
          row.value.name
            .toLowerCase()
            .includes(filterName.toLowerCase().trim()),
        );
      }
      if (filterEmail.trim() !== "") {
        filters.push((row: StatementTableRow) =>
          row.value.email
            .toLowerCase()
            .includes(filterEmail.toLowerCase().trim()),
        );
      }
      if (filterType.length) {
        filters.push((row: StatementTableRow) =>
          filterType.includes(row.value.type),
        );
      }
      if (filterStatus.length) {
        filters.push((row: StatementTableRow) =>
          filterStatus.includes(row.value.status ?? ""),
        );
      }
      if (filterAcademicYear.length) {
        filters.push(
          (row: StatementTableRow) =>
            !row.value.academicYear ||
            filterAcademicYear.includes(row.value.academicYear),
        );
      }
      if (filterAcademicTerm.trim() !== "") {
        filters.push(
          (row: StatementTableRow) =>
            `${row.value.academicTerm}` === filterAcademicTerm,
        );
      }
      if (filterElective.length) {
        filters.push((row: StatementTableRow) =>
          row.value.electives.some(({ id }) =>
            filterElective.includes(id as string),
          ),
        );
      }

      return (row: StatementTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },
    filterableElectives: ({ statements }) => {
      const electives: { [id: string]: string } = {};
      statements?.forEach((statement) => {
        statement?.embedded?.electives?.forEach(
          ({ id, name }) => (electives[id as string] = name),
        );
      });

      return Object.keys(electives).map((electiveId) => ({
        k: electiveId,
        v: electives[electiveId],
      }));
    },
    filterableAcademicYears: ({ statements }) => {
      const academicYears: { [n: string]: string } = {};
      statements?.forEach(
        ({ academicYear }) => (academicYears[academicYear] = academicYear),
      );

      return Object.values(academicYears).map((academicYear) => ({
        k: academicYear,
        v: academicYear,
      }));
    },
  },
  actions: {
    async load() {
      this.isLoading = true;
      try {
        this.statements = await this.$api.statement.getStatementCollection({
          sort: ["-createdAt"],
          expand: "user,electives",
          limit: 10000,
        });
      } catch (e) {
        useToast().error("Не удалось загрузить список заявлений");
        await router.replace({ name: "home" });
      } finally {
        this.isLoading = false;
      }
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useStatements, import.meta.hot));
}
