/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  Consolidation,
  ConsolidationExport,
  ConsolidationRow,
  PatchConsolidationRequest,
  PostConsolidationRequest,
  StoredFile,
} from '../models';
import {
    ConsolidationFromJSON,
    ConsolidationToJSON,
    ConsolidationExportFromJSON,
    ConsolidationExportToJSON,
    ConsolidationRowFromJSON,
    ConsolidationRowToJSON,
    PatchConsolidationRequestFromJSON,
    PatchConsolidationRequestToJSON,
    PostConsolidationRequestFromJSON,
    PostConsolidationRequestToJSON,
    StoredFileFromJSON,
    StoredFileToJSON,
} from '../models';

export interface DeleteConsolidationRequest {
    id: string;
}

export interface GetConsolidationRequest {
    id: string;
    expand?: string;
}

export interface GetConsolidationCollectionRequest {
    limit?: number;
    offset?: number;
    filter?: string;
    q?: string;
    expand?: string;
    sort?: Array<string>;
}

export interface GetConsolidationRowCollectionRequest {
    id: string;
    expand?: string;
}

export interface PatchConsolidationOperationRequest {
    id: string;
    expand?: string;
    patchConsolidationRequest?: PatchConsolidationRequest;
}

export interface PostConsolidationOperationRequest {
    postConsolidationRequest?: PostConsolidationRequest;
}

export interface PostConsolidationExportRequest {
    id: string;
    expand?: string;
    consolidationExport?: ConsolidationExport;
}

/**
 * 
 */
export class ConsolidationApi extends runtime.BaseAPI {

    /**
     * Delete consolidation
     */
    async deleteConsolidationRaw(requestParameters: DeleteConsolidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteConsolidation.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consolidations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete consolidation
     */
    async deleteConsolidation(requestParameters: DeleteConsolidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteConsolidationRaw(requestParameters, initOverrides);
    }

    /**
     * Get consolidation
     */
    async getConsolidationRaw(requestParameters: GetConsolidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consolidation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConsolidation.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consolidations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsolidationFromJSON(jsonValue));
    }

    /**
     * Get consolidation
     */
    async getConsolidation(requestParameters: GetConsolidationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consolidation> {
        const response = await this.getConsolidationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Retrieve consolidations collection.
     * Browse consolidation
     */
    async getConsolidationCollectionRaw(requestParameters: GetConsolidationCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<Consolidation>>> {
        const queryParameters: any = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.filter !== undefined) {
            queryParameters['filter'] = requestParameters.filter;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort.join(runtime.COLLECTION_FORMATS["csv"]);
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consolidations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsolidationFromJSON));
    }

    /**
     * Retrieve consolidations collection.
     * Browse consolidation
     */
    async getConsolidationCollection(requestParameters: GetConsolidationCollectionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<Consolidation>> {
        const response = await this.getConsolidationCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get consolidation rows
     */
    async getConsolidationRowCollectionRaw(requestParameters: GetConsolidationRowCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Array<ConsolidationRow>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getConsolidationRowCollection.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/consolidations/{id}/rows`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ConsolidationRowFromJSON));
    }

    /**
     * Get consolidation rows
     */
    async getConsolidationRowCollection(requestParameters: GetConsolidationRowCollectionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Array<ConsolidationRow>> {
        const response = await this.getConsolidationRowCollectionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update consolidation
     */
    async patchConsolidationRaw(requestParameters: PatchConsolidationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consolidation>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling patchConsolidation.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/consolidations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchConsolidationRequestToJSON(requestParameters.patchConsolidationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsolidationFromJSON(jsonValue));
    }

    /**
     * Update consolidation
     */
    async patchConsolidation(requestParameters: PatchConsolidationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consolidation> {
        const response = await this.patchConsolidationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create consolidation
     */
    async postConsolidationRaw(requestParameters: PostConsolidationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Consolidation>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/consolidations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PostConsolidationRequestToJSON(requestParameters.postConsolidationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConsolidationFromJSON(jsonValue));
    }

    /**
     * Create consolidation
     */
    async postConsolidation(requestParameters: PostConsolidationOperationRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Consolidation> {
        const response = await this.postConsolidationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create consolidation export
     */
    async postConsolidationExportRaw(requestParameters: PostConsolidationExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StoredFile>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling postConsolidationExport.');
        }

        const queryParameters: any = {};

        if (requestParameters.expand !== undefined) {
            queryParameters['expand'] = requestParameters.expand;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/consolidations/{id}/export`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConsolidationExportToJSON(requestParameters.consolidationExport),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StoredFileFromJSON(jsonValue));
    }

    /**
     * Create consolidation export
     */
    async postConsolidationExport(requestParameters: PostConsolidationExportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StoredFile> {
        const response = await this.postConsolidationExportRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
