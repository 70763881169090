/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ElectiveChoiceChangesReportInnerBucketsInner } from './ElectiveChoiceChangesReportInnerBucketsInner';
import {
    ElectiveChoiceChangesReportInnerBucketsInnerFromJSON,
    ElectiveChoiceChangesReportInnerBucketsInnerFromJSONTyped,
    ElectiveChoiceChangesReportInnerBucketsInnerToJSON,
} from './ElectiveChoiceChangesReportInnerBucketsInner';
import type { ElectiveChoiceChangesReportInnerEmbedded } from './ElectiveChoiceChangesReportInnerEmbedded';
import {
    ElectiveChoiceChangesReportInnerEmbeddedFromJSON,
    ElectiveChoiceChangesReportInnerEmbeddedFromJSONTyped,
    ElectiveChoiceChangesReportInnerEmbeddedToJSON,
} from './ElectiveChoiceChangesReportInnerEmbedded';

/**
 * 
 * @export
 * @interface ElectiveChoiceChangesReportInner
 */
export interface ElectiveChoiceChangesReportInner {
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof ElectiveChoiceChangesReportInner
     */
    electiveId: string;
    /**
     * The list of primary options chosen by the user. `null` if no choice was made.
     * @type {Array<ElectiveChoiceChangesReportInnerBucketsInner>}
     * @memberof ElectiveChoiceChangesReportInner
     */
    buckets: Array<ElectiveChoiceChangesReportInnerBucketsInner>;
    /**
     * 
     * @type {number}
     * @memberof ElectiveChoiceChangesReportInner
     */
    unknownPeriodChoices: number;
    /**
     * 
     * @type {number}
     * @memberof ElectiveChoiceChangesReportInner
     */
    totalChoices: number;
    /**
     * 
     * @type {ElectiveChoiceChangesReportInnerEmbedded}
     * @memberof ElectiveChoiceChangesReportInner
     */
    embedded?: ElectiveChoiceChangesReportInnerEmbedded;
}

/**
 * Check if a given object implements the ElectiveChoiceChangesReportInner interface.
 */
export function instanceOfElectiveChoiceChangesReportInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "electiveId" in value;
    isInstance = isInstance && "buckets" in value;
    isInstance = isInstance && "unknownPeriodChoices" in value;
    isInstance = isInstance && "totalChoices" in value;

    return isInstance;
}

export function ElectiveChoiceChangesReportInnerFromJSON(json: any): ElectiveChoiceChangesReportInner {
    return ElectiveChoiceChangesReportInnerFromJSONTyped(json, false);
}

export function ElectiveChoiceChangesReportInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): ElectiveChoiceChangesReportInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'electiveId': json['electiveId'],
        'buckets': ((json['buckets'] as Array<any>).map(ElectiveChoiceChangesReportInnerBucketsInnerFromJSON)),
        'unknownPeriodChoices': json['unknownPeriodChoices'],
        'totalChoices': json['totalChoices'],
        'embedded': !exists(json, '_embedded') ? undefined : ElectiveChoiceChangesReportInnerEmbeddedFromJSON(json['_embedded']),
    };
}

export function ElectiveChoiceChangesReportInnerToJSON(value?: ElectiveChoiceChangesReportInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'electiveId': value.electiveId,
        'buckets': ((value.buckets as Array<any>).map(ElectiveChoiceChangesReportInnerBucketsInnerToJSON)),
        'unknownPeriodChoices': value.unknownPeriodChoices,
        'totalChoices': value.totalChoices,
        '_embedded': ElectiveChoiceChangesReportInnerEmbeddedToJSON(value.embedded),
    };
}

