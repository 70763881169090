import { acceptHMRUpdate, defineStore } from "pinia";
import { debounce } from "lodash";
import { IntegrationApi } from "@/iot/apis";
import { Discipline } from "@/iot";

export const useDiscipline = defineStore({
  id: "discipline",
  state: (): {
    disciplines: null | Array<Discipline>;
  } => ({
    disciplines: null,
  }),
  getters: {
    asOptions({ disciplines }): Array<{ k: string; v: string }> {
      return (
        disciplines?.map(({ fullName, id }) => ({
          k: id as string,
          v: fullName?.trim() ?? "",
        })) ?? []
      );
    },
  },
  actions: {
    async load() {
      if (this.disciplines !== null) {
        return;
      }
      this.disciplines =
        await this.$api.integration.getIntegrationDisciplineCollection({
          limit: 1000,
        });
    },
    search({
      query,
      loading,
      value,
    }: {
      query: string;
      loading: (isLoading: boolean) => void;
      value: Array<string> | string;
    }) {
      if (!query.trim().length) {
        return;
      }
      const currentValues = Array.isArray(value) ? value : [value];
      loading(true);
      this.internalSearch(
        this.$api.integration,
        query,
        loading,
        (disciplines: Array<Discipline>) => {
          // Remove selected disciplines
          disciplines = disciplines.filter(
            ({ id }) => !currentValues.includes(id as string),
          );
          // Add selected disciplines at the top
          this.disciplines
            ?.filter(({ id }) => currentValues.includes(id as string))
            ?.forEach((discipline) => disciplines.unshift(discipline));

          this.disciplines = disciplines;
        },
      );
    },
    internalSearch: debounce(
      async (
        integration: IntegrationApi,
        q: string,
        loading: (isLoading: boolean) => void,
        setter: (disciplines: Array<Discipline>) => void,
      ) => {
        try {
          setter(await integration.getIntegrationDisciplineCollection({ q }));
        } finally {
          loading(false);
        }
      },
      350,
    ),
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDiscipline, import.meta.hot));
}
