/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ElectiveType } from './ElectiveType';
import {
    ElectiveTypeFromJSON,
    ElectiveTypeFromJSONTyped,
    ElectiveTypeToJSON,
} from './ElectiveType';
import type { GenericElectiveEmbedded } from './GenericElectiveEmbedded';
import {
    GenericElectiveEmbeddedFromJSON,
    GenericElectiveEmbeddedFromJSONTyped,
    GenericElectiveEmbeddedToJSON,
} from './GenericElectiveEmbedded';
import type { GenericElectiveFlow } from './GenericElectiveFlow';
import {
    GenericElectiveFlowFromJSON,
    GenericElectiveFlowFromJSONTyped,
    GenericElectiveFlowToJSON,
} from './GenericElectiveFlow';
import type { GenericElectiveOutcomeInner } from './GenericElectiveOutcomeInner';
import {
    GenericElectiveOutcomeInnerFromJSON,
    GenericElectiveOutcomeInnerFromJSONTyped,
    GenericElectiveOutcomeInnerToJSON,
} from './GenericElectiveOutcomeInner';
import type { StatementTemplate } from './StatementTemplate';
import {
    StatementTemplateFromJSON,
    StatementTemplateFromJSONTyped,
    StatementTemplateToJSON,
} from './StatementTemplate';

/**
 * 
 * @export
 * @interface GenericElective
 */
export interface GenericElective {
    /**
     * 
     * @type {string}
     * @memberof GenericElective
     */
    readonly id?: string;
    /**
     * 
     * @type {ElectiveType}
     * @memberof GenericElective
     */
    type: ElectiveType;
    /**
     * The resource slug. Defaults to resource ID. Must be unique across resources of the same type.
     * @type {string}
     * @memberof GenericElective
     */
    slug?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericElective
     */
    name: string;
    /**
     * 
     * @type {GenericElectiveFlow}
     * @memberof GenericElective
     */
    flow: GenericElectiveFlow;
    /**
     * Indicates whether current user is an elective member or not.
     * @type {boolean}
     * @memberof GenericElective
     */
    readonly isMember?: boolean;
    /**
     * Indicates whether current user can post a choice or not. This takes timeframe and rating into an account as well as existing choice existance.
     * @type {boolean}
     * @memberof GenericElective
     */
    readonly canChoose?: boolean;
    /**
     * 
     * @type {Array<GenericElectiveOutcomeInner>}
     * @memberof GenericElective
     */
    readonly outcome?: Array<GenericElectiveOutcomeInner> | null;
    /**
     * A list of filters against User resource.
     * Elective is availabe to the user if any filter matches.
     * @type {Array<string>}
     * @memberof GenericElective
     */
    userFilters?: Array<string>;
    /**
     * The academic year of the elective.
     * @type {string}
     * @memberof GenericElective
     */
    academicYear: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof GenericElective
     */
    academicTerm?: number | null;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof GenericElective
     */
    disciplineId?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericElective
     */
    description?: string;
    /**
     * Poster file ID.
     * @type {string}
     * @memberof GenericElective
     */
    posterId?: string;
    /**
     * Supported services (**https only**):
     * - disk.sevsu.ru
     * - Youtube
     * @type {string}
     * @memberof GenericElective
     */
    videoUrl?: string;
    /**
     * Only *.sevsu.ru domains are supported (**https only**).
     * @type {string}
     * @memberof GenericElective
     */
    detailsUrl?: string;
    /**
     * 
     * @type {StatementTemplate}
     * @memberof GenericElective
     */
    statementTemplate?: StatementTemplate | null;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof GenericElective
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof GenericElective
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {GenericElectiveEmbedded}
     * @memberof GenericElective
     */
    embedded?: GenericElectiveEmbedded;
}

/**
 * Check if a given object implements the GenericElective interface.
 */
export function instanceOfGenericElective(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "flow" in value;
    isInstance = isInstance && "academicYear" in value;

    return isInstance;
}

export function GenericElectiveFromJSON(json: any): GenericElective {
    return GenericElectiveFromJSONTyped(json, false);
}

export function GenericElectiveFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericElective {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': ElectiveTypeFromJSON(json['type']),
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'name': json['name'],
        'flow': GenericElectiveFlowFromJSON(json['flow']),
        'isMember': !exists(json, 'isMember') ? undefined : json['isMember'],
        'canChoose': !exists(json, 'canChoose') ? undefined : json['canChoose'],
        'outcome': !exists(json, 'outcome') ? undefined : (json['outcome'] === null ? null : (json['outcome'] as Array<any>).map(GenericElectiveOutcomeInnerFromJSON)),
        'userFilters': !exists(json, 'userFilters') ? undefined : json['userFilters'],
        'academicYear': json['academicYear'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
        'disciplineId': !exists(json, 'disciplineId') ? undefined : json['disciplineId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'posterId': !exists(json, 'posterId') ? undefined : json['posterId'],
        'videoUrl': !exists(json, 'videoUrl') ? undefined : json['videoUrl'],
        'detailsUrl': !exists(json, 'detailsUrl') ? undefined : json['detailsUrl'],
        'statementTemplate': !exists(json, 'statementTemplate') ? undefined : StatementTemplateFromJSON(json['statementTemplate']),
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'embedded': !exists(json, '_embedded') ? undefined : GenericElectiveEmbeddedFromJSON(json['_embedded']),
    };
}

export function GenericElectiveToJSON(value?: GenericElective | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': ElectiveTypeToJSON(value.type),
        'slug': value.slug,
        'name': value.name,
        'flow': GenericElectiveFlowToJSON(value.flow),
        'userFilters': value.userFilters,
        'academicYear': value.academicYear,
        'academicTerm': value.academicTerm,
        'disciplineId': value.disciplineId,
        'description': value.description,
        'posterId': value.posterId,
        'videoUrl': value.videoUrl,
        'detailsUrl': value.detailsUrl,
        'statementTemplate': StatementTemplateToJSON(value.statementTemplate),
        '_embedded': GenericElectiveEmbeddedToJSON(value.embedded),
    };
}

