/* tslint:disable */
/* eslint-disable */
/**
 * iot.sevsu.ru
 * SevSU IOT API 
 *
 * The version of the OpenAPI document: 0.0.1
 * Contact: arif+iot@sevsu.ru
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CreateElectiveFromDisciplineJobAllOfOptions } from './CreateElectiveFromDisciplineJobAllOfOptions';
import {
    CreateElectiveFromDisciplineJobAllOfOptionsFromJSON,
    CreateElectiveFromDisciplineJobAllOfOptionsFromJSONTyped,
    CreateElectiveFromDisciplineJobAllOfOptionsToJSON,
} from './CreateElectiveFromDisciplineJobAllOfOptions';
import type { ElectiveType } from './ElectiveType';
import {
    ElectiveTypeFromJSON,
    ElectiveTypeFromJSONTyped,
    ElectiveTypeToJSON,
} from './ElectiveType';
import type { OptionCohortSettings } from './OptionCohortSettings';
import {
    OptionCohortSettingsFromJSON,
    OptionCohortSettingsFromJSONTyped,
    OptionCohortSettingsToJSON,
} from './OptionCohortSettings';

/**
 * 
 * @export
 * @interface CreateElectiveFromDisciplineJob
 */
export interface CreateElectiveFromDisciplineJob {
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    readonly id?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    readonly userId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    type: CreateElectiveFromDisciplineJobTypeEnum;
    /**
     * If the job should only return a preview or perform an action.
     * @type {boolean}
     * @memberof CreateElectiveFromDisciplineJob
     */
    dryRun: boolean;
    /**
     * Reuse pre-calculations from parent job if passed.
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    parentId?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    readonly status?: CreateElectiveFromDisciplineJobStatusEnum;
    /**
     * The time resource was created.
     * @type {Date}
     * @memberof CreateElectiveFromDisciplineJob
     */
    readonly createdAt?: Date;
    /**
     * The time resource was updated.
     * @type {Date}
     * @memberof CreateElectiveFromDisciplineJob
     */
    readonly updatedAt?: Date;
    /**
     * 
     * @type {ElectiveType}
     * @memberof CreateElectiveFromDisciplineJob
     */
    electiveType: ElectiveType;
    /**
     * The elective period of study.
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    periodOfStudy: string;
    /**
     * The number of academic term within the whole education, e.g. 3 for first semester on the second year of study.
     * @type {number}
     * @memberof CreateElectiveFromDisciplineJob
     */
    academicTerm?: number | null;
    /**
     * A list of filters against Plan integration resource.
     * Plan is used if any of the filters match.
     * @type {Array<string>}
     * @memberof CreateElectiveFromDisciplineJob
     */
    planFilters: Array<string>;
    /**
     * The resource ID. Generated as ULID.
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    disciplineId: string;
    /**
     * 
     * @type {Array<CreateElectiveFromDisciplineJobAllOfOptions>}
     * @memberof CreateElectiveFromDisciplineJob
     */
    options: Array<CreateElectiveFromDisciplineJobAllOfOptions>;
    /**
     * The name of the elective to create.
     * @type {string}
     * @memberof CreateElectiveFromDisciplineJob
     */
    name?: string;
    /**
     * Default cohort user distribution settings.
     * @type {OptionCohortSettings}
     * @memberof CreateElectiveFromDisciplineJob
     */
    cohort: OptionCohortSettings;
}


/**
 * @export
 */
export const CreateElectiveFromDisciplineJobTypeEnum = {
    CreateElectiveFromDiscipline: 'create-elective-from-discipline',
    CreateElectiveFromPlans: 'create-elective-from-plans',
    CreateElectiveGroups: 'create-elective-groups',
    CreateElectiveLimitation: 'create-elective-limitation',
    DistributeCohortElectiveConsolidation: 'distribute-cohort-elective-consolidation',
    ExportConsolidations: 'export-consolidations',
    FillElectiveConsolidation: 'fill-elective-consolidation',
    ProcessElectiveConsolidation: 'process-elective-consolidation',
    SyncElectiveConsolidationBack: 'sync-elective-consolidation-back'
} as const;
export type CreateElectiveFromDisciplineJobTypeEnum = typeof CreateElectiveFromDisciplineJobTypeEnum[keyof typeof CreateElectiveFromDisciplineJobTypeEnum];

/**
 * @export
 */
export const CreateElectiveFromDisciplineJobStatusEnum = {
    Queued: 'queued',
    Processing: 'processing',
    Failed: 'failed',
    Completed: 'completed'
} as const;
export type CreateElectiveFromDisciplineJobStatusEnum = typeof CreateElectiveFromDisciplineJobStatusEnum[keyof typeof CreateElectiveFromDisciplineJobStatusEnum];


/**
 * Check if a given object implements the CreateElectiveFromDisciplineJob interface.
 */
export function instanceOfCreateElectiveFromDisciplineJob(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "dryRun" in value;
    isInstance = isInstance && "electiveType" in value;
    isInstance = isInstance && "periodOfStudy" in value;
    isInstance = isInstance && "planFilters" in value;
    isInstance = isInstance && "disciplineId" in value;
    isInstance = isInstance && "options" in value;
    isInstance = isInstance && "cohort" in value;

    return isInstance;
}

export function CreateElectiveFromDisciplineJobFromJSON(json: any): CreateElectiveFromDisciplineJob {
    return CreateElectiveFromDisciplineJobFromJSONTyped(json, false);
}

export function CreateElectiveFromDisciplineJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateElectiveFromDisciplineJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'type': json['type'],
        'dryRun': json['dryRun'],
        'parentId': !exists(json, 'parentId') ? undefined : json['parentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'createdAt': !exists(json, 'createdAt') ? undefined : (new Date(json['createdAt'])),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : (new Date(json['updatedAt'])),
        'electiveType': ElectiveTypeFromJSON(json['electiveType']),
        'periodOfStudy': json['periodOfStudy'],
        'academicTerm': !exists(json, 'academicTerm') ? undefined : json['academicTerm'],
        'planFilters': json['planFilters'],
        'disciplineId': json['disciplineId'],
        'options': ((json['options'] as Array<any>).map(CreateElectiveFromDisciplineJobAllOfOptionsFromJSON)),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'cohort': OptionCohortSettingsFromJSON(json['cohort']),
    };
}

export function CreateElectiveFromDisciplineJobToJSON(value?: CreateElectiveFromDisciplineJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'dryRun': value.dryRun,
        'parentId': value.parentId,
        'electiveType': ElectiveTypeToJSON(value.electiveType),
        'periodOfStudy': value.periodOfStudy,
        'academicTerm': value.academicTerm,
        'planFilters': value.planFilters,
        'disciplineId': value.disciplineId,
        'options': ((value.options as Array<any>).map(CreateElectiveFromDisciplineJobAllOfOptionsToJSON)),
        'name': value.name,
        'cohort': OptionCohortSettingsToJSON(value.cohort),
    };
}

