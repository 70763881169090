import { acceptHMRUpdate, defineStore } from "pinia";
import {
  ElectiveChoicesReportInner,
  Option,
  Specialty,
  UserCohortTypeEnum,
} from "@/iot";
import { abbreviateName, useElective, userFullName } from "@/stores";
import { SpecialtiesMap } from "@/stores/common";
import { useToast } from "vue-toastification";
import router from "@/router";
import { formatRFC3339 } from "@/utils/date";
import { fetchAll } from "@/utils/paginator";

export type ElectiveChoicesReportState = {
  isLoading: boolean;
  report: Array<ElectiveChoicesReportInner> | null;
  specialties: SpecialtiesMap;
  filterName: string;
  filterEmail: string;
  filterDepartment: Array<string | null>;
  filterSpecialty: Array<string | null>;
  filterPrimaryOption: Array<string | null>;
  filterBackupOption: Array<string | null>;
};

type ElectiveChoicesReportTableRow = {
  key: string | number;
  value: {
    name: string;
    email: string;
    departments: string[];
    specialties: string[];
    userCohorts: string[];
    rating: number;
    primaryOptions: Option[];
    backupOptions: Option[];
    createdAt: Date | null;
  };
};

export function transformElectiveChoicesReportRowForExport(
  row: ElectiveChoicesReportTableRow,
): Array<string | number> {
  const data = [
    row.value.name,
    row.value.email,
    row.value.departments.join(", "),
    row.value.specialties.join(", "),
    row.value.userCohorts.join(", "),
    `${row.value.rating}`,
    row.value.createdAt ? formatRFC3339(row.value.createdAt) : "",
  ];
  row.value.primaryOptions.forEach((option) => data.push(option.fullName));
  row.value.backupOptions.forEach((option) => data.push(option.fullName));
  return data;
}

function buildOptionsFilter(
  filters: Array<string | null>,
  optionsKey: "primaryOptions" | "backupOptions",
): (row: ElectiveChoicesReportTableRow) => boolean {
  if (!filters.length) {
    return () => true;
  }
  const callbacks: Array<(row: ElectiveChoicesReportTableRow) => boolean> = [];
  if (filters.includes("!empty")) {
    callbacks.push(
      (row: ElectiveChoicesReportTableRow) => row.value[optionsKey].length > 0,
    );
  }
  if (filters.includes("")) {
    callbacks.push(
      (row: ElectiveChoicesReportTableRow) =>
        row.value[optionsKey].length === 0,
    );
  }
  if (filters.length > 0) {
    callbacks.push((row: ElectiveChoicesReportTableRow) =>
      row.value[optionsKey].some((option) => filters.includes(option.id || "")),
    );
  }
  return (row: ElectiveChoicesReportTableRow) =>
    callbacks.reduce((result, filter) => result || filter(row), false);
}

export const useElectiveChoicesReport = defineStore({
  id: "electiveChoicesReport",
  state: () =>
    ({
      isLoading: false,
      report: null,
      specialties: {},
      filterName: "",
      filterEmail: "",
      filterDepartment: [],
      filterSpecialty: [],
      filterPrimaryOption: [],
      filterBackupOption: [],
    }) as ElectiveChoicesReportState,
  getters: {
    dataTable: function ({ report }): Array<ElectiveChoicesReportTableRow> {
      const options = useElective().options;
      return (
        report?.map((row) => ({
          key: row.userId as string,
          value: {
            name: userFullName(row.embedded?.user),
            email: row.embedded?.user?.email?.trim() || "",
            departments: row.embedded?.user?.departments || [],
            specialties: row.embedded?.user?.specialties || [],
            userCohorts:
              row.embedded?.user?.embedded?.cohorts
                ?.filter(
                  ({ name, type }) =>
                    !name?.includes("Уч.гр.") &&
                    UserCohortTypeEnum.Educational !== type,
                )
                ?.map(({ name }) => name || "") || [],
            rating: row.embedded?.user?.rating ?? 0,
            primaryOptions:
              row.primaryOptions
                ?.map((optionId) => options[optionId] || null)
                ?.filter((option) => option !== null) || [],
            backupOptions:
              row.backupOptions
                ?.map((optionId) => options[optionId] || null)
                ?.filter((option) => option !== null) || [],
            createdAt: row.createdAt ?? null,
          },
        })) || []
      );
    },
    exportName: () => {
      return useElective().elective?.name;
    },
    exportHeader: () => {
      const header = [
        "ФИО",
        "Email",
        "Институт",
        "Направление",
        "Группа",
        "Рейтинг",
        "Дата выбора",
      ];
      const elective = useElective().elective;
      if (elective?.flow) {
        for (let i = 1; i <= elective.flow.primaryOptions; i++) {
          header.push(
            `Основной выбор ${elective.flow.primaryOptions > 1 ? i : ""}`.trim(),
          );
        }
        for (let i = 1; i <= (elective.flow.backupOptions || 0); i++) {
          header.push(
            `Запасной выбор ${
              (elective.flow.backupOptions || 0) > 1 ? i : ""
            }`.trim(),
          );
        }
      }

      return header;
    },
    filter: ({
      filterName,
      filterEmail,
      filterDepartment,
      filterSpecialty,
      filterPrimaryOption,
      filterBackupOption,
    }) => {
      const filters: Array<(row: ElectiveChoicesReportTableRow) => boolean> =
        [];
      if (filterName.trim() !== "") {
        filters.push((row: ElectiveChoicesReportTableRow) =>
          row.value.name
            .toLowerCase()
            .includes(filterName.toLowerCase().trim()),
        );
      }
      if (filterEmail.trim() !== "") {
        filters.push((row: ElectiveChoicesReportTableRow) =>
          row.value.email
            .toLowerCase()
            .includes(filterEmail.toLowerCase().trim()),
        );
      }
      if (filterDepartment.length) {
        filters.push(
          (row: ElectiveChoicesReportTableRow) =>
            (filterDepartment.includes("") &&
              row.value.departments.length === 0) ||
            row.value.departments.some((department) =>
              filterDepartment.includes(department),
            ),
        );
      }
      if (filterSpecialty.length) {
        filters.push(
          (row: ElectiveChoicesReportTableRow) =>
            (filterSpecialty.includes("") &&
              row.value.specialties.length === 0) ||
            row.value.specialties.some((specialty) =>
              filterSpecialty.includes(specialty),
            ),
        );
      }
      filters.push(buildOptionsFilter(filterPrimaryOption, "primaryOptions"));
      filters.push(buildOptionsFilter(filterBackupOption, "backupOptions"));

      return (row: ElectiveChoicesReportTableRow): boolean =>
        filters.reduce(
          (result: boolean, filter): boolean => result && filter(row),
          true,
        );
    },
    availableOptions({ report }): Array<{ k: string; v: string }> {
      const options = useElective().options;
      return Object.values(
        (
          report?.flatMap((row) => [
            ...(row.primaryOptions?.map((optionId) => ({
              k: optionId,
              v: options[optionId]?.fullName || optionId,
            })) || []),
            ...(row.backupOptions?.map((optionId) => ({
              k: optionId,
              v: options[optionId]?.fullName || optionId,
            })) || []),
          ]) || []
        ).reduce(
          (map, el) => ({
            ...map,
            [el.k]: el,
          }),
          {} as { [n: string]: { k: string; v: string } },
        ),
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableOptions(): Array<{ k: string; v: string }> {
      return [
        { k: "", v: "Нет дисциплины" },
        { k: "!empty", v: "Любая дисциплина" },
        ...this.availableOptions,
      ];
    },
    availableDepartments({ report }): Array<{ k: string; v: string }> {
      return Object.values(
        report
          ?.flatMap((row) => row?.embedded?.user?.departments || [])
          ?.map((name) => ({ k: name, v: abbreviateName(name) }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableDepartments(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableDepartments];
    },
    availableSpecialties({
      report,
      specialties,
    }): Array<{ k: string; v: string }> {
      return Object.values(
        report
          ?.flatMap((row) => row?.embedded?.user?.specialties || [])
          ?.map((code) => ({
            k: code,
            v: `${code} ${specialties[code]?.name || ""}`.trim(),
          }))
          ?.reduce(
            (map, el) => ({
              ...map,
              [el.k]: el,
            }),
            {} as { [n: string]: { k: string; v: string } },
          ) || {},
      ).sort((a, b) => a.v.localeCompare(b.v));
    },
    filterableSpecialties(): Array<{ k: string; v: string }> {
      return [{ k: "", v: "Неизвестно" }, ...this.availableSpecialties];
    },
  },
  actions: {
    async load() {
      const electiveStore = useElective();
      if (!electiveStore.elective?.id) {
        return;
      }
      this.isLoading = true;
      try {
        this.report = await fetchAll(
          this.$api.report.getElectiveChoicesReportRaw.bind(this.$api.report),
          {
            electiveId: electiveStore.elective.id,
            expand: "user,user.cohorts",
          },
          { limit: 1000 },
        );
        // this.report = await this.loadFullReport(electiveStore.elective.id);
        const specialtyCodes = Object.keys(
          this.report
            .flatMap((row) => row?.embedded?.user?.specialties || [])
            .reduce((map, el) => ({ ...map, [el]: el }), {}),
        );
        this.$api.integration
          .getIntegrationSpecialtyCollection({
            filter: `code:${specialtyCodes.join(",")}`,
            limit: 1000,
          })
          .then(
            (specialties) =>
              (this.specialties = specialties.reduce(
                (map, specialty) => ({
                  ...map,
                  [specialty.code as string]: specialty,
                }),
                {} as { [code: string]: Specialty },
              )),
          );
        this.report.sort((a, b) =>
          userFullName(a.embedded?.user).localeCompare(
            userFullName(b.embedded?.user),
          ),
        );
      } catch (e) {
        console.error(e);
        useToast().error("Не удалось загрузить отчёт");
        await router.replace({
          name: "elective:read",
          params: { slug: electiveStore.elective.slug },
        });
      } finally {
        this.isLoading = false;
      }
    },
    async loadFullReport(
      electiveId: string,
    ): Promise<Array<ElectiveChoicesReportInner>> {
      const fullReport = [];
      let stop = false;
      let offset = 0;
      while (!stop) {
        const response = await this.$api.report.getElectiveChoicesReportRaw({
          electiveId,
          expand: "user,user.cohorts",
          limit: 1000,
          offset,
        });
        fullReport.push(await response.value());
        const paginationTotal = Number.parseInt(
          response.raw.headers.get("Pagination-Total"),
        );
        console.log(paginationTotal);
        if (paginationTotal <= offset + 1000 || offset >= 10000) {
          stop = true;
        } else {
          offset += 1000;
        }
      }

      return [].concat(...fullReport);
    },
  },
});

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(useElectiveChoicesReport, import.meta.hot),
  );
}
